import React, {Fragment, useState} from 'react';
import {BlockStack, Select, Box, Button, ButtonGroup, Checkbox, Divider, InlineStack, Text, TextField, Tooltip, Bleed, Icon} from "@shopify/polaris";
import {CheckIcon, DeleteIcon, DragHandleIcon, EditIcon, XIcon} from "@shopify/polaris-icons";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {defaultSignImage, onKeyFire} from "../../../../Utils/Constent";
import PhoneInput ,{ parsePhoneNumber, isSupportedCountry }  from "react-phone-number-input";

const initialDnDState = {
    draggedFrom: null,
    draggedTo: null,
    isDragging: false,
    originalOrder: [],
    updatedOrder: []
}

const CommonInputStyle = ({ commonProps, fieldType }) => {
    const { setBlockArea,blockArea, selectedPageIndex, selectedBlockIndex, handleChangeStyles, addOptions, onEditOption,onEditDone, onDeleteOption, optValue, setOptValue, editingIndex, setEditingIndex, editOptValue, checkChange , oldBlockAreas, formData} = commonProps;
    const {style, fieldSize, placeholder,signatureURL,initialValue, title, isRequired, description,ratingCount,ratingType, options, errorMessage} = blockArea[selectedPageIndex]?.[selectedBlockIndex] || {};

    const [dragAndDrop, setDragAndDrop] = useState(initialDnDState);
    const [draggingIndex, setDraggingIndex] = useState(null);

    const ratingTypeOptions = [
        {label: "Star", value: "1"},
        {label: "Lightning", value: "2"},
        {label: "Shield", value: "3"},
        {label: "Heart", value: "4"},
        {label: "Flag", value: "5"},
        {label: "Bulb", value: "6"},
    ]

    let ratingCountOptions = [];
    for (let i = 1; i <= 10; i++) {
        ratingCountOptions.push({label: `${i}`, value: `${i}`});
    }

    const onEdit = (value,index) => {
        onEditOption(value, index);
        setEditingIndex(index)
    }

    // drag and drop
    const onDragStart = (event, index) => {
        event.stopPropagation();
        const initialPosition = Number(event.currentTarget.dataset.position);
        setDragAndDrop({
            ...dragAndDrop,
            draggedFrom: initialPosition,
            isDragging: true,
            originalOrder: [...options]
        });
        setDraggingIndex(index)
    };

    const onDragOver = (event, index) => {
        event.preventDefault();
        event.stopPropagation();
        let newList = [...dragAndDrop.originalOrder];
        const draggedFrom = dragAndDrop.draggedFrom;
        const draggedTo = index;
        const itemDragged = newList[draggedFrom];
        newList.splice(draggedFrom, 1);
        newList.splice(draggedTo, 0, itemDragged);

        setDragAndDrop({
            ...dragAndDrop,
            updatedOrder: newList,
            draggedTo: draggedTo
        });
    };

    const onDrop = (event, index) => {
        event.preventDefault();
        event.stopPropagation();
        setDraggingIndex(index)
        const updatedBlockArea = { ...blockArea };
        const updatedOptions = [...dragAndDrop.updatedOrder];
        let hasUndefinedKey = false;
        for (const key of updatedOptions) {
            if (key === undefined) {
                hasUndefinedKey = true;
                break;
            }
        }
        if (!hasUndefinedKey) {
            updatedBlockArea[selectedPageIndex][selectedBlockIndex].options = updatedOptions;
            setBlockArea(updatedBlockArea);
            checkChange(updatedBlockArea, oldBlockAreas)
            setDragAndDrop(initialDnDState);
            setDraggingIndex(null)
        }
    };

    const onDragLeave = (event) => {
        event.stopPropagation();
        setDragAndDrop({
            ...dragAndDrop,
            draggedTo: null
        });
        setDraggingIndex(null);
    };

    const renderOptions = () => (
        <Fragment>
            <Box padding={"400"} paddingBlockStart={"200"}>
                <BlockStack gap={options?.length ? "300" : "0"}>
                    <Box>
                        <BlockStack gap="150">
                            {(options || []).map((x, i) => (
                                <Fragment key={i}>
                                    {(editingIndex === i && editOptValue.mainIndex === selectedPageIndex &&  editOptValue.subIndex === selectedBlockIndex) ? (
                                        <TextField
                                            value={editOptValue.val}
                                            onChange={(value) => onEditOption(value, i)}
                                            autoFocus
                                            error={editingIndex === i && editOptValue.val?.trim() === '' ? "Value is blanked." : false}
                                            connectedRight={
                                                <InlineStack gap={"050"}>
                                                    <Tooltip content={"Update option"} dismissOnMouseOut><Button size={"large"} variant={"primary"} icon={CheckIcon} onClick={onEditDone} /></Tooltip>
                                                    <Tooltip content={"Discard option"} dismissOnMouseOut><Button icon={XIcon} size={"large"} onClick={() => setEditingIndex(null)} /></Tooltip>
                                                </InlineStack>
                                            }
                                        />
                                    ) : (
                                        <div data-position={i} draggable={true}
                                             style={{cursor: "grab",}} title={"drag"}
                                             onDragStart={(e) => onDragStart(e, i)}
                                             onDragOver={(e) => onDragOver(e, i)}
                                             onDrop={(e) => onDrop(e,i)}
                                             onDragLeave={(e) => onDragLeave(e)}
                                        >
                                        <Box borderWidth={draggingIndex === i ? "050" : "025"}  padding={"050"} paddingInline={"150"} paddingInlineStart={"0"}
                                             borderColor={draggingIndex === i ? "border-focus" : "border-secondary"} borderStyle={"solid"} borderRadius={"150"} width={"100%"} >
                                                <InlineStack wrap={false} blockAlign={"center"} gap={"025"}>
                                                    <button className="drag-btn drag-btn-option"><Icon source={DragHandleIcon}/></button>
                                                    <Box as={"span"} width={"100%"}>
                                                        <InlineStack align={"space-between"} blockAlign={"center"} wrap={false} gap={"050"}>
                                                            <Text>{x?.label}</Text>
                                                            <Bleed marginBlockEnd={"100"}>
                                                                <InlineStack gap={"050"} wrap={false} as={"span"}>
                                                                    <Tooltip content={"Edit option"} dismissOnMouseOut><Button icon={EditIcon} variant={"plain"} onClick={() => onEdit(x?.label, i)}/></Tooltip>
                                                                    <Tooltip content={"Remove option"} dismissOnMouseOut><Button icon={DeleteIcon} variant={"plain"} onClick={() => onDeleteOption(i)}/></Tooltip>
                                                                </InlineStack>
                                                            </Bleed>
                                                        </InlineStack>
                                                    </Box>
                                                </InlineStack>
                                            </Box>
                                        </div>
                                    )}
                                </Fragment>
                            ))}
                        </BlockStack>
                    </Box>
                    <Box width={"100%"} onKeyPress={(e) => onKeyFire(e, addOptions)}>
                        <TextField
                            label="Options"
                            placeholder={"Type an option"}
                            value={optValue}
                            onChange={(value) => setOptValue(value)}
                            autoComplete="off"
                            connectedRight={<Tooltip content={"Add Option"}><Button size={"large"} onClick={addOptions}>Add</Button></Tooltip>}
                        />
                    </Box>
                </BlockStack>
            </Box>
            <Divider />
        </Fragment>
    );

    return (
        <Fragment>
            <Box padding={"400"} paddingBlockStart={"200"}>
                <TextField
                    label="Field Label"
                    placeholder={"Type a label"}
                    value={title}
                    onChange={(value) => handleChangeStyles('title', value)}
                    autoComplete="off"
                />
            </Box>
            <Divider />

            {
                formData.formLayout != 3 &&
                <Fragment>
                    <Box padding={"400"} paddingBlockStart={"200"}>
                        <BlockStack>
                            <div className="Polaris-Labelled__LabelWrapper">
                                <div className="Polaris-Label">
                                    <label className={`Polaris-Label__Text`}>Description</label>
                                </div>
                            </div>
                            <ReactQuill
                                placeholder={"Type a description"}
                                className={`ql-field"`}
                                value={description}
                                modules={{
                                    toolbar: {container: [["bold", "italic", 'link']],},
                                    clipboard: { matchVisual: false }
                                }}
                                onChange={(newValue, delta, source) => {
                                    if (source === 'user') {
                                        handleChangeStyles('description', newValue)
                                    }
                                }}
                            />
                        </BlockStack>
                    </Box>
                    <Divider />
                    <Box padding={"400"} paddingBlockStart={"200"}>
                        <BlockStack gap={"100"}>
                            <Text>Field Size</Text>
                            <ButtonGroup variant="segmented" fullWidth>
                                {['small', 'medium', 'large', 'fullwidth'].map((x) => (
                                    <Button
                                        key={x} fullWidth size={"large"}
                                        pressed={fieldSize === x}
                                        onClick={() => handleChangeStyles("fieldSize", x,)}
                                    >
                                        {x.charAt(0).toUpperCase() + x.slice(1)}
                                    </Button>
                                ))}
                            </ButtonGroup>
                        </BlockStack>
                    </Box>
                    <Divider />
                </Fragment>
            }

            { (formData.formLayout != 3 && fieldType == 12) &&
                <Fragment>
                    <Box padding={"400"} paddingBlockStart={"200"}>
                        <BlockStack gap={"150"}>
                            <Select
                                label="Rating Type"
                                options={ratingTypeOptions}
                                onChange={(value) => handleChangeStyles('ratingType', value)}
                                value={ratingType?.toString()}
                            />
                            <Select
                                label="Count"
                                options={ratingCountOptions}
                                onChange={(value) => handleChangeStyles('ratingCount', value)}
                                value={ratingCount?.toString()}
                            />
                        </BlockStack>
                    </Box>
                    <Divider />
                </Fragment>
            }

            {
                fieldType == 5 || fieldType == 6 || fieldType == 7 || fieldType == 12 || fieldType == 14 ? null :
                <Fragment>
                    <Box padding={"400"} paddingBlockStart={"200"}>
                        <TextField
                            label="Field Placeholder"
                            placeholder={"Type a placeholder"}
                            value={placeholder}
                            onChange={(value) => handleChangeStyles('placeholder', value)}
                            autoComplete="off"
                        />
                    </Box>
                    <Divider />
                </Fragment>
            }

            {formData.formLayout != 3 ?
                (fieldType == 12 || fieldType == 3 || fieldType == 6 || fieldType == 7 || fieldType == 8 || fieldType == 14) ? null :
                <Fragment>
                    <Box padding={"400"} paddingBlockStart={"200"}>
                        {
                            fieldType == 5 ? <Fragment>
                                    <div className="Polaris-Labelled__LabelWrapper">
                                        <div className="Polaris-Label">
                                            <label className="Polaris-Label__Text">Country Code</label>
                                        </div>
                                    </div>
                                    <PhoneInput
                                        international
                                        initialValueFormat="national"
                                        defaultCountry={style?.countryCode || "IN"}
                                        placeholder={'Select Country Code'}
                                        onChange={(value) => {
                                            if(value) {
                                                const phoneNumber = parsePhoneNumber(value?.toString())
                                                if (phoneNumber) {
                                                    handleChangeStyles('countryCode', phoneNumber.country, 'style')
                                                }
                                            }
                                        }}
                                        onCountryChange={(country) => {
                                            if(country) {
                                                const isSupported = isSupportedCountry(country);
                                                if (isSupported) {
                                                    handleChangeStyles('countryCode', country, 'style');
                                                } else {
                                                }
                                            }
                                        }}
                                    />
                                </Fragment> :
                                <TextField
                                    label="Initial Value"
                                    placeholder={"Type a initial value"}
                                    value={initialValue} type={fieldType == 9 ? "date" : "text"}
                                    onChange={(value) => handleChangeStyles('initialValue', value)}
                                    autoComplete="off"
                                />
                        }
                    </Box>
                    <Divider />
                </Fragment> : null
            }

            {
                (fieldType ==  14) ?
                <Fragment>
                    <Box padding={"400"} paddingBlock={"200"}>
                        <img src={signatureURL || defaultSignImage} className={"signatureURL"} alt="signature"/>
                    </Box>
                    <Divider />
                </Fragment> : null
            }


            {(fieldType == 3 || fieldType == 6 || fieldType == 7 || fieldType == 8) && renderOptions()}

            <Box paddingBlockEnd={"200"} paddingBlockStart={"200"} padding={"400"}>
                <Checkbox
                    label="Required Field"
                    checked={isRequired == 1}
                    onChange={(checked) => handleChangeStyles('isRequired', checked ? "1" : "0")}
                    helpText={"If checked, users will be required to complete this field."}
                />
            </Box>
            <Divider />

            {isRequired == 1 && (
                <Fragment>
                    <Box padding={"400"} paddingBlockStart={"200"}>
                        <TextField
                            label="Error Message"
                            placeholder={"Type a error message"}
                            value={errorMessage}
                            onChange={(value) => handleChangeStyles('errorMessage', value)}
                            autoComplete="off"
                        />
                    </Box>
                    <Divider />
                </Fragment>
            )}

            {/*<Box padding={"400"} paddingBlockStart={"200"}>*/}
            {/*    <BlockStack gap={"150"}>*/}
            {/*        <Text>Label Alignment</Text>*/}
            {/*        <ButtonGroup variant="segmented" fullWidth>*/}
            {/*            {['left', 'center', 'right'].map((alignment) => (*/}
            {/*                <Button*/}
            {/*                    key={alignment} fullWidth size={"large"}*/}
            {/*                    pressed={style?.alignment === alignment}*/}
            {/*                    onClick={() => handleChangeStyles("alignment", alignment, 'style')}*/}
            {/*                >*/}
            {/*                    {alignment.charAt(0).toUpperCase() + alignment.slice(1)}*/}
            {/*                </Button>*/}
            {/*            ))}*/}
            {/*        </ButtonGroup>*/}
            {/*        <Text tone={"subdued"}>Select how the label text is aligned horizontally</Text>*/}
            {/*    </BlockStack>*/}
            {/*</Box>*/}
        </Fragment>
    );
};

export default CommonInputStyle;
