import React, {Fragment} from 'react';
import {BlockStack, Icon} from "@shopify/polaris";
import {InfoIcon} from "@shopify/polaris-icons";
import {isContentEmpty} from "../CommonUse/CommonUse";

const SingleSelectOption = ({commonListProps}) => {
    const {blockArea, selectedPageIndex, selectedBlockIndex} = commonListProps;
    const {title, isRequired, description, options, errorMessage} = blockArea[selectedPageIndex]?.[selectedBlockIndex] || {};

    return (
        <Fragment>
            <div>
                <div className="Polaris-Labelled__LabelWrapper">
                    <div className="Polaris-Label">
                        <label className={`Polaris-Label__Text ${isRequired == 1 ? "Polaris-Label__RequiredIndicator" : ""}`}>
                            <span className="Polaris-Text--root Polaris-Text--bodyMd">{title}</span>
                        </label>
                    </div>
                </div>

                <BlockStack gap={"100"}>
                    {
                        (options || []).map(({value, label}, i) => (
                            <label className="Polaris-Choice Polaris-RadioButton__ChoiceLabel"
                                   htmlFor="disabled" key={i}>
                                                <span className="Polaris-Choice__Control">
                                                  <span className="Polaris-RadioButton">
                                                    <input readOnly type="radio" className="Polaris-RadioButton__Input"
                                                           checked={i === 0} value={value}/>
                                                    <span className="Polaris-RadioButton__Backdrop">
                                                    </span>
                                                  </span>
                                                </span>
                                <span className="Polaris-Choice__Label">
                                    <span key={i} >{label}</span>
                                </span>
                            </label>
                        ))
                    }
                </BlockStack>

                {errorMessage?.trim() !== '' && isRequired == 1 &&
                <div className="Polaris-Labelled__Error">
                    <div className="Polaris-InlineError">
                        <div className="Polaris-InlineError__Icon">
                            <Icon source={InfoIcon} />
                        </div>
                        {errorMessage}
                    </div>
                </div>
                }

                {isContentEmpty(description) ? null :
                    <div className="Polaris-Labelled__HelpText">
                        <span className="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--break Polaris-Text--subdued" dangerouslySetInnerHTML={{__html: description}}/>
                    </div>
                }

            </div>
        </Fragment>
    );
};

export default SingleSelectOption;