import React, {Fragment, useCallback, useState} from 'react';
import {BlockStack, Box, Button, Text, TextField} from "@shopify/polaris";
import {apiService, baseUrl, capitalizeMessage, onKeyFire} from "../../Utils/Constent";
import AuthFrame from "../AuthStructure/AuthFrame";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setUserDetails} from "../../Redux/Action/Action";

const initialState = {
    otp: "",
};

const Tfa = () => {
    const navigate = useNavigate();
    const [authDetails, setAuthDetails] = useState(initialState);
    const [formError, setErrors] = useState(initialState);
    const [isLoading, setIsLoading] = useState(false);
    const [active, setActive] = useState(false);
    const [message, setMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const dispatch = useDispatch();

    const formValidate = (name, value) => {
        switch (name) {
            case "otp":
                if (!value || value.trim() === "") {
                    return "Code is required.";
                } else {
                    return "";
                }
            default: {
                return "";
            }
        }
    };

    const onHandleChange = useCallback((name, value) => {
            if (!isNaN(value)) {
                setAuthDetails(prevTokenDetails => ({...prevTokenDetails, [name]: value}));
                setErrors(formError => ({...formError, [name]: '',}));
            }
        },
        [authDetails],
    );

    const onBlurChange = useCallback((name, value) => {
            setErrors(formError => ({
                ...formError,
                [name]: formValidate(name, value),
            }));
        },
        [authDetails],
    );

    const handleTFAContinue = async () => {
        let errorData = {};
        Object.keys(authDetails).map((x) => {
            let error = formValidate(x, authDetails[x]);
            if (error && error.length > 0) {
                errorData[x] = error;
            }
        });
        if (Object.keys(errorData).length > 0) {
            setErrors(errorData);
            return;
        }
        setIsLoading(true);
        const payload = {otp: authDetails.otp};

        const response = await apiService.verify2fa(payload);
        if (response.success === true) {
            setIsLoading(false);
            setMessage(capitalizeMessage(response?.message));
            setActive(true);
            setAuthDetails(initialState);
            localStorage.setItem("2FA", "false");
            setIsError(false)
            localStorage.setItem("token", response?.data?.token);
            dispatch(setUserDetails(response?.data));
            navigate(`${baseUrl}/dashboard`);
        } else {
            setIsLoading(false);
            setActive(true);
            setMessage(response.error.message);
            setIsError(true);
        }
    };

    const toggleActive = useCallback(() => {
        setActive(false)
        setIsError(false)
        setMessage('')
    }, []);

    return (
        <Fragment>
            <AuthFrame title={"Two-factor Authentication"} {...{message,isError, active, toggleActive}}
                       >
                <Box as={"span"} width={"100%"}>
                    <BlockStack gap={"300"}>
                        <Box as={"span"} width={"100%"} onKeyPress={(e) => onKeyFire(e, handleTFAContinue)}>
                            <TextField autoFocus
                                label={"Enter an authenticator app code or a recovery code"} placeholder={"Code"}
                                value={authDetails.otp}  type={"text"}
                                error={formError.otp} onBlur={(e) => onBlurChange("otp", e.target.value)}
                                onChange={(value) => onHandleChange("otp", value)}
                            />
                        </Box>
                        <Button variant={"primary"} size={"large"} loading={isLoading} onClick={handleTFAContinue}>Continue</Button>
                    </BlockStack>
                </Box>
            </AuthFrame>
        </Fragment>
    );
};

export default Tfa;