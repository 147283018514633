import React, {Fragment} from 'react';

const VideoPlay = ({commonListProps}) => {
    const {blockArea, selectedPageIndex, selectedBlockIndex} = commonListProps;
    const {videoURL} = blockArea[selectedPageIndex]?.[selectedBlockIndex] || {};

    let videoType = '';
    let embed_link = '';
    if (videoURL !== '' || videoURL != null) {
        if (videoURL?.includes('player.vimeo.com')) {
            videoType = 'vimeo';
            embed_link = videoURL.split('video/')[1]?.split('?')[0];
        }
        else if (videoURL?.includes('vimeo.com')) {
            videoType = 'vimeo';
            embed_link = videoURL.split('.com/')[1];
        } else if (videoURL?.includes('youtube.com/watch')) {
            videoType = 'youtube';
            if (videoURL.includes('list=')) {
                const videoIdIndex = videoURL.indexOf('v=') + 2;
                const ampersandIndex = videoURL.indexOf('&', videoIdIndex);
                embed_link = ampersandIndex !== -1 ? videoURL.substring(videoIdIndex, ampersandIndex) : videoURL.substring(videoIdIndex);
            } else {
                embed_link = videoURL.split('v=')[1];
            }
        } else if (videoURL?.includes('youtube.com/embed')) {
            videoType = 'youtube';
            embed_link = videoURL.split('embed/')[1]?.split('?')[0];
        } else if (videoURL?.includes('youtu.be/')) {
            videoType = 'youtube';
            embed_link = videoURL.split('.be/')[1]?.split('?')[0];
        } else if (videoURL.includes('youtube.com/live') || videoURL.includes('youtu.be/live')) {
            videoType = 'youtube';
            let parts = videoURL?.split('/').pop();
            embed_link = parts?.split('?')[0];
        } else {
            videoType = 'mp4';
        }
    }

    return (
        <Fragment>
            <div className={`videoWidget ${videoType === 'mp4' ? 'videoEmbed' : 'iframeEmbed'}`}>
                {
                    videoType === 'youtube' ?
                        <iframe
                            src={`https://www.youtube.com/embed/${embed_link}?autoplay=1&mute=1&loop=1&color=white&controls=0&modestbranding=1&playsinline=1&rel=0&enablejsapi=1&background=1&playlist=${embed_link}`}
                            title={'Youtube Video Preview'} frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                        :
                        videoType === 'vimeo' ?
                            <iframe
                                src={`https://player.vimeo.com/video/${embed_link}?autoplay=1&loop=1&title=0&byline=0&portrait=0&muted=1&transparent=0&background=1`}
                                title={'Vimeo Video Preview'}
                                frameBorder="0" allow="autoplay;fullscreen;" allowFullScreen
                                data-ready="true"
                            /> :
                            <video datatype="mp4" src={videoURL}
                                   title={'Video Preview'} width="100%"
                                   loop muted playsInline autoPlay="1" controls/>
                }
            </div>
        </Fragment>
    );
};

export default VideoPlay;