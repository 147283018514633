import React, {Fragment, useCallback, useEffect, useRef, useState} from 'react';
import {Toast} from "@shopify/polaris";
import {apiService, baseUrl, capitalizeMessage} from "../../../Utils/Constent";
import {useNavigate, useParams} from "react-router-dom";
import cloneDeep from 'lodash/cloneDeep';
import qs from "qs";
import Header from "./SubComponents/Header";
import MiniBar from "./SubComponents/MiniBar";
import RightBar from "./SubComponents/RightBar";
import LeftBar from "./SubComponents/LeftBar";
import {labelFieldType, optionsFieldType, placeholderFieldType, fieldSizeFieldType, scrollToBottom, findRefIndex} from "./CommonUse/CommonUse";
import Submissions from "./Submissions/Submissions";
import Integrate from "./Integrate/Integrate";
import Customize from "./Customize/Customize";
import ThankYou from "./ThankYou/ThankYou";
import MiddleBar from "./SubComponents/MiddleBar";
import ShareModal from "./Share/ShareModal";
import Html from "./Html/Html";

const initialFormState = {
    nextButtonText: "",
    submitButtonText: "",
    previousButtonText: "",
    thankYouTitle: "",
    thankYouBtnText: "",
    thankYouLink: "",
}

const FormDetails = () => {
    const navigate = useNavigate();
    const {workspaceId, type, id} = useParams();
    const middleRef = useRef(null);
    const findMiddleRef = useRef(null);
    const findLeftRef = useRef(null);
    const leftRef = useRef(null);
    const optionAddRef = useRef(null);

    const getMobileView = localStorage.getItem("isMobileView")

    const [isLoading, setIsLoading] = useState(false);
    const [isMobileView, setIsMobileView] = useState(getMobileView === "true" || false);

    const [formData, setFormData] = useState({});
    const [formFieldsAll, setFormFieldsAll] = useState([]);
    const [oldFormData, setOldFormData] = useState({});
    const [formError, setFormErrors] = useState(initialFormState);

    const [isNewLogo, setIsNewLogo] = useState(false);
    const [isNewBanner, setIsNewBanner] = useState(false);
    const [isRemoveLogo, setIsRemoveLogo] = useState(false);
    const [isRemoveBanner, setIsRemoveBanner] = useState(false);

    const [popoverActive, setPopoverActive] = useState(null);

    const [blockArea, setBlockArea] = useState({"1":[]});
    const [oldBlockAreas, setOldBlockAreas] = useState({"1":[]});
    const [deletedFieldsArray, setDeletedFieldsArray] = useState([]);

    const [loading, setLoading] = useState('');

    const [active, setActive] = useState(false);
    const [message, setMessage] = useState('');
    const [isError, setIsError] = useState(false);

    const [selectedPageIndex, setSelectedPageIndex] = useState(null);
    const [selectedBlockIndex, setSelectedBlockIndex] = useState(null);
    const [selectedFieldType, setSelectedFieldType] = useState(null);
    const [selectedHeadPageIndex, setSelectedHeadPageIndex] = useState(0);
    const [optValue, setOptValue] = useState("");
    const [editOptValue, setEditOptValue] = useState({val: "", id: null, mainIndex: null, subIndex: null});
    const [editingIndex, setEditingIndex] = useState(null);

    const [isEditName, setIsEditName] = useState(false);
    const [editName, setEditName] = useState('');
    const [isShareModal, setIsShareModal] = useState(false);
    const [isHtmlModal, setIsHtmlModal] = useState(false);

    const [isDirty, setIsDirty] = useState(false);
    const [isDirtyCustomize, setIsDirtyCustomize] = useState(false);
    const [isSaved, setIsSaved] = useState(false);

    const [pageTitle, setPageTitle] = useState('Page - 1');

    const checkChange = (newData, oldData, typeD) => {
        if(JSON.stringify(newData) === JSON.stringify(oldData)){
            if(typeD === 'customize'){
                setIsDirtyCustomize(false)
            } else {
                setIsDirty(false)
            }
        } else {
            if(typeD === 'customize'){
                setIsDirtyCustomize(true)
            } else {
                setIsDirty(true)
            }
        }
    }

    useEffect(() => {
        setEditName(formData?.name||"");
    }, [isEditName])

    useEffect(()=> {
        localStorage.setItem("isMobileView", isMobileView)
    },[isMobileView])

    const handleTabChange = useCallback((typeChange) => {
        navigate({pathname: `${baseUrl}/workspace/${workspaceId}/${typeChange}/${id}`});
        }, [],
    );

    useEffect(() => {
        document.title = `Web Form ${formData?.name ? `| ${capitalizeMessage(formData?.name)}` : ''}`;
    }, [formData?.name, type]);

    window.onpopstate = () => onBack();
    const onBack = () => {
        navigate({
            pathname: `${baseUrl}/workspace/${workspaceId}`,
            search: qs.stringify({workSpaceTab: 0}),
        });
    }

    useEffect(() => {
        getFormField()
    }, [])

    const getFormField = async () => {
        setIsLoading(true)
        const response = await apiService.getFormField(id, workspaceId);
        if (response.success === true) {
            setIsLoading(false)
            let objWithStyles = {
                ...response.data,
                style: {
                    ...response.data?.style,
                    submitButtonText: response?.data?.style?.submitButtonText ?? "Submit",
                    buttonIcon: response?.data?.style?.buttonIcon ?? "0",
                    nextButtonText: response?.data?.style?.nextButtonText ?? "Next",
                    previousButtonText: response?.data?.style?.previousButtonText ?? "Previous",
                    globalTextColor: response?.data?.style?.globalTextColor ?? "#000000",
                    globalLabelColor: response?.data?.style?.globalLabelColor ?? "#303030",
                    globalBg: response?.data?.style?.globalBg ?? "#f1f1f1",
                    globalPlaceColor: response?.data?.style?.globalPlaceColor ?? '#c2c2c2',
                    globalDescColor: response?.data?.style?.globalDescColor ?? "#616161",
                    globalErrorColor: response?.data?.style?.globalErrorColor ?? "#8e1f0b",
                    buttonTextColor: response?.data?.style?.buttonTextColor ?? "#ffffff",
                    buttonBg: response?.data?.style?.buttonBg ?? "#000000",
                    buttonPosition: response?.data?.style?.buttonPosition ?? "start",
                    logoPosition: response?.data?.style?.logoPosition ?? "left",
                    bannerPosition: response?.data?.style?.bannerPosition ?? "top",
                    bannerSize: response?.data?.style?.bannerSize ?? "container",
                    formSize: response?.data?.style?.formSize ?? "medium",
                    formDirection: response?.data?.style?.formDirection ?? "ltr",
                }
            }
            setFormData(objWithStyles)
            setOldFormData(cloneDeep(objWithStyles))
            setFormFieldsAll(response.data.formFieldsAll)
            setBlockArea(Object.keys(response.data.formFields).length > 0 ? cloneDeep(response.data.formFields) : {"1": []});
            setOldBlockAreas(Object.keys(response.data.formFields).length > 0 ? response.data.formFields : {"1": []});
            setIsSaved(Object.keys(response.data.formFields).length > 0);
        } else {
            setIsLoading(false)
        }
    }

    const formValidateCustomize = (name, value) => {
        switch (name) {
            case "nextButtonText":
                if ((!value || value.trim() === "") && formData?.style?.buttonIcon == '0' && type === 'customize') {
                    return "Next button text is required";
                } else {
                    return "";
                }
            case "previousButtonText":
                if ((!value || value.trim() === "") && formData?.style?.buttonIcon == '0' && type === 'customize') {
                    return "Previous button text is required";
                } else {
                    return "";
                }
            case "submitButtonText":
                if ((!value || value.trim() === "") && type === 'customize') {
                    return "Submit button text is required";
                } else {
                    return "";
                }
            case "thankYouTitle":
                if ((!value || value.trim() === "") && type === 'thank-you') {
                    return "Title text is required";
                } else {
                    return "";
                }
            // case "thankYouBtnText":
            //     if ((!value || value.trim() === "") && type === 'thank-you') {
            //         return "Button text is required";
            //     } else {
            //         return "";
            //     }
            // case "thankYouLink":
            //     if (((value?.toString()?.trim() !== '') && (!value.includes('https://'))) && type === 'thank-you') {
            //         return "Please enter valid link";
            //     } else {
            //         return "";
            //     }
            default: {
                return "";
            }
        }
    };

    const handleChangeCustomize = (name, value, category) => {
        let updatedFormData = { ...formData };
        if (category === 'style') {
            updatedFormData[category] = { ...formData.style, [name]: value };
        } else {
            updatedFormData[name] = value;
        }
        if (name === 'logo') {
            setIsNewLogo(true)
            checkChange(cloneDeep(value?.name ? value?.name : value), cloneDeep(oldFormData.logo?.name ? oldFormData.logo?.name : oldFormData.logo), "customize")
        } else if (name === 'banner') {
            setIsNewBanner(true)
            checkChange(cloneDeep(value?.name ? value?.name : value), cloneDeep(oldFormData.banner?.name ? oldFormData.banner?.name : oldFormData.banner), "customize")
        } else {
            checkChange(updatedFormData, oldFormData, "customize");
        }
        setFormData(updatedFormData)
        setFormErrors((formError) => ({
            ...formError,
            [name]: formValidateCustomize(name, value),
        }));
    };

    const handleRemove = (name) => {
        if(name === 'logo'){
            setIsRemoveLogo(true)
            handleChangeCustomize('logo', '')
        } else {
            setIsRemoveBanner(true)
            handleChangeCustomize('banner', '')
        }
    }

    const onSaveFromName = async () => {
        if(editName?.trim() === '') {
            return
        }
        setLoading('formName')
        const payload = new FormData();
        payload.append('name', editName);
        const response = await apiService.formUpdate(id, payload, true);
        if (response?.success === true) {
            setLoading('')
            setMessage(capitalizeMessage(response?.message));
            setFormData(prevState => ({...prevState, name: editName}));
            setActive(true);
            setIsError(false)
            setIsEditName(false)
        } else {
            setLoading('')
            setActive(true);
            setMessage(response?.error?.message);
            setIsError(true);
        }
    }

    const onSave = async () => {
        let response;
        if(type === 'form'){
            let newArray = [];
            const formDatas = new FormData();
            Object.keys(blockArea).map((key) => {
                blockArea[key].map((x, i) => {
                    const obj = {...x, step: formData.formLayout == 1 ? 0 : key, orderBy: i + 1};
                    newArray.push(obj)
                })
            })
            newArray.map((x, i) => {
                Object.keys(x).map((f) => {
                    if(f === "style" || f === "options"){
                        formDatas.append(`fields[${i}][${f}]`, JSON.stringify(x[f]))
                    } else {
                        formDatas.append(`fields[${i}][${f}]`, x[f])
                    }
                })
            })

            formDatas.append(`formId`, formData.id)
            formDatas.append(`workSpaceId`, formData.workSpaceId)
            formDatas.append(`deletedFields`, JSON.stringify(deletedFieldsArray))

            setLoading('save')
            response = await apiService.createFieldForm(formDatas);
        }
        else if(type === 'customize' || type === 'thank-you'){
            let validationErrors = {};

            Object.keys(type === 'customize' ? formData?.style : formData).forEach(name => {
                const error = formValidateCustomize(name, type === 'customize' ? formData?.style[name] : formData[name]);
                if (error && error.length > 0) {
                    validationErrors[name] = error;
                }
            });

            if (Object.keys(validationErrors).length > 0) {
                setFormErrors(validationErrors);
                return;
            }

            setLoading('save')
            const payload = new FormData();
            payload.append('name', formData?.name);
            payload.append('formType', formData?.formType);
            payload.append('formLayout', formData?.formLayout);
            payload.append('publishStatus', formData.publishStatus);
            payload.append('logo', isNewLogo ? formData?.logo :  "");
            payload.append('banner', isNewBanner ? formData?.banner : "");
            payload.append('isRemoveLogo', isRemoveLogo);
            payload.append('isRemoveBanner', isRemoveBanner);
            payload.append('style', JSON.stringify(formData?.style));

            if(type === 'thank-you') {
                payload.append('thankYouTitle', formData?.thankYouTitle);
                payload.append('thankYouDescription', formData?.thankYouDescription);
                payload.append('thankYouBtnText', formData?.thankYouBtnText);
                payload.append('thankYouLink', formData?.thankYouLink);
            }

            response = await apiService.formUpdate(id, payload, true);
        }
        if(type === 'form' || type === 'customize' || type === 'thank-you') {
            if (response?.success === true) {
                setLoading('')
                if(type === 'form'){
                    setBlockArea({...blockArea,...response.data.blockArea})
                    setFormFieldsAll(response.data.formFieldsAll)
                    setOldBlockAreas(blockArea)
                    setIsDirty(false)
                    setDeletedFieldsArray([])
                    setIsSaved(true)
                } else {
                    setIsNewBanner(false)
                    setIsNewLogo(false)
                    setIsRemoveLogo(false)
                    setIsRemoveBanner(false)
                    setIsDirtyCustomize(false)
                    setOldFormData(formData)
                }
                setMessage(capitalizeMessage(response?.message));
                setActive(true);
                setIsError(false)
            } else {
                setLoading('')
                setActive(true);
                setMessage(response?.error?.message);
                setIsError(true);
                setIsSaved(false)
            }
        }
    }

    const onPublish = async () => {
        setLoading('publish')
        const payload = {
            publishStatus: formData.publishStatus == 0 ? '1' : '0'
        }
        const response = await apiService.formUpdate(id, payload, false);
        if (response.success === true) {
            setLoading('')
            setMessage(capitalizeMessage(response?.message));
            setFormData({...formData,publishStatus : formData.publishStatus == 0 ? '1' : '0'})
            setActive(true);
            setIsError(false)
        } else {
            setLoading('')
            setActive(true);
            setMessage(response?.error?.message);
            setIsError(true);
        }
    }

    const onUseThisBlock = (record, actionType, pageIndex, pageIndexNum) => {
        let clone = {...blockArea};
        if (actionType === "addPage") {
            if (formData.formLayout == 1) {
                clone["0"] = [];
            } else {
                const length = Object.keys(clone).length
                clone[length + 1] = [];
            }
            setSelectedHeadPageIndex(Object.keys(clone).length - 1 || 0)
        } else if (actionType === "addBlock") {
            const cloneBlock = [...blockArea[pageIndex]]
            const newBlock = {
                fieldType: record?.fieldType,
                title: labelFieldType[record?.fieldType] || labelFieldType.default,
                description: "",
                fieldSize: fieldSizeFieldType[record?.fieldType] || fieldSizeFieldType.default,
                initialValue: "",
                signatureURL: "",
                videoURL: "",
                imgURL: "",
                imgLink: "",
                imgType: "0",
                captchaKey: "",
                ratingType: "1",
                ratingCount: "5",
                fileFormats: '.jpg, .jpeg, .png',
                fileUploadMaxLimit: "1",
                fileUploadMinLimit: "0",
                placeholder: placeholderFieldType[record?.fieldType] || placeholderFieldType.default,
                options: optionsFieldType[record?.fieldType] || optionsFieldType.default,
                isRequired: "0",
                errorMessage: "",
                style: {alignment: "left", text_color: "#000000", bg: "#00000000", divider_color: "#000000", divider_width: "1", divider_style: "solid", countryCode: "IN",},
                orderBy: cloneBlock.length + 1,
                step: formData.formLayout == 1 ? "0" : pageIndex
            };
            cloneBlock.push(newBlock);
            clone[pageIndex] = cloneBlock;
            setSelectedBlockIndex(cloneBlock.length - 1);
        }
        setBlockArea(clone)
        setPopoverActive(null);
        setSelectedPageIndex(pageIndexNum + 1)
        setSelectedFieldType(record?.fieldType)
        checkChange(clone, oldBlockAreas)
        scrollToBottom(middleRef);
        scrollToBottom(leftRef);
    };

    const onSetUpMiddle = (pageIndex, blockIndex, fieldType, refIndex, type='') => {
        setSelectedPageIndex(pageIndex)
        setSelectedBlockIndex(blockIndex)
        setSelectedFieldType(fieldType)
        findRefIndex(type === 'left' ? findMiddleRef : findLeftRef, refIndex)
    }

    const addOptions = () => {
        const trimmedOptValue = optValue?.trim()?.replace(/\s+/g, ' ')?.toLowerCase();
        if (trimmedOptValue !== '') {
            const updatedBlockArea = { ...blockArea };
            const currentOptions = updatedBlockArea[selectedPageIndex][selectedBlockIndex].options || [];
            const optionExists = currentOptions.some(x => x?.value?.trim()?.replace(/\s+/g, ' ')?.toLowerCase() === trimmedOptValue);
            if (!optionExists) {
                updatedBlockArea[selectedPageIndex][selectedBlockIndex].options = [...currentOptions, { label: optValue, value: trimmedOptValue }];
                setBlockArea(updatedBlockArea);
                setOptValue('');
                checkChange(updatedBlockArea, oldBlockAreas);
                scrollToBottom(optionAddRef);
                setMessage('');
                setIsError(false);
                setActive(false);
            } else {
                setMessage("Option already exists");
                setIsError(true);
                setActive(true);
            }
        } else {
            setMessage("Value is blanked.");
            setIsError(true);
            setActive(true);
        }
    };

    const onEditDone = () => {
        if(editOptValue.val?.trim() !== '' && editOptValue.id !== null){
            let updatedBlockArea = cloneDeep({...blockArea});
            const options = cloneDeep([...updatedBlockArea[selectedPageIndex][selectedBlockIndex].options])
            options[editOptValue?.id] = cloneDeep({label: editOptValue.val, value: editOptValue.val});
            updatedBlockArea[selectedPageIndex][selectedBlockIndex] = {...updatedBlockArea[selectedPageIndex][selectedBlockIndex], options}
            setBlockArea(cloneDeep(updatedBlockArea));
            setEditingIndex(null)
            checkChange(updatedBlockArea, oldBlockAreas);
        }
    };

    const onEditOption = (newValue, id) => {
        setEditOptValue({val: newValue, id: id, mainIndex: selectedPageIndex, subIndex: selectedBlockIndex})
    };

    const onDeleteOption = (id) => {
        const updatedBlockArea = { ...blockArea };
        const currentOptions = updatedBlockArea[selectedPageIndex][selectedBlockIndex].options || [];
        currentOptions.splice(id, 1);
        updatedBlockArea[selectedPageIndex][selectedBlockIndex].options = currentOptions;
        setBlockArea(updatedBlockArea);
        setEditingIndex(null)
        checkChange(updatedBlockArea, oldBlockAreas)
    };

    const handleChangeStyles = (name, value, category) => {
        const updatedBlockArea = {...blockArea};
        if (category === 'style') {
            updatedBlockArea[selectedPageIndex][selectedBlockIndex][category] =
                {...updatedBlockArea[selectedPageIndex][selectedBlockIndex][category], [name]: value};
        } else {
            if (name === 'fileUploadMinLimit') {
                if (parseInt(updatedBlockArea[selectedPageIndex][selectedBlockIndex]['fileUploadMaxLimit']) >= parseInt(value)) {
                    updatedBlockArea[selectedPageIndex][selectedBlockIndex][name] = value;
                }
            } else if (name === 'fileUploadMaxLimit') {
                if (parseInt(updatedBlockArea[selectedPageIndex][selectedBlockIndex]['fileUploadMinLimit']) <= parseInt(value)) {
                    updatedBlockArea[selectedPageIndex][selectedBlockIndex][name] = value;
                }
            } else {
                updatedBlockArea[selectedPageIndex][selectedBlockIndex][name] = value;
            }
        }
        if (name === 'fileFormats'){
            checkChange(updatedBlockArea[selectedPageIndex][selectedBlockIndex][name], oldBlockAreas[selectedPageIndex][selectedBlockIndex][name])
        } else {
            checkChange(updatedBlockArea, oldBlockAreas)
        }
        setBlockArea(updatedBlockArea);
    };

    const toggleActive = useCallback(() => {
        setActive(false)
        setIsError(false)
        setMessage('')
    }, []);

    const deleteSingleField = () => {
        if (selectedPageIndex !== null && selectedBlockIndex !== null) {
            const clone = {...blockArea};
            const updatedBlockArea = [...clone[selectedPageIndex]];
            const deletedField = updatedBlockArea?.splice(selectedBlockIndex, 1)[0];
            const updatedArea = { ...clone, [selectedPageIndex]: updatedBlockArea };
            checkChange(updatedArea, oldBlockAreas);
            setBlockArea(updatedArea);
            if (deletedField && deletedField.id) {
                setDeletedFieldsArray(previous => [...previous, deletedField.id]);
            }
            setSelectedPageIndex(null);
            setSelectedBlockIndex(null);
            setSelectedFieldType(null);
        }
    };

    const onDeletePage = async (event,pageKey) => {
        if(event){
            event.stopPropagation()
        }
        const idsArray = (blockArea[pageKey] || []).map((x) => x?.id).filter((id) => id !== undefined);
        if(idsArray && idsArray.length > 0) {
            setDeletedFieldsArray(previous => {
                const filteredArray = previous?.filter(item => !idsArray?.includes(item));
                return [...filteredArray, ...idsArray];
            });
        }
        let newBlockArea = { ...blockArea };
        delete newBlockArea[pageKey];
        let obj = {}
        Object.keys(newBlockArea).map((x, i) => {
            let array= [];
            newBlockArea[x].map((y) => {
                let obj = {...y, step: i + 1}
                array.push(obj)
            })
            obj[i+1] = array
        })
        setBlockArea(obj);
        checkChange(obj, oldBlockAreas)
        setSelectedPageIndex(null);
        setSelectedBlockIndex(null);
        setSelectedFieldType(null)
        setSelectedHeadPageIndex(0)
        setPageTitle('Page - 1')
    };

    const commonProps = {
        isLoading,
        blockArea,
        setBlockArea,
        selectedPageIndex,
        selectedBlockIndex,
        selectedFieldType,
        setSelectedPageIndex,
        setSelectedBlockIndex,
        setSelectedFieldType,
        handleChangeStyles,
        addOptions,
        optionAddRef,
        onEditOption,
        onEditDone,
        onDeleteOption,
        optValue,
        setOptValue,
        editingIndex,
        setEditingIndex,
        editOptValue,
        deleteSingleField,
        checkChange,
        oldBlockAreas,
        formData
    }

    const commonPropsLeftBar = {
        leftRef,
        isLoading,
        formData,
        blockArea,
        selectedPageIndex,
        selectedBlockIndex,
        setSelectedPageIndex,
        setSelectedBlockIndex,
        setSelectedFieldType,
        onSetUpMiddle,
        onUseThisBlock,
        setBlockArea,
        popoverActive,
        setPopoverActive,
        checkChange,
        oldBlockAreas,
        setDeletedFieldsArray,
        setSelectedHeadPageIndex,
        selectedHeadPageIndex,
        onDeletePage,
        findLeftRef
    }

    const headerProps = {
        blockArea,
        isLoading,
        formData,
        isMobileView,
        setIsMobileView,
        onBack,
        onSave,
        onPublish,
        loading,
        type,
        isEditName,
        setIsEditName,
        editName,
        setEditName,
        onSaveFromName,
        isDirty,
        isDirtyCustomize,
        onUseThisBlock,
        setBlockArea,
        checkChange,
        oldBlockAreas,
        setSelectedHeadPageIndex,
        selectedHeadPageIndex,
        isSaved,
        onDeletePage,
        pageTitle,
        setPageTitle
    }

    const objColors = {
        backgroundColor: formData?.style?.buttonBg,
        color: formData?.style?.buttonTextColor,
        fill: formData?.style?.buttonTextColor,
    }

    const customizeProps = {
        isLoading,
        formData,
        formError,
        handleChangeCustomize,
        handleRemove,
    }

    const commonPropsMiddleBar = {
        middleRef,
        isLoading,
        isMobileView,
        formData,
        blockArea,
        onSetUpMiddle,
        selectedPageIndex,
        selectedBlockIndex,
        objColors,
        setSelectedHeadPageIndex,
        selectedHeadPageIndex,
        findMiddleRef
    }

    const commonPropsThankYou = {
        formData,
        handleChangeCustomize,
        objColors,
        formError,
        isMobileView
    }

    const commonPropsMiniBar = {
        handleTabChange,
        type,
        setIsShareModal,
        isShareModal,
        isHtmlModal,
        setIsHtmlModal,
        formData,
        isLoading,
        isDirty,
    }

    const commonPropsShare = {
        isShareModal,
        setIsShareModal,
        formData,
        isLoading
    }

    const commonPropsHtml = {
        formData,
        isHtmlModal,
        setIsHtmlModal,
        blockArea,
        isLoading,
    }

    return (
        <Fragment>
            {(active && message !== '') && <Toast content={message} onDismiss={toggleActive} error={isError}/>}

             <div className={"main-screen"}>
                    <div className={"inner-screen"}>
                        <Header {...{headerProps}}/>

                        <div className={`middleware direction-${formData?.style?.formDirection}`}>
                            <MiniBar {...{commonPropsMiniBar}}/>

                            {
                                (type === 'form') &&
                                <Fragment>
                                    <LeftBar {...{commonPropsLeftBar}}/>

                                    <MiddleBar {...{commonPropsMiddleBar}}/>

                                    <RightBar {...{commonProps}}/>
                                </Fragment>
                            }

                            {(type === 'customize' &&  formData.formLayout != 3) && <Customize {...{customizeProps, commonPropsMiddleBar}}/>}
                            {type === 'thank-you' && <ThankYou {...{commonPropsThankYou}}/>}
                            {type === 'integrate' && <Integrate {...{formData, formFieldsAll}} />}
                            {type === 'submission' && <Submissions {...{formData, setActive, setMessage, setIsError}}/>}
                        </div>
                    </div>
                </div>

            <ShareModal {...{commonPropsShare}}/>
            {isHtmlModal && <Html {...{commonPropsHtml}}/>}
        </Fragment>
    );
};

export default FormDetails;