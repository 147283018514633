import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {Button, InlineStack, Toast} from "@shopify/polaris";
import {Icons} from "../../Utils/Icons";
import {loadAuth2, loadGapiInsideDOM} from "gapi-script";
import {apiService, baseUrl, capitalizeMessage, getToken, googleClientId} from "../../Utils/Constent";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setUserDetails} from "../../Redux/Action/Action";

const WithGoogle = ({text, isLoading, isGoogleLoading, setIsGoogleLoading}) => {
    const navigate = useNavigate();

    const [gapi, setGapi] = useState(null);
    const [active, setActive] = useState(false);
    const [message, setMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!getToken()) {
            const loadGapi = async () => {
                const newGapi = await loadGapiInsideDOM();
                setGapi(newGapi);
            }
            loadGapi();
        }
    }, [getToken()]);

    const handleGoogleLogin = async () => {
        try {
            const auth2 = await loadAuth2(gapi, googleClientId, "profile");
            const googleUser = await auth2.signIn();
            updateUser(googleUser);
        } catch (error) {
            console.error('Google sign-in error:', error);
        }
    };

    const updateUser = async (currentUser) => {
        // const name = currentUser.getBasicProfile().getName();
        const getEmail = currentUser.getBasicProfile().getEmail();
        let firstName = currentUser.getBasicProfile().getGivenName() ;
        let lastName = currentUser.getBasicProfile().getFamilyName() ;
        let userId = currentUser.getBasicProfile().getId() ;
        const profileImg = currentUser.getBasicProfile().getImageUrl();

        const payload = {
            firstName: firstName,
            lastName: lastName,
            email: getEmail,
            googleId: userId,
            profileImage: profileImg,
        }
        setIsGoogleLoading(true);
        const response = await apiService.loginWithGoogle(payload);
        if (response.success === true) {
            setIsGoogleLoading(false);
            setMessage(capitalizeMessage(response?.message));
            setActive(true);
            setIsError(false);
            dispatch(setUserDetails(response?.data));
            localStorage.setItem("token", response?.data?.token);
            let userDetails = { ...response?.data };
            delete userDetails?.token;
            localStorage.setItem("user-details", JSON.stringify(userDetails));
            if(response?.data?.enabled2fa == 1){
                navigate(`${baseUrl}/verify-2fa`);
            }   else {
                navigate(`${baseUrl}/dashboard`);
            }
        } else {
            setIsGoogleLoading(false);
            setActive(true);
            setMessage(response.error.message);
            setIsError(true);
            signOut();
        }
    };

    const signOut = () => {
        const auth2 = gapi.auth2.getAuthInstance();
        auth2.signOut().then(() => {
            console.log('User signed out.');
        });
    }

    const toggleActive = useCallback(() => {
        setActive(false)
        setIsError(false)
        setMessage('')
    }, []);

    return (
        <Fragment>
            {(active && message !== '') && <Toast content={message} onDismiss={toggleActive} error={isError} duration={3500} />}

            <Button size="large" onClick={isLoading ? null : handleGoogleLogin} loading={isGoogleLoading}>
                <InlineStack gap={200}>{isGoogleLoading ? null : Icons.google} {text}</InlineStack>
            </Button>
        </Fragment>
    );
};

export default WithGoogle;