import {SET_PLAN_DETAILS} from "../../Utils/Constent";

const initialState = {
    planInterval: 1,
    planType: "0",
    org: []
};

export default function planDetailsReducer(state = initialState, action) {
    switch (action.type) {
        case SET_PLAN_DETAILS: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}