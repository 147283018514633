import axios from "axios";
import {getToken, removeDetails, baseUrl} from "./Constent";
import qs from "qs";

const baseUrlApi = "https://app.webform.io/api";
const instance = axios.create();

instance.interceptors.request.use(function (config) {
    config.headers["Authorization"] = `Bearer ${getToken() || ''}`;
    return config;
})

export class ApiService {
    async fetchData(method, url, data, isFormData, header) {
        const config = {
            headers: {
                ...header || {},
                "content-type": isFormData
                    ? "multipart/form-data"
                    : "application/json",
            }
        };
        let result = '';
        try {
            const res = await instance[method](url, data, config);
            if (res.status === 200) {
                result = res?.data;
            } else if (res?.status === 403) {
                removeDetails();
                window.location.replace(`${baseUrl}/login`);
            } else {
                result = res?.data;
            }
        } catch (e) {
            if (e?.response?.status === 403) {
                removeDetails();
                window.location.replace(`${baseUrl}/login`);
            } else {
                result = e?.response?.data || '';
            }
        }
        return result;
    }

    //----------------------------API-Methods-----------------------------//
    async getData(url, header) {
        return await this.fetchData('get', url, null, false, header);
    }

    async postData(url, data, isFormData, header) {
        return await this.fetchData('post', url, data, isFormData, header);
    }

    async putData(url, data, isFormData, header) {
        return await this.fetchData('put', url, data, isFormData, header);
    }

    async deleteData(url, header) {
        return await this.fetchData('delete', url, null, false, header);
    }

    //---------------------------------------------------------------------//


    //--------------------------------API----------------------------------//
    async login(payload) {
        return await this.postData(`${baseUrlApi}/auth/login`, payload);
    }

    async verify2fa(payload) {
        return await this.postData(`${baseUrlApi}/profile/verify-2fa`, payload);
    }

    async loginWithGoogle(payload) {
        return await this.postData(`${baseUrlApi}/auth/login-with-google`, payload);
    }

    async register(payload) {
        return await this.postData(`${baseUrlApi}/auth/register`, payload);
    }

    async forgotPassword(payload) {
        return await this.postData(`${baseUrlApi}/auth/forgot-password`, payload);
    }

    async resetPassword(payload) {
        return await this.postData(`${baseUrlApi}/auth/reset-password`, payload);
    }

    async getAnalytics(payload) {
        return await this.postData(`${baseUrlApi}/analytics/analytics`, payload);
    }

    async createWorkSpace(payload) {
        return await this.postData(`${baseUrlApi}/workSpace/create`, payload);
    }

    async getWorkspaceForm() {
        return await this.getData(`${baseUrlApi}/workSpace/workspace-form`);
    }

    async workspaceFormUpdateDelete(id, payload) {
        return await this.putData(`${baseUrlApi}/workSpace/update/${id}`, payload);
    }

    async createForm(payload) {
        return await this.postData(`${baseUrlApi}/form/create`, payload);
    }

    async duplicateCreateForm(payload) {
        return await this.postData(`${baseUrlApi}/form/duplicate`, payload);
    }

    async createFieldForm(payload) {
        return await this.postData(`${baseUrlApi}/form/field/create`, payload, true);
    }

    async getAllForms(payload) {
        return await this.getData(`${baseUrlApi}/form/get?${qs.stringify(payload)}`);
    }

    async getFormField(formId, workSpaceId) {
        return await this.getData(`${baseUrlApi}/form/field/${formId}/${workSpaceId}`);
    }

    async deleteForm(id) {
        return await this.deleteData(`${baseUrlApi}/form/delete/${id}`);
    }

    async formUpdate(id, payload, isFormData) {
        return await this.putData(`${baseUrlApi}/form/update/${id}`, payload, isFormData);
    }

    async getSubmittedForms(payload) {
        return await this.postData(`${baseUrlApi}/form/submitted-forms`, payload);
    }

    async connectIntegration(payload) {
        return await this.postData(`${baseUrlApi}/integrations/integration`, payload);
    }

    async getIntegrations(id) {
        return await this.getData(`${baseUrlApi}/integrations/${id}`);
    }

    async disconnectIntegration(id) {
        return await this.deleteData(`${baseUrlApi}/integrations/${id}`);
    }

    async getWebhooks(payload) {
        return await this.getData(`${baseUrlApi}/webhooks/get?${qs.stringify(payload)}`);
    }

    async webhooksRetry(id) {
        return await this.getData(`${baseUrlApi}/webhooks/re-try/${id}`);
    }

    async updateProfile(payload) {
        return await this.postData(`${baseUrlApi}/profile/update`, payload, true);
    }

    async getProfile() {
        return await this.getData(`${baseUrlApi}/profile/get`);
    }

    async enable2FA(payload) {
        return await this.postData(`${baseUrlApi}/profile/enable-2fa`, payload);
    }

    async disable2FA(payload) {
        return await this.postData(`${baseUrlApi}/profile/disable-2fa`, payload);
    }

    async deleteAccount(payload) {
        return await this.postData(`${baseUrlApi}/auth/delete-account`, payload);
    }

    async acceptZapier(payload) {
        return await this.postData(`${baseUrlApi}/zapier/authorize`, payload);
    }

    async acceptPlan(payload) {
        return await this.postData(`${baseUrlApi}/plan/active`, payload);
    }

    async getPlanPrice() {
        return await this.getData(`${baseUrlApi}/plan/getPlan`);
    }

    async getTrash(payload) {
        return await this.getData(`${baseUrlApi}/form/get-trash?${qs.stringify(payload)}`);
    }

    async reStore(id) {
        return await this.getData(`${baseUrlApi}/form/restore/${id}`);
    }

    async memberInvite(payload) {
        return await this.postData(`${baseUrlApi}/member/invite`, payload);
    }

    async getMember() {
        return await this.getData(`${baseUrlApi}/member/get-members`);
    }

    async getWorkspaceMember() {
        return await this.getData(`${baseUrlApi}/member/get-workspace-members`);
    }

    async deleteMember(payload) {
        return await this.postData(`${baseUrlApi}/member/delete-member`, payload);
    }

    async cancelInvitation(payload) {
        return await this.postData(`${baseUrlApi}/member/cancel-invitation`, payload);
    }

    async getInvitation() {
        return await this.getData(`${baseUrlApi}/member/get-invitation`);
    }

    async acceptInvitation(payload) {
        return await this.postData(`${baseUrlApi}/member/accept-or-reject`, payload);
    }

    async getOrganization(payload) {
        return await this.postData(`${baseUrlApi}/member/organization`, payload);
    }

}
