import React, {Fragment, useState} from 'react';
import {BlockStack, Box, Button, Tooltip, ButtonGroup, Checkbox, Divider, DropZone, Text, TextField, Icon, Select} from "@shopify/polaris";
import {commonParagraph} from "../../../../../Utils/Loader";
import ColorPicker from "../../../../Common/ColorPicker";
import {imagePath} from "../../../../../Utils/Constent";
import {useWindowSize} from "../../CommonUse/CommonUse";
import {ChevronDownIcon, ChevronUpIcon, InfoIcon} from "@shopify/polaris-icons";

const CmLeftBar = ({customizeProps}) => {
    const {isLoading, formData, formError, handleChangeCustomize, handleRemove} = customizeProps;
    const [isToggle, setIsToggle] = useState(false);
    const [sizeError, setSizeError] = useState('');
    const [sizeBannerError, setSizeBannerError] = useState('');

    const {width} = useWindowSize();

    const uploadedLogo = formData && formData?.logo?.name ?
        (<div className={"parent-pro"}><div className="profile-thumbnail logo-thumbnail"><img alt={"logo-thumbnail"} src={URL.createObjectURL(formData?.logo)}/></div></div>) :
        formData?.logo?.length > 0 ? (<div className={"parent-pro"}><div className="profile-thumbnail logo-thumbnail"><img alt={"logo-thumbnail"} src={`${imagePath}${formData?.logo}`}/></div></div>):
            <DropZone.FileUpload/> ;

    const uploadedBanner = formData && formData?.banner?.name ?
        (<div className={"parent-pro"}><div className="profile-thumbnail banner-thumbnail"><img alt={"banner-thumbnail"} src={URL.createObjectURL(formData?.banner)}/></div></div>) :
        formData?.banner?.length > 0 ? (<div className={"parent-pro"}><div className="profile-thumbnail banner-thumbnail"><img alt={"banner-thumbnail"} src={`${imagePath}${formData?.banner}`}/></div></div>):
            <DropZone.FileUpload/> ;

    const bytesToMB = (bytes) => {
        const megabytes = bytes / (1024 * 1024);
        return megabytes.toFixed(2);
    }

    const handleDropZoneLogo = (_dropFiles, acceptedFiles, _rejectedFiles) => {
        const maxSizeInBytes = 2 * 1024 * 1024;
        if (acceptedFiles[0]?.size > maxSizeInBytes) {
            setSizeError(`File size ${bytesToMB(acceptedFiles[0]?.size)} MB. The file that was added is larger than 2 MB.`);
        } else {
            handleChangeCustomize('logo', acceptedFiles && acceptedFiles.length ? acceptedFiles[0] : '')
            setSizeError('')
        }
    }

    const handleDropZoneBanner = (_dropFiles, acceptedFiles, _rejectedFiles) => {
        const maxSizeInBytes = 5 * 1024 * 1024;
        if (acceptedFiles[0]?.size > maxSizeInBytes) {
            setSizeBannerError(`File size ${bytesToMB(acceptedFiles[0]?.size)} MB. The file that was added is larger than 5 MB.`);
        } else {
            handleChangeCustomize('banner', acceptedFiles && acceptedFiles.length ? acceptedFiles[0] : '')
            setSizeBannerError('')
        }
    }

    const buttonPositionOpt = [
        {label: 'Left', value: 'start'},
        {label: 'Center', value: 'center'},
        {label: 'Right', value: 'end'},
    ]

    if(formData?.formLayout == 2){
        buttonPositionOpt.push(
            {label: 'Separate ', value: 'space-between'},
        )
    }

    return (
        <Fragment>
            <div className={`left-bar ${(width <= 767) ? 'fixed' : ''} ${isToggle}`}>
                {
                    isLoading ? <Box paddingBlock={"300"} paddingInline={"400"}>{commonParagraph(4)}</Box> :
                        <Fragment>
                            {
                                (width <= 767) && <div className={"sticky"}>
                                    <Box paddingBlock={"300"} paddingBlockEnd={'200'} borderBlockEndWidth={"025"} borderColor={"border-secondary"} borderStyle={"solid"}>
                                        <div onClick={() => setIsToggle(!isToggle)} className={"cursor-pointer"}>
                                            <BlockStack  align={"center"} inlineAlign={"center"}>
                                                <Tooltip content={`${isToggle ? 'Close' : 'Open'} Customize`} dismissOnMouseOut>
                                                    <Button variant={"monochromePlain"} icon={isToggle ? ChevronDownIcon : ChevronUpIcon}/>
                                                </Tooltip>
                                                <Text variant={"headingMd"}>Customize</Text>
                                            </BlockStack>
                                        </div>
                                    </Box>
                                </div>
                            }

                            <Box paddingBlock={"300"} paddingInline={"400"}>
                                <BlockStack gap={"200"}>
                                    <BlockStack>
                                        <div className="Polaris-Labelled__LabelWrapper">
                                            <div className="Polaris-Label"><label className="Polaris-Label__Text">Logo</label></div>
                                            <Tooltip content={"Remove logo image"}>
                                                <Button variant={"plain"} onClick={()=> handleRemove('logo')}>Remove</Button>
                                            </Tooltip>
                                        </div>
                                        <DropZone
                                            labelHidden dropOnPage={false}
                                            onDrop={handleDropZoneLogo}
                                            accept="image/*"
                                            type="image" allowMultiple={false}
                                            error={true}
                                        >
                                            {uploadedLogo}
                                        </DropZone>
                                        {sizeError?.trim() !== '' &&
                                            <div className="Polaris-Labelled__Error">
                                                <div className="Polaris-InlineError">
                                                    <div className="Polaris-InlineError__Icon">
                                                        <Icon source={InfoIcon}/>
                                                    </div>
                                                    {sizeError}
                                                </div>
                                            </div>
                                        }
                                        <div className="Polaris-Labelled__HelpText">
                                            <Text as={"span"} variant={"bodyMd"} tone={"caution"} fontWeight={"medium"}>Recommended image size (100x100)</Text>
                                        </div>
                                        <Text as={"span"} variant={"bodyMd"} tone={"subdued"}>Make sure your file is no larger than 2 MB.</Text>
                                    </BlockStack>

                                    <BlockStack gap={"100"}>
                                        <Text>Logo Position</Text>
                                        <ButtonGroup variant="segmented" fullWidth>
                                            {['left', 'center', 'right'].map((x) => (
                                                <Button
                                                    key={x} fullWidth size={"large"}
                                                    pressed={formData?.style?.logoPosition === x}
                                                    onClick={() => handleChangeCustomize("logoPosition", x, 'style')}
                                                >
                                                    {x.charAt(0).toUpperCase() + x.slice(1)}
                                                </Button>
                                            ))}
                                        </ButtonGroup>
                                    </BlockStack>
                                </BlockStack>
                            </Box>
                            <Divider/>

                            <Box paddingBlock={"300"} paddingInline={"400"}>
                                <BlockStack gap={"200"}>
                                    <BlockStack>
                                        <div className="Polaris-Labelled__LabelWrapper">
                                            <div className="Polaris-Label"><label className="Polaris-Label__Text">Banner</label></div>
                                            <Tooltip content={"Remove banner image"}>
                                                <Button variant={"plain"} onClick={()=> handleRemove('banner')}>Remove</Button>
                                            </Tooltip>
                                        </div>
                                        <DropZone labelHidden dropOnPage={false}
                                                  onDrop={handleDropZoneBanner}
                                                  accept="image/*"
                                                  type="image" allowMultiple={false}>
                                            {uploadedBanner}
                                        </DropZone>
                                        {sizeBannerError?.trim() !== '' &&
                                        <div className="Polaris-Labelled__Error">
                                            <div className="Polaris-InlineError">
                                                <div className="Polaris-InlineError__Icon">
                                                    <Icon source={InfoIcon}/>
                                                </div>
                                                {sizeBannerError}
                                            </div>
                                        </div>
                                        }
                                        <div className="Polaris-Labelled__HelpText">
                                            <Text as={"span"} variant={"bodyMd"} tone={"subdued"}>Make sure your file is no larger than 5 MB.</Text>
                                        </div>
                                    </BlockStack>

                                    <BlockStack gap={"100"}>
                                        <Text>Banner Background Position</Text>
                                        <ButtonGroup variant="segmented" fullWidth>
                                            {['top', 'center', 'bottom'].map((x) => (
                                                <Button
                                                    key={x} fullWidth size={"large"}
                                                    pressed={formData?.style?.bannerPosition === x}
                                                    onClick={() => handleChangeCustomize("bannerPosition", x, 'style')}
                                                >
                                                    {x.charAt(0).toUpperCase() + x.slice(1)}
                                                </Button>
                                            ))}
                                        </ButtonGroup>
                                    </BlockStack>
                                </BlockStack>
                            </Box>

                            <Divider/>
                            <Box paddingBlock={"300"} paddingInline={"400"}>
                                <BlockStack gap={"200"}>
                                    <BlockStack gap={"100"}>
                                        <Text>Banner Size</Text>
                                        <ButtonGroup variant="segmented" fullWidth>
                                            {['fullwidth', 'container'].map((x) => (
                                                <Button
                                                    key={x} fullWidth size={"large"}
                                                    pressed={formData?.style?.bannerSize === x}
                                                    onClick={() => handleChangeCustomize("bannerSize", x, 'style')}
                                                >
                                                    {x.charAt(0).toUpperCase() + x.slice(1)}
                                                </Button>
                                            ))}
                                        </ButtonGroup>
                                    </BlockStack>

                                    <BlockStack gap={"100"}>
                                        <Text>Form Size</Text>
                                        <ButtonGroup variant="segmented" fullWidth>
                                            {['small', 'medium', 'large'].map((x) => (
                                                <Button
                                                    key={x} fullWidth size={"large"}
                                                    pressed={formData?.style?.formSize === x}
                                                    onClick={() => handleChangeCustomize("formSize", x, 'style')}
                                                >
                                                    {x.charAt(0).toUpperCase() + x.slice(1)}
                                                </Button>
                                            ))}
                                        </ButtonGroup>
                                    </BlockStack>
                                </BlockStack>
                            </Box>

                            <Divider/>
                            <Box paddingBlock={"300"} paddingInline={"400"}>
                                <BlockStack gap={"100"}>
                                    <Text>Form Direction</Text>
                                    <ButtonGroup variant="segmented" fullWidth>
                                        {['ltr', 'rtl'].map((x) => (
                                            <Button
                                                key={x} fullWidth size={"large"}
                                                pressed={formData?.style?.formDirection === x}
                                                onClick={() => handleChangeCustomize("formDirection", x, 'style')}
                                            >
                                                {x === 'ltr' ? "Left to Right" : "Right to Left"}
                                            </Button>
                                        ))}
                                    </ButtonGroup>
                                </BlockStack>
                            </Box>

                            <Divider/>
                            <Box paddingBlock={"300"} paddingInline={"400"}>
                                <BlockStack gap={"200"}>
                                    <ColorPicker label={"Label Color"} name="globalLabelColor"
                                                 value={formData?.style?.globalLabelColor}
                                                 onChange={(value) => handleChangeCustomize('globalLabelColor', value, 'style')}
                                    />

                                    <ColorPicker label={"Text Color"} name="globalTextColor"
                                                 value={formData?.style?.globalTextColor}
                                                 onChange={(value) => handleChangeCustomize('globalTextColor', value, 'style')}
                                    />
                                    <ColorPicker label={"Description Color"} name="globalDescColor"
                                                 value={formData?.style?.globalDescColor}
                                                 onChange={(value) => handleChangeCustomize('globalDescColor', value, 'style')}
                                    />
                                    <ColorPicker label={"Placeholder Color"} name="globalPlaceColor"
                                                 value={formData?.style?.globalPlaceColor}
                                                 onChange={(value) => handleChangeCustomize('globalPlaceColor', value, 'style')}
                                    />
                                    <ColorPicker label={"Error Color"} name="globalErrorColor"
                                                 value={formData?.style?.globalErrorColor}
                                                 onChange={(value) => handleChangeCustomize('globalErrorColor', value, 'style')}
                                    />
                                    <ColorPicker label={"Background Color"} name="globalBg"
                                                 value={formData?.style?.globalBg}
                                                 onChange={(value) => handleChangeCustomize('globalBg', value, 'style')}
                                    />
                                </BlockStack>
                            </Box>

                            <Divider/>
                            <Box paddingBlock={"300"} paddingInline={"400"}>
                                <BlockStack gap={"200"}>
                                    {
                                        formData.formLayout == 2 &&
                                            <Fragment>
                                                <Checkbox label={"Button Icon?"}
                                                          checked={formData?.style?.buttonIcon == '1'}
                                                          onChange={(checked) => handleChangeCustomize('buttonIcon', checked ? '1' : '0', 'style')}
                                                          helpText={""}
                                                />
                                                <Divider/>
                                            </Fragment>
                                    }

                                    {formData.formLayout == 2 &&
                                        (formData?.style?.buttonIcon == '0') && <Fragment>
                                            <TextField
                                                label="Next Button Text"
                                                placeholder={"Type a button text"}
                                                value={formData?.style?.nextButtonText}
                                                onChange={(value) => handleChangeCustomize('nextButtonText', value, 'style')}
                                                autoComplete="off"
                                                error={formError.nextButtonText}
                                            />
                                            <TextField
                                                label="Previous Button Text"
                                                placeholder={"Type a button text"}
                                                value={formData?.style?.previousButtonText}
                                                onChange={(value) => handleChangeCustomize('previousButtonText', value, 'style')}
                                                autoComplete="off"
                                                error={formError.previousButtonText}
                                            />
                                        </Fragment>
                                    }

                                    <TextField
                                        label="Submit Button Text"
                                        placeholder={"Type a button text"}
                                        value={formData?.style?.submitButtonText}
                                        onChange={(value) => handleChangeCustomize('submitButtonText', value, 'style')}
                                        autoComplete="off"
                                        error={formError.submitButtonText}
                                    />
                                </BlockStack>
                            </Box>

                            <Divider/>
                            <Box paddingBlock={"400"} paddingInline={"400"}>
                                <BlockStack gap={"200"}>
                                    <ColorPicker label={formData?.style?.buttonIcon == '1' ? "Button Icon Color" : "Button Text Color"} name="buttonTextColor"
                                                 value={formData?.style?.buttonTextColor}
                                                 onChange={(value) => handleChangeCustomize('buttonTextColor', value, 'style')}
                                    />
                                    <ColorPicker label={"Button Background Color"} name="buttonBg"
                                                 value={formData?.style?.buttonBg}
                                                 onChange={(value) => handleChangeCustomize('buttonBg', value, 'style')}
                                    />
                                    {/*<BlockStack gap={"100"}>*/}
                                    {/*    <Text>Button Position</Text>*/}
                                    {/*    <ButtonGroup variant="segmented" fullWidth>*/}
                                    {/*        {['left', 'center', 'right'].map((x) => (*/}
                                    {/*            <Button*/}
                                    {/*                key={x} fullWidth size={"large"}*/}
                                    {/*                pressed={formData?.style?.buttonPosition === x}*/}
                                    {/*                onClick={() => handleChangeCustomize("buttonPosition", x, 'style')}*/}
                                    {/*            >*/}
                                    {/*                {x.charAt(0).toUpperCase() + x.slice(1)}*/}
                                    {/*            </Button>*/}
                                    {/*        ))}*/}
                                    {/*    </ButtonGroup>*/}
                                    {/*</BlockStack>*/}
                                    <Select
                                        label="Button Position"
                                        options={buttonPositionOpt}
                                        onChange={(value) => handleChangeCustomize("buttonPosition", value, 'style')}
                                        value={formData?.style?.buttonPosition}
                                    />

                                </BlockStack>
                            </Box>
                        </Fragment>
                }
            </div>
        </Fragment>
    );
};

export default CmLeftBar;