import React, {Fragment} from 'react';

const CommonHtmlInput = ({commonListProps}) => {
    const {fieldType, blockArea, selectedPageIndex, selectedBlockIndex} = commonListProps;
    const {
        title = '',
        isRequired = 0,
        placeholder = '',
        errorMessage = '',
        id,
        fileFormats,
        fileUploadMinLimit,
        fileUploadMaxLimit,
        captchaKey,
    } = blockArea[selectedPageIndex][selectedBlockIndex] || {};

    return (
        <Fragment>
            {
                fieldType == 15 ?
                    <div>
                        <div className="g-recaptcha" name="g-recaptcha-response" id={id} data-field={fieldType} data-sitekey={captchaKey || undefined}></div>
                        <span className="error error_g-recaptcha-response"></span>
                    </div> :
                    <div>
                        {title?.trim() !== '' && <label>{title}</label>}
                        {fieldType == 2 ?
                            <textarea
                                id={id}
                                name={id}
                                placeholder={placeholder || undefined}
                                required={isRequired == 1}
                                data-error={isRequired == 1 ? errorMessage : undefined}
                                data-field={fieldType}
                            /> :
                            <input
                                id={id}
                                name={id}
                                accept={fieldType == 13 ? fileFormats : undefined}
                                placeholder={placeholder || undefined}
                                required={isRequired == 1}
                                multiple={fieldType == 13 ? fileUploadMaxLimit > 1 : undefined}
                                type={fieldType == 13 ? "file" : fieldType == 4 ? "email" : (fieldType == 5 || fieldType == 10) ? "number" : fieldType == 9 ? "date" : "text"}
                                data-error={isRequired == 1 ? errorMessage : undefined}
                                data-field={fieldType}
                                data-filemin={fieldType == 13 ? fileUploadMinLimit : undefined}
                                data-filemax={fieldType == 13 ? fileUploadMaxLimit : undefined}
                            />
                        }
                        {((fieldType == 4 || fieldType == 5 || fieldType == 11) || isRequired == 1) ?
                            <span className="error"></span> : ""}
                    </div>
            }
        </Fragment>
    );
};

export default CommonHtmlInput;