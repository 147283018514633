import React, {Fragment} from 'react';
import {Box, Text, BlockStack, Divider, Icon, InlineStack, Button, Tooltip} from "@shopify/polaris";
import {ChevronDownIcon, ChevronLeftIcon, DeleteIcon, LayoutBuyButtonIcon} from "@shopify/polaris-icons";
import CommonInputStyle from "../StylesList/CommonInputStyle";
import WithColorStyle from "../StylesList/WithColorStyle";
import VideoStyle from "../StylesList/VideoStyle";
import ImgStyle from "../StylesList/ImgStyle";
import CaptchaKey from "../StylesList/CaptchaKey";
import FileUploadStyle from "../StylesList/FileUploadStyle";
import {labelFieldType, useWindowSize} from "../CommonUse/CommonUse";
import {commonParagraph} from "../../../../Utils/Loader";

const RightBar = ({commonProps}) => {
    const {isLoading,selectedFieldType, selectedPageIndex, selectedBlockIndex, setSelectedPageIndex, setSelectedBlockIndex, setSelectedFieldType, deleteSingleField, optionAddRef} = commonProps;
    const {width} = useWindowSize();

    const renderComponentByFieldType = (fieldType) => {
        switch (fieldType) {
            case "1":
            case "2":
            case "3":
            case "4":
            case "5":
            case "6":
            case "7":
            case "8":
            case "9":
            case "10":
            case "11":
            case "12":
            case "14":
                return <CommonInputStyle {...{commonProps, fieldType}}/>;
            case "13":
                return <FileUploadStyle {...{commonProps}}/>;
            case "21":
                return <ImgStyle {...{commonProps}}/>;
            case "16":
            case "17":
            case "18":
            case "19":
            case "20":
                return <WithColorStyle {...{commonProps, fieldType}}/>;
            case "22":
                return <VideoStyle {...{commonProps}}/>;
            case "15":
                return <CaptchaKey {...{commonProps}}/>;
            default:
                return null;
        }
    };

    const onBack = () => {
        setSelectedPageIndex(null)
        setSelectedBlockIndex(null)
        setSelectedFieldType(null)
    }

    return (
        <Fragment>
            { selectedFieldType && width <= 1200 || width > 1200 ?
                <Fragment>
                    {
                        ((width <= 1200) && (selectedFieldType || selectedPageIndex || selectedBlockIndex)) || width > 1200 ?
                            <div ref={optionAddRef}  className={`right-bar ${(width <= 1200) ? 'fixed' : ''} ${(selectedFieldType || selectedPageIndex || selectedBlockIndex) ? 'true' : 'false'}`}>
                                {isLoading ? <Box padding={"400"}>{commonParagraph(4)}</Box> : selectedFieldType &&
                                    (selectedFieldType || selectedPageIndex || selectedBlockIndex) ?
                                        <Fragment>
                                            <div className={`sticky-bar`}>
                                                <Box padding={"400"} paddingBlockStart={width <= 767 ? "300" : "400"} paddingBlockEnd={(width <= 1200) ? '200' : '400'}>
                                                    {
                                                        (width <= 767) ? <div className={"sticky"}>
                                                            <div onClick={onBack} className={"cursor-pointer"}>
                                                                <BlockStack  align={"center"} inlineAlign={"center"}>
                                                                    <Tooltip content={"Open Sections"} dismissOnMouseOut>
                                                                        <Button variant={"monochromePlain"} icon={ChevronDownIcon}/>
                                                                    </Tooltip>
                                                                    <Text variant={"headingMd"}>{labelFieldType[selectedFieldType] || labelFieldType.default}</Text>
                                                                </BlockStack>
                                                            </div>
                                                        </div> : (width <= 1200) ? <Fragment>
                                                                <InlineStack align={"space-between"} blockAlign={"center"}>
                                                                    <Tooltip content={"Close"} dismissOnMouseOut>
                                                                        <Button icon={ChevronLeftIcon} variant={"monochromePlain"} onClick={onBack}>
                                                                            <Text variant={"headingMd"}>{labelFieldType[selectedFieldType] || labelFieldType.default}</Text>
                                                                        </Button>
                                                                    </Tooltip>
                                                                </InlineStack>
                                                            </Fragment> :
                                                            <BlockStack gap={"200"}>
                                                                <Text variant={"headingLg"}>{labelFieldType[selectedFieldType] || labelFieldType.default}</Text>
                                                                <Text variant={"p"} tone={"subdued"}>Make you form your own by adding your brand
                                                                    color and logo.</Text>
                                                            </BlockStack>
                                                    }
                                                </Box>
                                                <Divider/>
                                            </div>
                                            <div>
                                                {renderComponentByFieldType(selectedFieldType)}
                                            </div>
                                            <Box paddingBlockStart={"1200"}/>
                                            <div className={`sticky-bar-bottom`}>
                                                <Divider/>
                                                <Box padding={"400"} borderStyle={"solid"} borderColor={"border-secondary"}>
                                                    <InlineStack align={"start"}><Button tone={"critical"} variant={"plain"} onClick={deleteSingleField} icon={DeleteIcon}>Remove Block</Button></InlineStack>
                                                </Box>
                                                <Divider/>
                                            </div>
                                        </Fragment> :
                                        <Box borderBlockEndWidth={"025"} borderStyle={"solid"} borderColor={"border-secondary"} padding={"400"}>
                                            <BlockStack align={"start"} gap={"100"}>
                                                <div className="layout-btn">
                                                    <Icon source={LayoutBuyButtonIcon}/>
                                                </div>
                                                <Text fontWeight={"medium"} variant={"headingMd"}>Customize Your Form</Text>
                                                <Text tone={"subdued"}>Choose a section or block from the sidebar to get started.</Text>
                                            </BlockStack>
                                        </Box>
                                }
                            </div> : null
                    }
                </Fragment> : ""
            }
        </Fragment>
    );
};

export default RightBar;