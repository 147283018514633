import React, {Fragment, useCallback, useState} from 'react';
import AuthFrame from "../AuthStructure/AuthFrame";
import {BlockStack, Button, TextField, Icon, InlineStack, Text, Box} from "@shopify/polaris";
import {useNavigate} from "react-router-dom";
import {ArrowLeftIcon} from "@shopify/polaris-icons";
import {apiService, baseUrl, capitalizeMessage, emailRegExp, onKeyFire} from "../../Utils/Constent";

const initialState = {
    email: "",
};

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [authDetails, setAuthDetails] = useState(initialState);
    const [formError, setErrors] = useState(initialState);
    const [isLoading, setIsLoading] = useState(false);
    const [active, setActive] = useState(false);
    const [message, setMessage] = useState('');
    const [isError, setIsError] = useState(false);

    const onNavigate = (url) => {
        navigate(`${baseUrl}/${url}`)
    }

    const formValidate = (name, value) => {
        switch (name) {
            case "email":
                if (!value.trim()) {
                    return "Email is required.";
                } else if (!value.match(emailRegExp)) {
                    return "Enter a valid email address";
                } else {
                    return "";
                }
            default: {
                return "";
            }
        }
    };

    const onHandleChange = useCallback((name, value) => {
            setAuthDetails(prevTokenDetails => ({
                ...prevTokenDetails,
                [name]: value
            }));
            setErrors(formError => ({...formError, [name]: '',}));
        },
        [authDetails],
    );

    const onBlurChange = useCallback((name, value) => {
            setErrors(formError => ({
                ...formError,
                [name]: formValidate(name, value),
            }));
        },
        [authDetails],
    );

    const onSubmit = async () => {
        let errorData = {};
        Object.keys(authDetails).map((x) => {
            let error = formValidate(x, authDetails[x]);
            if (error && error.length > 0) {
                errorData[x] = error;
            }
        });
        if (Object.keys(errorData).length > 0) {
            setErrors(errorData);
            return;
        }
        setIsLoading(true);

        const payload = {
            ...authDetails
        };

        const response = await apiService.forgotPassword(payload);
        if (response.success === true) {
            setIsLoading(false);
            setMessage(capitalizeMessage(response?.message));
            setActive(true);
            setAuthDetails(initialState);
            setIsError(false)
        } else {
            setIsLoading(false);
            setActive(true);
            setMessage(response.error.message);
            setIsError(true);
        }
    }

    const toggleActive = useCallback(() => {
        setActive(false)
        setIsError(false)
        setMessage('')
    }, []);

    return (
        <Fragment>
            <AuthFrame title={"Forgot Password"} {...{message,isError, active, toggleActive}}>
                <Box as={"span"} width={"100%"}>
                    <BlockStack gap={"300"}>
                        <Box as={"span"} width={"100%"} onKeyPress={(e) => onKeyFire(e, onSubmit)}>
                            <TextField
                                type={"email"} label={"Email"}
                                placeholder={"Email"}
                                value={authDetails.email}
                                error={formError.email}
                                onChange={(value) => onHandleChange("email", value)}
                                onBlur={(e) => onBlurChange("email", e.target.value)}
                            />
                        </Box>

                        <Box as={"span"}/>
                        <Button size={"large"} variant={"primary"} onClick={onSubmit} loading={isLoading}>Reset Password</Button>

                        <button className={"back-log-btn"} onClick={() => onNavigate('login')}>
                            <InlineStack blockAlign={"center"} gap={"150"} as={"span"}>
                                <Icon source={ArrowLeftIcon} />
                                <Text as={"span"} fontWeight={"medium"}>Return to login</Text>
                            </InlineStack>
                        </button>
                    </BlockStack>
                </Box>
            </AuthFrame>
        </Fragment>
    );
};

export default ForgotPassword;