import React, {Fragment} from 'react';
import {BlockStack, Box,  Divider, Text} from "@shopify/polaris";
import CmLeftBar from "./SubComponents/CMLeftBar";
import MiddleBar from "../SubComponents/MiddleBar";
import {useWindowSize} from "../CommonUse/CommonUse";

const Customize = ({customizeProps, commonPropsMiddleBar}) => {
    const {width} = useWindowSize();

    return (
        <Fragment>
            <CmLeftBar {...{customizeProps}}/>

            <MiddleBar {...{commonPropsMiddleBar}}/>

            {
                (width > 1200) &&
                <div className={"right-bar"}>
                    <Box padding={"400"}>
                        <BlockStack gap={"200"}>
                            <Text variant={"headingLg"}>Customize your form's appearance to align with your brand.</Text>
                            <Text tone={"subdued"}>WebForm Form Builder offers a comprehensive range of customization features to help you create the perfect form for your needs.</Text>
                            <Text tone={"subdued"}>You can easily customize the form by adding your logo and selecting its position, as well as including a banner with an adjustable background position. Adjust the form size and direction to match your layout preferences.</Text>

                            <Text tone={"subdued"}>Additionally, you have control over the colors of various elements, such as the label, text, description, placeholder, error messages, and background. </Text>
                            <Text tone={"subdued"}>The submit button is also fully customizable, allowing you to modify its text, background color, and position. These features ensure that your form not only functions seamlessly but also aligns perfectly with your brand's aesthetic.</Text>
                        </BlockStack>
                    </Box>
                    <Divider/>
                </div>
            }
        </Fragment>
    );
};

export default Customize;