import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {Card, Page, Layout, DropZone, InlineStack, Thumbnail, Toast, BlockStack, Button, TextField, Modal, Text, Checkbox, Badge, Box, Collapsible,} from "@shopify/polaris";
import {
    apiService,
    baseUrl,
    getLSUserDetails,
    googleClientId,
    imagePath,
    isEmpty,
    removeDetails
} from "../../Utils/Constent";
import {CheckIcon, DeleteIcon, EditIcon, HideIcon, NoteIcon, ViewIcon, XIcon} from "@shopify/polaris-icons";
import {commonParagraph} from "../../Utils/Loader";
import {setUserDetails} from "../../Redux/Action/Action";
import {useDispatch, useSelector} from "react-redux";
import PlanBadge from "../Common/PlanBadge";
import {Icons} from "../../Utils/Icons";
import {loadAuth2, loadGapiInsideDOM} from "gapi-script";
import CopyCode from "../Common/CopyCode";
import {useNavigate} from "react-router-dom";

const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    profileImage: "",
    branding: 0,
    enabled2fa: "0",
    loginType: 0,
};

const Settings = () => {
    const navigate = useNavigate();

    const [profileDetails, setProfileDetails] = useState(initialState);
    const [oldProfileDetails, setOldProfileDetails] = useState(initialState);
    const [formError, setErrors] = useState(initialState);
    const [isLoading, setIsLoading] = useState(false);
    const [isImage, setIsImage] = useState(false);
    const [loading, setLoading] = useState('');
    const [active, setActive] = useState(false);
    const [message, setMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [isConfirmUpdate, setIsConfirmUpdate] = useState(false);
    const [authPassword, setAuthPassword] = useState('');
    const [authPasswordError, setAuthPasswordError] = useState('');
    const [modalType, setModalType] = useState('');
    const [isShowPaas, setIsShowPaas] = useState(false);
    const [isOpenCode, setIsOpenCode] = useState(false);
    const [gapi, setGapi] = useState(null);
    const [TFADetails, setTFADetails] = useState({qrCode: "", secrets2fa: ""});
    const [isUploadProfile, setIsUploadProfile] = useState(false);
    // const [tempProfile, setTempProfile] = useState('');

    const planDetails = useSelector((state) => state.planDetails);
    const dispatch = useDispatch();


    useEffect(() => {
        const loadGapi = async () => {
            const newGapi = await loadGapiInsideDOM();
            setGapi(newGapi);
        }
        loadGapi();
    }, []);

    useEffect(()=> {
        const getProfile = async () => {
            setIsLoading(true)
            const response = await apiService.getProfile();
            if (response.success === true) {
                setIsLoading(false)
                setProfileDetails(response.data)
                setOldProfileDetails(response.data)
                dispatch(setUserDetails(response?.data));
                let user = { ...response.data };
                localStorage.setItem("user-details", JSON.stringify(user));
                setTFADetails({qrCode: response.data.qrCode, secrets2fa: response.data.secrets2fa})
            } else {
                setIsLoading(false)
            }
        }
        getProfile()
    },[])

    const handleDropZoneDrop = (_dropFiles, acceptedFiles, _rejectedFiles) => {
        setIsImage(true)
        setProfileDetails({...profileDetails, profileImage: acceptedFiles && acceptedFiles.length ? acceptedFiles[0] : '' })
        // setTempProfile(acceptedFiles && acceptedFiles.length ? acceptedFiles[0] : '')
    }

    const formValidate = (name, value) => {
        switch (name) {
            case "firstName":
                if (!value.trim()) {
                    return "First name is required.";
                } else {
                    return "";
                }
            case "lastName":
                if (!value.trim()) {
                    return "Last name is required.";
                } else {
                    return "";
                }
            default: {
                return "";
            }
        }
    };

    const onHandleChange = useCallback((name, value) => {
        setProfileDetails((prev) => ({
            ...prev,
            [name]: value,
        }));
        setErrors((formError) => ({
            ...formError,
            [name]: formValidate(name, value),
        }));
    }, [profileDetails]);

    const handleUpdate = async () => {
        let errorData = {};
        Object.keys(profileDetails).map((x) => {
            let error = formValidate(x, profileDetails[x]);
            if (error && error.length > 0) {
                errorData[x] = error;
            }
        });
        if (Object.keys(errorData).length > 0) {
            setErrors(errorData);
            return;
        }
        setLoading('updateProfile');

        const payload = new FormData();
        payload.append('firstName', profileDetails.firstName);
        payload.append('lastName', profileDetails.lastName);
        payload.append('profileImage', isImage ? profileDetails?.profileImage : '');
        if(planDetails.planType == 1){
            payload.append('branding', profileDetails?.branding);
        }
        const response = await apiService.updateProfile(payload);
        if (response.success === true) {
            setLoading('');
            setIsImage(false)
            dispatch(setUserDetails(response?.data));
            const user = {...getLSUserDetails(), ...response.data,};
            localStorage.setItem("user-details", JSON.stringify(user));
            dispatch(setUserDetails(user));
            setMessage(response.message);
            setActive(true);
            setProfileDetails({...profileDetails});
            setOldProfileDetails({...profileDetails});
            setIsError(false)
            setIsConfirmUpdate(false)
        } else {
            setLoading('');
            setActive(true);
            setMessage(response.error.message);
            setIsError(true);
        }
    };

    const toggleActive = useCallback(() => {
        setActive(false)
        setIsError(false)
        setMessage('')
    }, []);

    const validImageTypes = ['image/*'];

    const fileUpload = !profileDetails.profileImage && <DropZone.FileUpload />;

    const uploadedFile = profileDetails.profileImage && (
            <Thumbnail
                size="small"
                alt={profileDetails.profileImage.name || 'profile'}
                source={
                    validImageTypes.includes(profileDetails.profileImage.type)
                        ? window.URL.createObjectURL(profileDetails.profileImage)
                        : NoteIcon
                }
            />
    );

    const onCancelChange = () => {
        setIsConfirmUpdate(false)
        setProfileDetails({...oldProfileDetails})
    }

    const onModalHit = async (loader, type, currentUser = '') => {
        if (authPassword.trim() === '' && profileDetails.loginType == 0) {
            setAuthPasswordError('Password is required.')
            return
        }
        setLoading(loader);
        let payload;
        if (profileDetails.loginType == 0) {
            payload = {password: authPassword}
        } else {
            let googleId = currentUser.getBasicProfile().getId();
            payload = {googleId: googleId}
        }

        let response;
        if (type === '2FAE') {
            response = await apiService.enable2FA(payload);
        } else if (type === '2FAD') {
            response = await apiService.disable2FA(payload);
        } else if (type === 'DelAcc') {
            response = await apiService.deleteAccount(payload);
        }

        if (response.success === true) {
            setLoading('');
            if (type === '2FAE') {
                setProfileDetails({...profileDetails, enabled2fa: '1'});
                setTFADetails(response?.data)
            } else if (type === '2FAD') {
                setProfileDetails({...profileDetails, enabled2fa: '0'});
                setTFADetails({qrCode: "", secrets2fa: ""})
            } else if (type === 'DelAcc') {
                removeDetails()
                if (gapi && gapi.auth2) {
                    const auth2 = gapi.auth2.getAuthInstance();
                    if (auth2) {
                        auth2.signOut().then(() => {
                            console.log('%cUser signed out', 'color: red; background-color: black; padding: 4px;');
                        });
                    }
                }
                navigate(`${baseUrl}/login`);
            }
            setMessage(response.message);
            setActive(true);
            setIsError(false)
            onCancelModal()
        } else {
            setLoading('');
            setActive(true);
            setMessage(response.error.message);
            setIsError(true);
        }
    }

    const onCancelModal = () => {
        setAuthPassword('')
        setModalType('')
        setAuthPasswordError('')
        setIsShowPaas(false)
    }

    const onChangeAuth = (value) => {
        setAuthPassword(value)
        setAuthPasswordError('')
    }

    const handleGoogle = async (type = '') => {
        try {
            const auth2 = await loadAuth2(gapi, googleClientId, "profile");
            const googleUser = await auth2.signIn();
            if (profileDetails.loginType == 1 && type === 'DelAcc') {
                await onModalHit('DelAcc', 'DelAcc', googleUser);
            } else {
                await onModalHit('auth', profileDetails.enabled2fa == 1 ? '2FAD' : '2FAE', googleUser);
            }
        } catch (error) {
            console.error('Google sign-in error:', error);
        }
    };

    // const onRemoveProfile = () => {
    //     setIsImage(false)
    //     setProfileDetails({...profileDetails, profileImage: '' })
    //     setTempProfile('')
    // }
    //
    // const onUpdateProfile = () => {
    //     setIsImage(true)
    //     setProfileDetails({...profileDetails, profileImage: tempProfile})
    //     setIsUploadProfile(false)
    // }
    //
    // const onClose = () => {
    //     setTempProfile('')
    //     setIsUploadProfile(false)
    // }

    const profile = profileDetails?.profileImage?.name ? URL.createObjectURL(profileDetails?.profileImage) :
        profileDetails?.profileImage?.includes('googleusercontent.com') ? profileDetails?.profileImage : `${imagePath}${profileDetails?.profileImage}`;

    return (
        <Fragment>
            {(active && message !== '') && <Toast content={message} onDismiss={toggleActive} error={isError} />}

            <Fragment>
                {
                    isConfirmUpdate &&
                    <Modal
                        open={isConfirmUpdate}
                        onClose={onCancelChange}
                        title="Profile details"
                        primaryAction={{
                            content: 'Yes',
                            onAction: handleUpdate,
                            loading: loading === 'updateProfile'
                        }}
                        secondaryActions={[
                            {
                                content: 'Cancel',
                                onAction: onCancelChange,
                            },
                        ]}
                    >
                        <Modal.Section>
                            <Text>
                                Are you sure you want to update?
                            </Text>
                        </Modal.Section>
                    </Modal>
                }
            </Fragment>

            <Fragment>
                {
                    modalType === 'authPass2FA' &&
                    <Modal
                        open={modalType === 'authPass2FA'}
                        onClose={onCancelModal}
                        title={<InlineStack gap={"200"} blockAlign={"center"} as={"span"}>{Icons.authKey}<Text as={"span"}>Two-factor Authentication</Text></InlineStack>}
                        primaryAction={{
                            content: 'Save',
                            onAction: () => onModalHit("auth", profileDetails.enabled2fa == 1 ? '2FAD' : '2FAE'),
                            loading: loading === 'auth'
                        }}
                        secondaryActions={[
                            {
                                content: 'Cancel',
                                onAction: onCancelModal,
                            },
                        ]}
                    >
                        <Modal.Section>
                            <TextField
                                label="Password"
                                placeholder={"Enter password"}
                                value={authPassword} type={isShowPaas ? "text" : "password"}
                                error={authPasswordError}
                                onChange={(value) => onChangeAuth(value)}
                                suffix={
                                    <Box paddingBlockStart={"100"}><Button icon={!isShowPaas ? ViewIcon : HideIcon} variant={"plain"} onClick={()=> setIsShowPaas(!isShowPaas)}/></Box>
                                }
                            />
                        </Modal.Section>
                    </Modal>
                }
            </Fragment>

            <Fragment>
                {
                    modalType === 'DeleteAccount' &&
                    <Modal
                        open={modalType === 'DeleteAccount'}
                        onClose={onCancelModal}
                        title={<InlineStack gap={"200"} as={"span"} blockAlign={"center"}>{Icons.danger}<Text as={"span"}>Delete Account</Text></InlineStack>}
                        primaryAction={{
                            content: 'Delete My Account',
                            onAction:  () => profileDetails.loginType == 1 ? handleGoogle('DelAcc') :  onModalHit('DelAcc', 'DelAcc'),
                            loading: loading === 'DelAcc',
                            destructive : true
                        }}
                        secondaryActions={[
                            {
                                content: 'Cancel',
                                onAction: onCancelModal,
                            },
                        ]}
                    >
                        <Modal.Section>
                            <BlockStack gap={"400"}>
                                <Text tone={"subdued"}>If you are certain you want to delete your account, please proceed with caution.</Text>
                                <Text tone={"subdued"}>Be aware that this action is irreversible. All your data, including forms, submissions, and workspaces, will be permanently deleted.</Text>

                                { profileDetails.loginType == 0 ?
                                    <TextField
                                    label="Password"
                                    placeholder={"Enter password"}
                                    value={authPassword} type={isShowPaas ? "text" : "password"}
                                    error={authPasswordError}
                                    onChange={(value) => onChangeAuth(value)}
                                    suffix={
                                        <Box paddingBlockStart={"100"}><Button icon={!isShowPaas ? ViewIcon : HideIcon} variant={"plain"} onClick={()=> setIsShowPaas(!isShowPaas)}/></Box>
                                    }
                                /> : null}
                            </BlockStack>
                        </Modal.Section>
                    </Modal>
                }
            </Fragment>

            <Fragment>
                {/*<Modal size={"small"}*/}
                {/*    open={isUploadProfile}*/}
                {/*    onClose={onClose}*/}
                {/*    title="Upload Photo"*/}
                {/*>*/}
                {/*    <Modal.Section>*/}
                {/*        <BlockStack align={"center"} inlineAlign={"center"} gap={"500"}>*/}
                {/*            <div className={"profile-thumbnail profile-thumbnail-inner "}>*/}
                {/*                <div className={"edit-profile-in"}>*/}
                {/*                    <DropZone accept={'image/*'} allowMultiple={false} onDrop={handleDropZoneDrop}>*/}
                {/*                        {uploadedFile}*/}
                {/*                        {fileUpload}*/}
                {/*                    </DropZone>*/}
                {/*                    {tempProfile?.name ? null : <label className={"label-btn"}>Choose File</label> }*/}
                {/*                </div>*/}
                {/*                {tempProfile?.name ? <img alt={"avtar"} src={URL.createObjectURL(tempProfile)}/> :*/}
                {/*                    (profileDetails?.profileImage === '' || profileDetails?.profileImage === null) ?*/}
                {/*                    <span>{profileDetails?.firstName[0]?.toUpperCase()}</span> :*/}
                {/*                    <img*/}
                {/*                        alt={profileDetails?.firstName[0]?.toUpperCase() + profileDetails?.lastName[0]?.toUpperCase()}*/}
                {/*                        src={profileDetails?.profileImage?.name ? URL.createObjectURL(profileDetails?.profileImage) : `${imagePath}${profileDetails?.profileImage}`}/>}*/}
                {/*            </div>*/}

                {/*            <InlineStack gap={"300"}>*/}
                {/*                <Button size={"large"} icon={DeleteIcon} tone={"critical"} onClick={onRemoveProfile} disabled={(profileDetails?.profileImage === '' || profileDetails?.profileImage === null)}>Remove</Button>*/}
                {/*                <Button size={"large"} icon={CheckIcon} tone={"success"} onClick={onUpdateProfile} disabled={tempProfile === ''}>Update</Button>*/}
                {/*            </InlineStack>*/}
                {/*        </BlockStack>*/}
                {/*    </Modal.Section>*/}
                {/*</Modal>*/}
            </Fragment>

            <Page title="Settings">
                <Layout>
                    <Layout.Section>
                        <Card>
                            <BlockStack as={"span"} gap={"500"}>
                                <Text variant={"headingMd"}>My Account</Text>
                                {isLoading ? commonParagraph(4) :
                                    <BlockStack as={"span"} gap={"400"}>
                                        <BlockStack>
                                            {/*<div className="Polaris-Labelled__LabelWrapper">*/}
                                            {/*    <div className="Polaris-Label">*/}
                                            {/*        <label className="Polaris-Label__Text">Profile</label>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            <div className={"profile-thumbnail"}>
                                                <div className={"edit-profile"} onClick={() => setIsUploadProfile(true)}>
                                                    <DropZone accept={'image/*'} allowMultiple={false} onDrop={handleDropZoneDrop}>
                                                        {uploadedFile}
                                                        {fileUpload}
                                                    </DropZone>
                                                    <Button variant={"plain"} icon={EditIcon} />
                                                </div>
                                                {isEmpty(profileDetails?.profileImage) ? <span>{profileDetails?.firstName[0]?.toUpperCase()}</span> :
                                                    <img src={profile} alt={profileDetails?.firstName[0]?.toUpperCase() + profileDetails?.lastName[0]?.toUpperCase()}/>
                                                }
                                            </div>
                                        </BlockStack>

                                        <TextField
                                            label="First name"
                                            placeholder={"Enter first name"}
                                            value={profileDetails.firstName}
                                            error={formError.firstName}
                                            onChange={(value) => onHandleChange("firstName", value)}
                                        />

                                        <TextField
                                            label="Last name"
                                            placeholder={"Enter last name"}
                                            value={profileDetails.lastName}
                                            error={formError.lastName}
                                            onChange={(value) => onHandleChange("lastName", value)}
                                        />

                                        <TextField
                                            label="Email" type={"email"}
                                            placeholder={"Enter email"}
                                            disabled
                                            value={profileDetails.email}
                                        />

                                        <TextField
                                            label="Password" type={"password"}
                                            placeholder={"Enter password"}
                                            disabled
                                            value={profileDetails.password || '*********'}
                                        />

                                        <Checkbox
                                            label={<Fragment>Remove WebForm Branding {planDetails.planType == 0 ? <PlanBadge/> :""}</Fragment>}
                                            checked={profileDetails?.branding == 1} disabled={planDetails.planType == 0}
                                            onChange={planDetails.planType == 0 ? null : (checked) => onHandleChange("branding", checked ? 1 : 0)}
                                        />

                                        <InlineStack align={"start"} gap={"100"}>
                                            <Button variant={"primary"} onClick={()=> setIsConfirmUpdate(true)} loading={loading === 'updateProfile'}>Update</Button>
                                        </InlineStack>
                                    </BlockStack>
                                }
                            </BlockStack>
                        </Card>
                    </Layout.Section>

                    <Layout.Section>
                        <Card>
                            {
                                isLoading ? commonParagraph(1) :
                                <BlockStack gap={"500"}>
                                    <BlockStack gap={"400"}>
                                        <BlockStack gap={"100"}>
                                            <InlineStack gap={"200"} blockAlign={"center"}>
                                                {Icons.authKey}
                                                <Text variant={"headingMd"}>Two-factor Authentication</Text> {profileDetails.enabled2fa == 0 ? <Badge>Disabled</Badge> : <Badge tone={"success"}>Enabled</Badge>}
                                            </InlineStack>
                                            <Box><Text tone={"subdued"}>Secure your account with two-factor authentication which adds an additional layer of security during login.</Text></Box>
                                        </BlockStack>


                                            {
                                                !TFADetails?.qrCode?.trim() || !TFADetails?.secrets2fa?.trim() ? null :
                                                <BlockStack gap={"0"}>
                                                    <Text variant={"headingMd"}>Scan the QR code with your authenticator app</Text>
                                                    <img src={TFADetails?.qrCode} width={150} height={150} loading={"lazy"} alt={'QR code'}/>
                                                    <Text as={"span"}>
                                                        <Button variant={"monochromePlain"} disclosure={!isOpenCode ? "down" : 'up'} onClick={() => setIsOpenCode(!isOpenCode)}>Show code for manual configuration </Button>

                                                        <Collapsible
                                                            open={isOpenCode} id="basic-collapsible-code"
                                                            transition={{duration: '400ms', timingFunction: 'ease-in-out'}}
                                                        >
                                                            <Box paddingBlockStart={"100"} maxWidth={"230px"}><CopyCode value={TFADetails?.secrets2fa}/></Box>
                                                        </Collapsible>
                                                    </Text>
                                                </BlockStack>
                                            }

                                    </BlockStack>
                                    <Text as={"span"}>
                                        <Button variant={"primary"} loading={profileDetails.loginType == 1 && (loading === '2FAD' || loading === '2FAE')}
                                                onClick={profileDetails.loginType == 0 ? () => setModalType('authPass2FA') : handleGoogle}>{profileDetails.enabled2fa == 0 ? 'Set Up' : 'Disable'}</Button></Text>
                                </BlockStack>
                            }
                        </Card>
                    </Layout.Section>

                    <Layout.Section>
                        <Card>
                            {
                                isLoading ? commonParagraph(1) :
                                <BlockStack gap={"500"}>
                                    <BlockStack gap={"100"}>
                                        <InlineStack gap={"200"} blockAlign={"center"}>
                                            {Icons.danger}
                                            <Text variant={"headingMd"}>Danger Zone</Text>
                                        </InlineStack>
                                        <Box maxWidth={""}><Text tone={"subdued"}>This action will permanently delete your entire account, including all forms, submissions, and workspaces.</Text></Box>
                                    </BlockStack>
                                    <Text as={"span"}><Button variant={"primary"} tone={"critical"} onClick={()=> setModalType('DeleteAccount')}>Delete Account</Button></Text>
                                </BlockStack>
                            }
                        </Card>
                    </Layout.Section>

                </Layout>
            </Page>
        </Fragment>
    );
};

export default Settings;