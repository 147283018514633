import { combineReducers } from 'redux';
import workSpaceDetailsReducer from "./workSpaceDetailsReducer";
import userDetailsReducer from "./userDetailsReducer";
import planDetailsReducer from "./planDetailsReducer";
import isLoadingPlanReducer from "./isLoadingPlanReducer";

const reducer = combineReducers({
    workSpaceDetails : workSpaceDetailsReducer,
    userDetails: userDetailsReducer,
    planDetails: planDetailsReducer,
    isLoadingPlan: isLoadingPlanReducer,
});

export default reducer;