import React, {Fragment} from 'react';
import {BlockStack, Icon} from "@shopify/polaris";
import {InfoIcon} from "@shopify/polaris-icons";
import {isContentEmpty} from "../CommonUse/CommonUse";

const MultiSelectOption = ({commonListProps}) => {
    const {blockArea, selectedPageIndex, selectedBlockIndex} = commonListProps;
    const { title, isRequired, description, options, errorMessage} = blockArea[selectedPageIndex]?.[selectedBlockIndex] || {};

    return (
        <Fragment>
            <div>

                <div className="Polaris-Labelled__LabelWrapper">
                    <div className="Polaris-Label">
                        <label className={`Polaris-Label__Text ${isRequired == 1 ? "Polaris-Label__RequiredIndicator" : ""}`}>
                            <span className="Polaris-Text--root Polaris-Text--bodyMd">{title}</span>
                        </label>
                    </div>
                </div>

                <BlockStack gap={"100"}>
                    {
                        (options || []).map(({value, label}, i) => (
                            <label key={i} className="Polaris-Choice Polaris-Checkbox__ChoiceLabel">
                    <span
                        className="Polaris-Choice__Control">
                        <span className="Polaris-Checkbox">
                            <input type="checkbox" readOnly checked
                                   className="Polaris-Checkbox__Input"
                                   aria-invalid="false"
                                   role="checkbox"
                                   aria-checked="true"
                                   value={value}/>
                                <span className="Polaris-Checkbox__Backdrop"/>
                                <span
                                    className="Polaris-Checkbox__Icon Polaris-Checkbox--animated">
                                    <svg viewBox="0 0 16 16"
                                         shapeRendering="geometricPrecision"
                                         textRendering="geometricPrecision">
                                        <path className="Polaris-Checkbox--checked"
                                              d="M1.5,5.5L3.44655,8.22517C3.72862,8.62007,4.30578,8.64717,4.62362,8.28044L10.5,1.5"
                                              transform="translate(2 2.980376)" opacity="0" fill="none" stroke="currentColor"
                                              strokeWidth="2"
                                              strokeLinecap="round" strokeLinejoin="round" pathLength="1"/>
                                    </svg>
                                </span>
                                </span>
                            </span>
                                <span className="Polaris-Choice__Label"><span>{label}</span></span>
                            </label>
                        ))
                    }
                </BlockStack>

                {errorMessage?.trim() !== '' && isRequired == 1 &&
                <div className="Polaris-Labelled__Error">
                    <div className="Polaris-InlineError">
                        <div className="Polaris-InlineError__Icon">
                            <Icon source={InfoIcon} />
                        </div>
                        {errorMessage}
                    </div>
                </div>
                }

                {isContentEmpty(description) ? null :
                    <div className="Polaris-Labelled__HelpText">
                        <span className="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--break Polaris-Text--subdued" dangerouslySetInnerHTML={{__html: description}}/>
                    </div>
                }

            </div>
        </Fragment>
    );
};

export default MultiSelectOption;