import React, {Fragment} from 'react';
import {defaultImage, imagePath} from "../../../../Utils/Constent";

const ImgTag = ({commonListProps}) => {
    const {blockArea, selectedPageIndex, selectedBlockIndex} = commonListProps;
    const {imgURL, imgLink, imgType} = blockArea[selectedPageIndex]?.[selectedBlockIndex] || {};

    const imgStyle = {
        maxWidth: "100%",
        width: "100%",
    }

    return (
        <Fragment>
            { imgType == 0 ?
                (imgURL && imgURL?.name ?
                    (<img style={imgStyle} alt={"img"} src={URL.createObjectURL(imgURL)}/>) :
                    imgURL?.length > 0 ? (<img style={imgStyle} alt={"img"} src={`${imagePath}${imgURL}`}/>):
                        <img style={imgStyle} alt={"img"} src={defaultImage}/>) : (imgType == 1 && imgLink ?
                    <img style={imgStyle} alt={"img"} src={`${imgLink}`}/> :
                    <img style={imgStyle} alt={"img"} src={defaultImage}/>)
            }
        </Fragment>
    );
};

export default ImgTag;