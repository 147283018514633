import {baseUrl} from "./Constent";
import Dashboard from "../Components/Dashboard/Dashboard";
import Workspace from "../Components/MyWorkspace/Workspace";
import Settings from "../Components/Settings/Settings";
import FormDetails from "../Components/MyWorkspace/FormDetails/FormDetails";
import PlanAndPrice from "../Components/PlanAndPrice/PlanAndPrice";
import Trash from "../Components/Trash/Trash";
import Members from "../Components/Members/Members";
import OnBoarding from "../Components/OnBoarding/OnBoarding";

export const routes = [
    {path: `${baseUrl}/onboarding`, component: <OnBoarding/>},
    {path: `${baseUrl}/dashboard`, component: <Dashboard/>},
    {path: `${baseUrl}/members`, component: <Members/>},
    {path: `${baseUrl}/workspace/:id`, component: <Workspace/>},
    {path: `${baseUrl}/workspace/:workspaceId/:type/:id`, component: <FormDetails/>},
    {path: `${baseUrl}/trash`, component: <Trash/>},
    {path: `${baseUrl}/plan`, component: <PlanAndPrice/>},
    {path: `${baseUrl}/settings`, component: <Settings/>},
]