import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {EmptySearchResult, Grid, Popover, SkeletonBodyText, ActionList, Button, ButtonGroup, Box, Divider, Card, Layout, Page, Tabs, Text, InlineStack, Badge, IndexTable,
    BlockStack, TextField, Select, Toast, Tooltip, Spinner, Pagination, Modal} from "@shopify/polaris";
import {AppsIcon, ChartVerticalFilledIcon, DeleteIcon, DuplicateIcon, EditIcon, ListBulletedIcon, MenuHorizontalIcon, ShareIcon} from "@shopify/polaris-icons";
import FormModal from "./Modals/FormModal";
import {apiService, baseUrl, capitalizeMessage} from "../../Utils/Constent";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import qs from "qs";
import {useDispatch, useSelector} from "react-redux";
import WorkSpaceModal from "./Modals/WorkSpaceModal";
import Confirmation from "../Common/Confirmation";
import {workSpaceDetailsAction} from "../../Redux/Action/Action";
import classicFromImg from "../../Img/ClassicForm.webp";
import cardFromImg from "../../Img/CardForm.webp";
import cardHTMLImg from "../../Img/HTML.webp";
import EllipsisText from "../Common/EllipsisText";
import NotFoundContent from "../Common/NotFoundContent";
import ShareModal from "./FormDetails/Share/ShareModal";

const initialState = {
    name: "",
    formType: "",
    formLayout: "1",
};

const Workspace = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const UrlParams = new URLSearchParams(location.search);
    const { id } = useParams();

    const workSpaceTab = UrlParams.get("workSpaceTab") || 0;
    const isListedGet = localStorage.getItem('isListed') || 'true';

    const [selected, setSelected] = useState(Number(workSpaceTab));
    const [isListed, setIsListed] = useState(isListedGet === 'true');

    const [popoverActive, setPopoverActive] = useState(null);

    const [wpPopoverActive, setWpPopoverActive] = useState(false);

    const [isFormModal, setIsFormModal] = useState(false);
    const [isFormLoading, setIsFormLoading] = useState(false);
    const [formDeleteRecord, setFormDeleteRecord] = useState({});

    const [formDetails, setFormDetails] = useState(initialState);
    const [formError, setErrors] = useState(initialState);

    const [isLoading, setIsLoading] = useState(false);
    const [forLoading, setForLoading] = useState('');
    const [forSelectedIndex, setForSelectedIndex] = useState(null);

    const [active, setActive] = useState(false);
    const [message, setMessage] = useState('');
    const [isError, setIsError] = useState(false);

    const [formList, setFormList] = useState([]);

    const [pageNo, setPageNo] = useState(1);
    const [isNextPage, setIsNextPage] = useState(false);
    const [totalPage, setTotalPage] = useState("");

    const [isWorkspaceModal, setIsWorkspaceModal] = useState(false);
    const [wpUpdateRecord, setWpUpdateRecord] = useState({});
    const [wpDeleteModalRecord, setWpDeleteModalRecord] = useState({});
    const [isWpDeleteLoading, setIsWpDeleteLoading] = useState(false);

    const [isShareModal, setIsShareModal] = useState(false);
    const [formData, setFormData] = useState({});
    const [isModalInvite, setIsModalInvite] = useState(false);

    const dispatch = useDispatch();

    const workSpacesAll = useSelector((state) => state.workSpaceDetails);
    const planDetails = useSelector((state) => state.planDetails);

    const handleListChange = (val) => {
        localStorage.setItem('isListed', val ? 'true' : 'false');
        setIsListed(val)
    }

    useEffect(() => {
        navigate({
            pathname: `${baseUrl}/workspace/${id}`,
            search: qs.stringify({ workSpaceTab: selected}),
        });
    }, [selected])

    const togglePopoverActive = useCallback((inx) => {
        setPopoverActive((prev) => (prev === inx ? null : inx));
    }, [popoverActive]);

    const handleTabChange = (selectedTabIndex) => {
        setPageNo(1)
        setSelected(selectedTabIndex)
    };

    const tabs = [
        {
            id: 'all-customers-1',
            content: 'All',
            accessibilityLabel: 'All customers',
            panelID: 'all-customers-content-1',
        },
        {
            id: 'publish-marketing-1',
            content: 'Publish',
            panelID: 'publish-marketing-content-1',
        },
        {
            id: 'Unpublish-customers-1',
            content: 'Unpublish',
            panelID: 'unpublish-customers-content-1',
        },
    ];

    useEffect(() => {
        setPageNo(1)
    }, [id])

    useEffect(() => {
        getForm()
    }, [pageNo, selected, id])

    const getForm = async () => {
        setIsLoading(true)
        const payload = {
            workSpaceId: id?.toString(),
            publishStatus: selected === 0 ? "all" : selected === 1 ? 1 : 0,
            pageNo: pageNo
        }
        const response = await apiService.getAllForms(payload);
        if (response.success === true) {
            setIsLoading(false)
            setFormList(response.data.forms)
            setTotalPage(response?.data?.totalPages)
            setIsNextPage(response.data.nextPage)
        } else {
            setIsLoading(false)
        }
    }

    const resourceName = {
        singular: 'form',
        plural: 'forms',
    };

    const onEdit = async (record) => {
        navigate({
            pathname: `${baseUrl}/workspace/${record?.workSpaceId}/form/${record?.id}`,
            search: qs.stringify({workSpaceTab: selected}),
        });
    }

    const onSubmission = (record) => {
        navigate({
            pathname: `${baseUrl}/workspace/${record?.workSpaceId}/submission/${record?.id}`,
            search: qs.stringify({workSpaceTab: selected}),
        });
    }

    const updateWorkSpaceAll = (sign) => {
        const updatedWorkSpaces = (workSpacesAll || []).map(x => {
            if (x.id == id) {
                return {
                    ...x,
                    formCount: x?.formCount + (sign === '+' ? 1 : sign === '-' ? -1 : 0),
                };
            }
            return x;
        });
        dispatch(workSpaceDetailsAction(updatedWorkSpaces));
    };

    const onDuplicate = async (loader, loadIndex, id, wpId) => {
        const payload = {
            id: id?.toString(),
            workSpaceId: wpId?.toString(),
        }
        setForSelectedIndex(loadIndex)
        setForLoading(loader)
        const response = await apiService.duplicateCreateForm(payload);
        if (response.success === true) {
            setForLoading('')
            setForSelectedIndex(null)
            updateWorkSpaceAll('+')
            await getForm()
            setMessage(capitalizeMessage(response?.message));
            setActive(true);
            setIsError(false)
        } else {
            setForLoading('')
            setForSelectedIndex(null)
            setActive(true);
            setMessage(response?.error?.message);
            setIsError(true);
        }
    }

    const onDelete = async (record) => {
        setFormDeleteRecord(record)
    }

    const onFormDelete = async () => {
        setForLoading('delete')
        const response = await apiService.deleteForm(formDeleteRecord?.id);
        if (response.success === true) {
            setForLoading('')
            onCloseFormDeleteModal()
            updateWorkSpaceAll('-')
            let clone = [...formList]
            const updatedArray = clone.filter((x) => x.id != formDeleteRecord?.id)
            setFormList(updatedArray);
            if (updatedArray?.length <= 0 && isNextPage) {
                setPageNo(1);
                setIsNextPage(false);
            } else if(updatedArray?.length <= 0) {
                setPageNo(1);
                setIsNextPage(false);
            } else if(updatedArray?.length < 10){
                setIsNextPage(false);
            }
            setMessage(capitalizeMessage(response?.message));
            setActive(true);
            setIsError(false)
        } else {
            setForLoading('')
            setFormDeleteRecord({})
            setActive(true);
            setMessage(response?.error?.message);
            setIsError(true);
        }
    }

    const onCloseFormDeleteModal = () => {
        setFormDeleteRecord({})
    }

    const onShareLink = (record) => {
        setFormData({...record})
        setIsShareModal(true)
    }

    const rowMarkup = (formList || []).map((x, i) => (
            <IndexTable.Row key={x.id}>
                <IndexTable.Cell>
                    <Button onClick={() => onEdit(x)} variant={"monochromePlain"}>
                        <EllipsisText text={x.name} />
                    </Button>
                </IndexTable.Cell>
                <IndexTable.Cell>
                    {x.publishStatus == 0 ? <Badge tone={"critical"}>Unpublish</Badge> : <Badge tone={"info"}>Publish</Badge>}
                </IndexTable.Cell>
                <IndexTable.Cell>{x.formLayout == 2 ? "Card" : x.formLayout == 3 ? "HTML" : "Classic"} Form</IndexTable.Cell>
                <IndexTable.Cell>{x.submissions ? x.submissions : "0"}</IndexTable.Cell>
                <IndexTable.Cell> {x.completionRate ? x?.completionRate: "0"}%</IndexTable.Cell>
                <IndexTable.Cell className="w-200">
                    <ButtonGroup noWrap>
                        <Tooltip dismissOnMouseOut content={"Edit"}><Button icon={EditIcon} onClick={() => onEdit(x)}/></Tooltip>
                        {/*<Tooltip dismissOnMouseOut content={"Preview"}>*/}
                        {/*    <Button icon={ViewIcon} onClick={() => onView(x?.publishId)}/>*/}
                        {/*</Tooltip>*/}
                        <Tooltip dismissOnMouseOut content={"Share Link"}>
                            <Button icon={ShareIcon} onClick={() => onShareLink(x)} disabled={x.publishStatus == 0}/>
                        </Tooltip>
                        <Tooltip dismissOnMouseOut content={"Submission"}>
                        <Button icon={ChartVerticalFilledIcon} onClick={() => onSubmission(x)}/>
                        </Tooltip>
                        <Tooltip dismissOnMouseOut content={"Duplicate"}>
                            <Button icon={DuplicateIcon} loading={forSelectedIndex === i && forLoading === 'duplicate'}
                                    onClick={() => onDuplicate('duplicate', i, x.id, x.workSpaceId)}/>
                        </Tooltip>
                        <Tooltip dismissOnMouseOut content={"Delete"}>
                            <Button icon={DeleteIcon} loading={forSelectedIndex === i && forLoading === 'delete'}
                                    onClick={() => onDelete(x)}/>
                        </Tooltip>
                    </ButtonGroup>
                </IndexTable.Cell>
            </IndexTable.Row>
        ),);

    const formValidate = (name, value) => {
        switch (name) {
            case "name":
                if (!value.trim()) {
                    return "Name is required.";
                } else {
                    return "";
                }
            case "formType":
                if (!value.trim()) {
                    return "Please select at least one.";
                } else {
                    return "";
                }
            default: {
                return "";
            }
        }
    };

    const onHandleChange = useCallback((name, value) => {
        setFormDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));
        setErrors((formError) => ({
            ...formError,
            [name]: formValidate(name, value),
        }));
    }, [formDetails]);

    const onForm = async () => {
        let errorData = {};
        Object.keys(formDetails).map((x) => {
            let error = formValidate(x, formDetails[x]);
            if (error && error.length > 0) {
                errorData[x] = error;
            }
        });
        if (Object.keys(errorData).length > 0) {
            setErrors(errorData);
            return
        }

        setIsFormLoading(true);
        const defaultStyle = {
            submitButtonText: "Submit",
            buttonIcon: "0",
            nextButtonText: "Next",
            previousButtonText: "Previous",
            globalTextColor: "#000000",
            globalLabelColor: "#303030",
            globalBg: "#f1f1f1",
            globalPlaceColor: '#c2c2c2',
            globalDescColor: "#616161",
            globalErrorColor: "#8e1f0b",
            buttonTextColor: "#ffffff",
            buttonBg: "#000000",
            buttonPosition: "start",
            logoPosition: "left",
            bannerPosition: "top",
            bannerSize: "container",
            formSize: "medium",
            formDirection: "ltr",
        }
        const payload = {
            name: formDetails.name,
            formType: formDetails.formType?.toString(),
            workSpaceId: id?.toString(),
            formLayout: formDetails.formLayout?.toString(),
            publishStatus: '0',
            banner: '',
            logo: '',
            style: JSON.stringify(defaultStyle),
        }
        const response = await apiService.createForm(payload);
        if (response.success === true) {
            setIsFormLoading(false);
            setMessage(capitalizeMessage(response?.message));
            setActive(true);
            await onEdit({workSpaceId: id, id: response?.data?.id})
            onCloseModal();
            setIsError(false)
            await getForm()
            updateWorkSpaceAll('+')
        } else {
            setIsFormLoading(false);
            setActive(true);
            setMessage(response?.error?.message);
            setIsError(true);
        }
    }

    const onCloseModal = () => {
        setIsFormModal(false)
        setErrors(initialState)
        setFormDetails(initialState)
    }

    const toggleActive = useCallback(() => {
        setActive(false)
        setIsError(false)
        setMessage('')
    }, []);

    const options = [
        {label: 'Application form', value: 'Application form'},
        {label: 'Checklist', value: 'Checklist'},
        {label: 'Contact form', value: 'Contact form'},
        {label: 'Feedback form', value: 'Feedback form'},
        {label: 'Lead gen form', value: 'Lead gen form'},
        {label: 'Order form', value: 'Order form'},
        {label: 'Payment form', value: 'Payment form'},
        {label: 'Poll', value: 'Poll'},
        {label: 'Quiz', value: 'Quiz'},
        {label: 'Registration form', value: 'Registration form'},
        {label: 'Request form', value: 'Request form'},
        {label: 'Research survey', value: 'Research survey'},
        {label: 'Other', value: 'Other'},
    ]

    const onPrevious = () => {
        const pageNoNew = Number(pageNo) - 1 === 0 ? 1 : Number(pageNo) - 1
        setPageNo(pageNoNew)
    }

    const onNext = () => {
        setPageNo(Number(pageNo) + 1)
    }

    const getWorkspaceTitle = () => {
        const findTitle = workSpacesAll.find((x) => x.id == id)
        return  findTitle?.name || '';
    }

    const onDeleteWorkSpace = async () => {
        const payload = {
            isDeleted: '1'
        };
        setIsWpDeleteLoading(true)
        const response = await apiService.workspaceFormUpdateDelete(wpDeleteModalRecord?.id, payload);
        if (response.success === true) {
            setIsWpDeleteLoading(false)
            setMessage(response.message);
            let clone = [...workSpacesAll]
            const updatedArray = clone.filter((x) => x.id != wpDeleteModalRecord?.id)
            dispatch(workSpaceDetailsAction(updatedArray));
            setActive(true);
            setIsError(false)
            if(updatedArray.length > 0){
                navigate({
                    pathname: `${baseUrl}/workspace/${updatedArray[0]?.id}`,
                    search: qs.stringify({workSpaceTab: selected}),
                });
            } else {
                navigate({pathname: `${baseUrl}/dashboard`});
            }
            onCloseDeleteModal()
        } else {
            setIsWpDeleteLoading(false)
            setActive(true);
            setMessage(response.error.message);
            setIsError(true);
        }
    }

    const onDeleteWorkSpaceModal = () => {
        const record = (workSpacesAll || []).find((x) => x.id == id)
        setWpDeleteModalRecord(record)
    }

    const onCloseDeleteModal = () => {
        setWpDeleteModalRecord({})
    }

    const onUpdateWorkSpace = async () => {
        const record = (workSpacesAll || []).find((x) => x.id == id)
        setWpUpdateRecord(record)
        setIsWorkspaceModal(true)
    }

    const commonPropsShare = {
        isShareModal,
        setIsShareModal,
        formData,
        isLoading
    }

    const onOpenInviteModal = () => {
        setIsModalInvite(true)
    }

    const onCloseInviteModal = () => {
        setIsModalInvite(false)
    }

    const formOptions = [
        { value: '1', label: "Classic", imgSrc: classicFromImg },
        { value: '2', label: "Card", imgSrc: cardFromImg },
        { value: '3', label: "HTML", imgSrc: cardHTMLImg },
    ];

    return (
        <Fragment>
            {(active && message !== '') && <Toast content={message} onDismiss={toggleActive} error={isError}/>}

            <Fragment>
                {isWorkspaceModal && <WorkSpaceModal {...{isWorkspaceModal, setIsWorkspaceModal, wpUpdateRecord, setWpUpdateRecord}}/>}

                {
                    wpDeleteModalRecord?.id ?
                        <Confirmation
                            title={"Remove Workspace"} open={wpDeleteModalRecord?.id}
                            onConfirm={onDeleteWorkSpace}
                            isLoading={isWpDeleteLoading}
                            onClose={onCloseDeleteModal}
                            message={'Are you sure you want to remove workspace?'}
                        /> : null
                }

                {
                    formDeleteRecord?.id ?
                        <Confirmation
                            title={"Remove Form"} open={formDeleteRecord?.id}
                            onConfirm={onFormDelete}
                            isLoading={forLoading === 'delete'}
                            onClose={onCloseFormDeleteModal}
                            message={'Are you sure you want to remove form?'}
                        /> : null
                }
                <ShareModal {...{commonPropsShare}}/>

                <FormModal
                    open={isFormModal}
                    title={'Bring your new form to life'}
                    onClose={onCloseModal}
                    onContinue={onForm}
                    loading={isFormLoading}>
                    <BlockStack gap={"400"}>
                        <TextField
                            label="Give your form name"
                            placeholder={"Enter Form Name"}
                            value={formDetails.name}
                            error={formError.name}
                            onChange={(value) => onHandleChange("name", value)}
                        />
                        <Select
                            label="What are you creating?"
                            options={options}
                            placeholder={"Select Form Type"}
                            value={formDetails.formType}
                            error={formError.formType}
                            onChange={(value) => onHandleChange("formType", value)}
                        />
                        <Box as={"span"}>
                            <div className="Polaris-Labelled__LabelWrapper">
                                <div className="Polaris-Label"><label className="Polaris-Label__Text">Form layout</label></div>
                            </div>
                            <InlineStack wrap={false} gap={"300"}>
                                {formOptions.map((x,i) => (
                                    <div className={"form-checkbox"} key={i}>
                                        <input
                                            type="radio"
                                            id={`formType${i}`}
                                            checked={formDetails.formLayout === x.value}
                                            onChange={() => onHandleChange("formLayout", x.value)}
                                        />
                                        <label htmlFor={`formType${i}`}>
                                            <div className={"media-thumbnail modal-thumbnail cursor-pointer"}>
                                                <img src={x.imgSrc} className={"w-100"} />
                                            </div>
                                            <p className={"type-text"}>{x.label}</p>
                                        </label>
                                    </div>
                                ))}
                            </InlineStack>
                        </Box>
                    </BlockStack>
                </FormModal>

                <Modal
                    open={isModalInvite}
                    onClose={onCloseInviteModal}
                    title="Reach more shoppers with Instagram product tags"
                    primaryAction={{
                        content: 'Add Instagram',
                        onAction: () => {
                        },
                    }}
                    secondaryActions={[
                        {
                            content: 'Learn more',
                            onAction: () => {
                            },
                        },
                    ]}
                >
                <Modal.Section>
                        Use Instagram posts to share your products with millions of
                        people. Let shoppers buy from your store without leaving
                    </Modal.Section>
                </Modal>
            </Fragment>

            <Page title={ workSpacesAll.length > 0 ?
                <InlineStack gap={"200"} blockAlign={"center"} wrap={false}>
                    <EllipsisText text={getWorkspaceTitle() || ''} />
                    <span className={"dot-title-btn"}>
                         <Popover
                             active={wpPopoverActive}
                             activator={<Tooltip content={"Actions"} dismissOnMouseOut>
                                 <Button onClick={()=> setWpPopoverActive((prev) => !prev)} variant={"plain"} icon={MenuHorizontalIcon}/>
                             </Tooltip>}
                             autofocusTarget="first-node"
                             onClose={()=> setWpPopoverActive((prev) => !prev)}
                         >
                            <ActionList
                                actionRole="menuitem"
                                items={[
                                    {
                                        content: 'Update',
                                        onAction: onUpdateWorkSpace,
                                    },
                                    {
                                        content: 'Delete', destructive: true,
                                        disabled: planDetails.planType == 0,
                                        onAction: planDetails.planType == 0 ? null : onDeleteWorkSpaceModal,
                                    },
                                ]}
                            />
                          </Popover>
                    </span>
                </InlineStack> : <SkeletonBodyText lines={1}/>
            }
                  primaryAction={{
                          content: 'Create New Form',
                          onAction: () => setIsFormModal(true),
                          disabled: !id,
                      }}
                  // secondaryActions={
                  //     <Fragment>
                  //         <InlineStack wrap={false}>
                  //             {
                  //                 Array.from(Array(4)).map((_, r) => {
                  //                     return (
                  //                         <Fragment key={r}>
                  //                             <Tooltip content={"View"} dismissOnMouseOut>
                  //                                 <div className={"avatar-member"} onClick={onOpenInviteModal}>
                  //                                     A
                  //                                     {/*<img src={"https://storage.tally.so/e80faef1-527e-4e97-9f65-4baa71c0ccf6/mailchimp.png"} alt={"avatar"}/>*/}
                  //                                 </div>
                  //                             </Tooltip>
                  //                         </Fragment>
                  //                     )
                  //                 })
                  //             }
                  //         </InlineStack>
                  //     </Fragment>
                  // }
            >
                <Layout>
                    <Layout.Section>
                        <Card padding={"0"}>
                            <Box paddingInlineEnd={"300"}>
                                <InlineStack gap={"100"} align={"space-between"} wrap={false}>
                                    <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}/>
                                    <ButtonGroup noWrap>
                                        <Button icon={AppsIcon} pressed={!isListed} onClick={()=> handleListChange(false)}/>
                                        <Button icon={ListBulletedIcon} pressed={isListed} onClick={()=> handleListChange(true)}/>
                                    </ButtonGroup>
                                </InlineStack>
                            </Box>
                            <Divider/>
                            <Box padding={isListed ? "0" : "400"} paddingBlockEnd={isListed ? "" : formList?.length === 0 ? "400" : "400"}>
                                {
                                    isListed ?
                                        <Fragment>
                                            <IndexTable
                                                emptyState={
                                                    <NotFoundContent isLoading={isLoading} action={{content: 'Create New Form', onAction : () => setIsFormModal(true)}}>
                                                        <Text variant={"headingMd"}>No form found</Text>
                                                    </NotFoundContent>
                                                }
                                                resourceName={resourceName}
                                                itemCount={isLoading? 0 : formList.length}
                                                loading={isLoading}
                                                selectable={false}
                                                headings={[
                                                    {title: 'Title'},
                                                    {title: 'Status'},
                                                    {title: 'Form Layout'},
                                                    {title: 'Submissions'},
                                                    {title: 'Completion Rate'},
                                                    {title: 'Action'},
                                                ]}
                                            >
                                                {rowMarkup}
                                            </IndexTable>
                                        </Fragment> :
                                        <Fragment>
                                            {isLoading ?
                                                    <BlockStack gap={"800"} align={"center"} inlineAlign={"center"}><span/><Spinner size="large"/><span/></BlockStack> :
                                                    <Fragment>
                                                    {
                                                        formList.length > 0 ?
                                                            <Grid gap={{xs: "10px", sm: "10px", md: "10px", lg: "10px", xl: "10px"}}>
                                                            {
                                                                (formList || []).map((x, i) => {
                                                                    return (
                                                                        <Grid.Cell columnSpan={{xs: 6, sm: 3, md: 3, lg: 3, xl: 3}} key={i}>
                                                                            <Card padding={"0"}>
                                                                                <div className={"relative"}>
                                                                                    <div className={"checkBadge"}>
                                                                                        <InlineStack align={"space-between"} blockAlign={"end"}>
                                                                                            <span>{x.publishStatus == 0 ? <Badge tone={"critical"}>Unpublish</Badge> : <Badge tone={"info"}>Publish</Badge>}</span>
                                                                                        </InlineStack>
                                                                                    </div>
                                                                                    <Box padding={"150"} paddingBlockEnd={"0"}>
                                                                                        <div className={"media-thumbnail cursor-pointer"} onClick={() => onEdit(x)}>
                                                                                            <img style={{maxWidth: x.formLayout == 3 ? "32%" : ""}} src={x.formLayout == 1 ? classicFromImg : x.formLayout == 2 ? cardFromImg : cardHTMLImg} className={"w-100"}/>
                                                                                        </div>
                                                                                    </Box>
                                                                                </div>

                                                                                <Box paddingBlockStart={"200"} padding={"400"}>
                                                                                    <InlineStack gap={"100"} align={"space-between"} blockAlign={"end"} wrap={false}>
                                                                                        <Button onClick={() => onEdit(x)} variant={"monochromePlain"}>
                                                                                            <Text variant={"headingMd"}><EllipsisText text={x.name || ''} maxWidth={160} /></Text>
                                                                                        </Button>
                                                                                        <span style={{transform: 'translateY(6px)'}}>
                                                                                            <Popover active={popoverActive === i} onClose={() => togglePopoverActive(i)}
                                                                                                     activator={<Button variant={"plain"} onClick={() => togglePopoverActive(i)} icon={MenuHorizontalIcon}/>}
                                                                                            >
                                                                                                <div
                                                                                                    style={{minWidth: '200px'}}>
                                                                                                    <ActionList
                                                                                                        items={[
                                                                                                            {content: 'Edit', onAction: () => onEdit(x)},
                                                                                                            {content: 'Share Link', onAction: () => onShareLink(x) , disabled: x.publishStatus == 0},
                                                                                                        ]}
                                                                                                    />
                                                                                                    <Divider/>
                                                                                                    <ActionList
                                                                                                        items={[
                                                                                                            {content: 'Submission', onAction: () => onSubmission(x)},
                                                                                                            {content: 'Duplicate ', onAction: () => onDuplicate('duplicate', i, x.id, x.workSpaceId),
                                                                                                                loading: forSelectedIndex === i && forLoading === 'duplicate'
                                                                                                            },
                                                                                                        ]}
                                                                                                    />
                                                                                                    <Divider/>
                                                                                                    <ActionList
                                                                                                        items={[
                                                                                                            {
                                                                                                                content: 'Delete', destructive: true,
                                                                                                                onAction: () => onDelete(x),
                                                                                                                loading: forSelectedIndex === i && forLoading === 'delete'
                                                                                                            },
                                                                                                        ]}
                                                                                                    />
                                                                                                </div>
                                                                                            </Popover>
                                                                                        </span>
                                                                                    </InlineStack>
                                                                                </Box>
                                                                            </Card>
                                                                        </Grid.Cell>
                                                                    )
                                                                })
                                                            }
                                                        </Grid> : <EmptySearchResult title={'No form found'} description={<Button variant={"primary"} onClick={() => setIsFormModal(true)}>Create New Form</Button>} withIllustration/>
                                                    }
                                                    </Fragment>
                                            }
                                        </Fragment>
                                }
                                {
                                    isLoading ? null : formList?.length > 0 ? <Fragment>
                                        <Box paddingBlockStart={!isListed ? "300" : '0'}><Divider/></Box>
                                        <Box paddingInlineEnd={isListed ? "500" : '0'} paddingBlockEnd={isListed ? "300" : '0'} paddingBlockStart={"300"}>
                                            <InlineStack align={"end"}>
                                                <Pagination
                                                    hasPrevious={pageNo !== 1}
                                                    onPrevious={onPrevious}
                                                    hasNext={pageNo < totalPage && isNextPage}
                                                    onNext={onNext}
                                                />
                                            </InlineStack>
                                        </Box>
                                    </Fragment> : ""
                                }
                            </Box>
                        </Card>
                    </Layout.Section>
                </Layout>
            </Page>
        </Fragment>
    );
};

export default Workspace;