import React, {Fragment} from 'react';
import {InlineStack, Icon} from '@shopify/polaris';
import {InfoIcon} from "@shopify/polaris-icons";
import {isContentEmpty} from "../CommonUse/CommonUse";

const MultiSelect = ({commonListProps}) => {
    const { blockArea, selectedPageIndex, selectedBlockIndex} = commonListProps;
    const { title, isRequired,placeholder, description,options, errorMessage } = blockArea[selectedPageIndex]?.[selectedBlockIndex] || {};

    return (
        <Fragment>
            <div>
                <div className="Polaris-Labelled__LabelWrapper">
                    <div className="Polaris-Label">
                        <label className={`Polaris-Label__Text ${isRequired == 1 ? "Polaris-Label__RequiredIndicator" : ""}`}>
                            <span className="Polaris-Text--root Polaris-Text--bodyMd">{title}</span>
                        </label>
                    </div>
                </div>

                <Fragment>
                    <div className="Polaris-Connected">
                        <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
                            <div className="Polaris-TextField">
                                <div className="Polaris-TextField__VerticalContent">
                                    <InlineStack gap={'100'}>
                                        {
                                            (options || []).map((x,i) => {
                                                return (
                                                    <span key={i} className="Polaris-Tag Polaris-Tag--removable">
                                        <span title="First Choice" className="Polaris-Tag__TagText">{x.label}</span><button
                                                        type="button"  className="Polaris-Tag__Button">
                                        <span className="Polaris-Icon">
                                            <svg viewBox="0 0 20 20"
                                                 className="Polaris-Icon__Svg"
                                                 focusable="false"
                                                 aria-hidden="true"><path
                                                d="M12.72 13.78a.75.75 0 1 0 1.06-1.06l-2.72-2.72 2.72-2.72a.75.75 0 0 0-1.06-1.06l-2.72 2.72-2.72-2.72a.75.75 0 0 0-1.06 1.06l2.72 2.72-2.72 2.72a.75.75 0 1 0 1.06 1.06l2.72-2.72 2.72 2.72Z"/></svg></span></button></span>
                                                )
                                            })
                                        }
                                    </InlineStack>
                                    <input  placeholder={placeholder} autoComplete="off" readOnly
                                           className="Polaris-TextField__Input" type="text" value=""/>
                                </div>
                                <div className="Polaris-TextField__Backdrop"/>
                            </div>
                        </div>
                    </div>
                    {errorMessage?.trim() !== '' && isRequired == 1 &&
                    <div className="Polaris-Labelled__Error">
                        <div className="Polaris-InlineError">
                            <div className="Polaris-InlineError__Icon">
                                <Icon source={InfoIcon} />
                            </div>
                            {errorMessage}
                        </div>
                    </div>
                    }

                    {isContentEmpty(description) ? null :
                        <div className="Polaris-Labelled__HelpText">
                            <span className="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--break Polaris-Text--subdued" dangerouslySetInnerHTML={{__html: description}}/>
                        </div>
                    }
                </Fragment>

            </div>
        </Fragment>
    );
};

export default MultiSelect;