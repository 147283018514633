import React, {Fragment, useCallback, useState} from 'react';
import {Badge, Button, EmptySearchResult, IndexTable, Text,InlineStack, Tooltip, Pagination, BlockStack} from '@shopify/polaris';
import {ChevronDownIcon, ChevronRightIcon, ResetIcon} from "@shopify/polaris-icons";
import moment from "moment";
import {apiService, capitalizeMessage} from "../../../../../Utils/Constent";

const EventLogTable = ({loading, history, setLoading, setHistory, setMessage, setIsError, setActive, pageNo, totalPage, isNextPage, setPageNo, modalTitle}) => {
    const [selectedIndex, setSelectedIndex] = useState(null);

    const toggleActive = useCallback(
        (id) => setSelectedIndex(prevId => prevId === id ? null : id),
        [selectedIndex],
    );

    const onPrevious = () => {
        const pageNoNew = Number(pageNo) - 1 === 0 ? 1 : Number(pageNo) - 1
        setPageNo(pageNoNew)
        setSelectedIndex(null)
    }

    const onNext = () => {
        setPageNo(Number(pageNo) + 1)
        setSelectedIndex(null)
    }

    const webhooksRetry = async (event, reTryId) => {
        event?.stopPropagation()
        setLoading(reTryId)
        const response = await apiService.webhooksRetry(reTryId);
        if (response.success === true) {
            setLoading('')
            setHistory((pre) => (
                (pre || []).map(x => {
                    if (x.id == reTryId) {return {...x, ...response?.data};}
                    return x;
                })
            ))
            setMessage(capitalizeMessage(response?.message));
            setActive(true);
            setIsError(false)
        } else {
            setLoading('')
            setActive(true);
            setMessage(response?.error?.message);
            setIsError(true);
        }
    }

    const rowMarkup = (history || []).map((x, i) => {
        return (
           <Fragment key={`${i}Parent`}>
               <IndexTable.Row rowType="data" id={`Parent-${i}`}>
                    <IndexTable.Cell className={"resend-main"} >
                       <span className={"cursor-pointer"} onClick={() => toggleActive(i)}>
                           <InlineStack gap={"025"} align={"space-between"} blockAlign={"center"}>
                               <InlineStack gap={"150"}>
                                   <Button variant={"monochromePlain"} icon={selectedIndex === i ? ChevronDownIcon : ChevronRightIcon} size={"large"} onClick={(e) => {e.stopPropagation();toggleActive(i)}}/>
                                   {x?.httpStatus == 200 ? <Badge tone={"success"}>Delivered</Badge> : <Badge tone={"critical"}>Failed</Badge>}
                               </InlineStack>

                               {x?.httpStatus == 200 ? null : <div className={"resend"}>
                                   <Tooltip content={"Resend"} dismissOnMouseOut>
                                       <Button onClick={(e) => webhooksRetry(e, x?.id)} loading={loading === x.id} size={"micro"} variant={"monochromePlain"} icon={ResetIcon}/>
                                   </Tooltip>
                               </div>}
                           </InlineStack>
                       </span>
                    </IndexTable.Cell>
                    <IndexTable.Cell><Button fullWidth textAlign={"left"} variant={"monochromePlain"} onClick={() => toggleActive(i)}>{x?.response?.submissionId}</Button></IndexTable.Cell>
                    <IndexTable.Cell><Button fullWidth textAlign={"left"} variant={"monochromePlain"} onClick={() => toggleActive(i)}>{moment(x?.createdAt).format("MMM DD, YYYY hh:mm a")}</Button></IndexTable.Cell>
                    <IndexTable.Cell>
                        <Button fullWidth textAlign={"left"} variant={"monochromePlain"} onClick={() => toggleActive(i)}>{x.reTray == 0 ? "-" : x.reTray}</Button>
                    </IndexTable.Cell>
                </IndexTable.Row>

               { selectedIndex === i &&
                   <Fragment key={`${i}child`}>
                       <IndexTable.Row rowType="child" id={`First_Child_${i}`}>
                           <IndexTable.Cell scope="row" className={"border-0"}>
                                   <Text tone={"subdued"}>HTTP status code</Text>
                           </IndexTable.Cell>
                           <IndexTable.Cell colSpan={3} className={"border-0"}>
                                   <Text>{x?.httpStatus}</Text>
                           </IndexTable.Cell>
                       </IndexTable.Row>

                       <IndexTable.Row rowType="child" id={`Middle_${i}`}>
                           <IndexTable.Cell scope="row" className={"border-0"}>
                               <Text tone={"subdued"}>Request to your endpoint</Text>
                           </IndexTable.Cell>
                           <IndexTable.Cell colSpan={3} className={"border-0"}>
                               <pre className={"json-history"}>
                                  <code>
                                    {JSON.stringify(x?.response, null, 2)}
                                  </code>
                                </pre>
                           </IndexTable.Cell>
                       </IndexTable.Row>

                       <IndexTable.Row rowType="child" id={`Last_Child_${i}`}>
                           <IndexTable.Cell scope="row" className={"border-0"}>
                               <Text tone={"subdued"}>Response</Text>
                           </IndexTable.Cell>
                           <IndexTable.Cell className={"border-0"} colSpan={3}>
                               {x?.httpStatus == 200 ? 'Ok' : `Not Allowed | Request failed with status code ${x?.httpStatus}`}
                           </IndexTable.Cell>
                       </IndexTable.Row>
                   </Fragment>
               }
           </Fragment>
        )
    },);

    const resourceName = {
        singular: 'event log',
        plural: 'event logs',
    };

    return (
        <Fragment>
            <BlockStack gap={"400"}>
                <IndexTable
                    emptyState={<EmptySearchResult title={'No Event Logs'} withIllustration={loading !== 'eventLog'}/>}
                    resourceName={resourceName}
                    itemCount={loading === 'eventLog' ? null : history.length}
                    loading={loading === 'eventLog'}
                    selectable={false}
                    headings={[
                        {title: 'Status'},
                        {title: 'Event ID'},
                        {title: 'Date'},
                        {title: 'Retries'},
                    ]}
                >
                    {rowMarkup}
                </IndexTable>
                {
                    loading === 'eventLog' ? null : history.length > 0 ?
                        <div className={"pagination-event-log"}>
                            <Pagination
                                hasPrevious={loading === 'eventLog' ? false : (pageNo !== 1)}
                                onPrevious={onPrevious}
                                hasNext={loading === 'eventLog' ? false : (pageNo < totalPage && isNextPage) || (pageNo < totalPage)}
                                onNext={onNext}
                                type="table"
                                label={`${pageNo}/${totalPage} - ${modalTitle} Event Logs`}
                            />
                        </div> : ''
                }
            </BlockStack>
        </Fragment>
    );
};

export default EventLogTable;