import React, {Fragment} from 'react';
import {Icon,BlockStack} from "@shopify/polaris";
import {InfoIcon} from "@shopify/polaris-icons";
import {iconFieldType, isContentEmpty} from "../CommonUse/CommonUse";
import {defaultSignImage} from "../../../../Utils/Constent";
import PhoneInput from "react-phone-number-input";

const CommonInput = ({commonListProps}) => {
    const {fieldType, blockArea, selectedPageIndex, selectedBlockIndex} = commonListProps;
    const {style, initialValue, title,signatureURL,isRequired,placeholder, description, errorMessage} = blockArea[selectedPageIndex]?.[selectedBlockIndex] || {};

    return (
        <Fragment>
            <div>
                <div className="Polaris-Labelled__LabelWrapper">
                    <div className="Polaris-Label">
                        <label className={`Polaris-Label__Text ${isRequired == 1 ? "Polaris-Label__RequiredIndicator" : ""}`}>
                            <span className="Polaris-Text--root Polaris-Text--bodyMd">{title}</span>
                        </label>
                    </div>
                </div>

                {
                    fieldType == 5 ? <PhoneInput
                        international
                        initialValueFormat="national"
                        defaultCountry={style?.countryCode || "IN"}
                        placeholder={placeholder}
                        readOnly onChange={()=>{}} onCountryChange={()=>{}}
                    />
                        : fieldType == 14 ?
                        <BlockStack gap={"100"}>
                            <img src={signatureURL || defaultSignImage} className={"signatureURL"} alt="signature"/>
                        </BlockStack>
                        :
                        <div className="Polaris-Connected">
                            <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
                                <div className={`Polaris-TextField Polaris-TextField--hasValue ${fieldType == 2 ? "Polaris-TextField--multiline" : ""}`}>
                                    <div className="Polaris-TextField__Prefix">
                                        <Icon source={iconFieldType[fieldType] || iconFieldType.default}/>
                                    </div>
                                    {(fieldType == 2) ?
                                        <textarea placeholder={placeholder} autoComplete="off" value={initialValue || ""} className="Polaris-TextField__Input" rows="4" readOnly/> :
                                        <Fragment>
                                            <input readOnly placeholder={placeholder}  autoComplete="off" value={initialValue || ""} className="Polaris-TextField__Input"/>
                                        </Fragment>
                                    }
                                    <div className="Polaris-TextField__Backdrop"/>
                                </div>
                            </div>
                        </div>
                }

                {errorMessage?.trim() !== '' && isRequired == 1 &&
                <div className="Polaris-Labelled__Error">
                    <div className="Polaris-InlineError">
                        <div className="Polaris-InlineError__Icon">
                            <Icon source={InfoIcon}/>
                        </div>
                        {errorMessage}
                    </div>
                </div>
                }

                {isContentEmpty(description) ? null :
                    <div className="Polaris-Labelled__HelpText">
                            <span className="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--break Polaris-Text--subdued" dangerouslySetInnerHTML={{__html: description}}/>
                    </div>
                }

            </div>
        </Fragment>
    );
};

export default CommonInput;
