import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {Page, IndexTable, Layout, Card, Tooltip, Button, Box, Thumbnail, Badge, InlineStack, BlockStack, Text, SkeletonBodyText, Divider, Pagination, IndexFilters,
    useSetIndexFiltersMode} from '@shopify/polaris';
import {apiService, audioImage, baseUrl, capitalizeMessage, Copyright, imagePath, videoImage} from "../../../../Utils/Constent";
import {useNavigate, useParams} from "react-router-dom";
import {FileIcon} from "@shopify/polaris-icons";
import moment from "moment";
import {useSelector} from "react-redux";
import PlanBadge from "../../../Common/PlanBadge";
import NotFoundContent from "../../../Common/NotFoundContent";

const initialState = {
    completionRate: 0,
    totalFullSubmitted: 0,
    totalPartialSubmitted: 0,
    totalSubmitted: 0,
    totalViews: 0,
}

const Submissions = ({formData, setActive, setMessage, setIsError}) => {
    const navigate = useNavigate();

    const {workspaceId, id} = useParams();
    const planDetails = useSelector((state) => state.planDetails);

    const [selected, setSelected] = useState(0);
    const [submittedRecordsList, setSubmittedRecordsList] = useState([]);
    const [titleList, setTitleList] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [isNextPage, setIsNextPage] = useState(false);
    const [totalPage, setTotalPage] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    const [isAnalyticsLoading, setIsAnalyticsLoading] = useState(true);
    const [analyticsData, setAnalyticsData] = useState(initialState);
    const {mode, setMode} = useSetIndexFiltersMode();

    const handleTabChange = useCallback((selectedTabIndex) => {
        setSelected(selectedTabIndex)
        setPageNo(1)
    }, [selected],);

    useEffect(() => {
        getAnalytics()
    }, [])

    useEffect(() => {
        if (selected === 2 && (planDetails.planType == 0)) {
        } else {
            getSubmittedForms()
        }
    }, [pageNo, selected])

    const getAnalytics = async () => {
        setIsAnalyticsLoading(true)
        const payload = {
            startDate: '',
            endDate: '',
            formId: id,
        }
        const response = await apiService.getAnalytics(payload);
        if (response.success === true) {
            setIsAnalyticsLoading(false)
            setAnalyticsData(response?.data)
        } else {
            setIsAnalyticsLoading(false)
        }
    }

    const onPrevious = () => {
        const pageNoNew = Number(pageNo) - 1 === 0 ? 1 : Number(pageNo) - 1
        setPageNo(pageNoNew)
    }

    const onNext = () => {
        setPageNo(Number(pageNo) + 1)
    }

    const getSubmittedForms = async () => {
        setIsLoading(true)
        const payload = {
            formId: id,
            workSpaceId: workspaceId,
            status: selected === 0 ? planDetails.planType == 0 ? 1 : "all" : selected === 1 ? 1 : 0,
            pageNo: pageNo
        }
        const response = await apiService.getSubmittedForms(payload);
        if (response.success === true) {
            setIsLoading(false)
            setSubmittedRecordsList(response?.data?.submittedRecords || []);
            let newArrayTitle = [];
            (response?.data?.formField || []).forEach((x) => {
                if (x.fieldType >= 1 && x.fieldType <= 14) {
                    const obj = {...x, title: capitalizeMessage(x.title)}
                    newArrayTitle.push(obj);
                }
            });
           if(newArrayTitle.length > 0){
                newArrayTitle.push({
                    fieldType: 'submittedAt',
                    id: 'submittedAt',
                    title: 'Submitted At'
                });
           }
            setTitleList(newArrayTitle || []);
            setIsNextPage(response?.data?.nextPage)
            setTotalPage(response?.data?.totalPages)
        } else {
            setIsLoading(false)
        }
    }

    const submissionsDetail = [
        {label: "View", value: analyticsData?.totalViews || "0"},
        {label: "Total Submissions", value: analyticsData?.totalSubmitted || "0"},
        {label: "Full Submissions", value: analyticsData?.totalFullSubmitted || "0"},
        {label: "Partial Submissions", value: analyticsData?.totalPartialSubmitted || "0", isPlan: planDetails.planType == 0},
        {label: "Completion Rate", value: `${analyticsData?.completionRate || "0"}%` },
    ]

    const tabs = [
        {
            id: 'all-customers-1',
            content: 'All',
            panelID: 'all-customers-content-1',
        },
        {
            id: 'Completed-1',
            content: 'Completed',
            panelID: 'Completed-content-1',
        },
        {
            id: 'Partial-1',
            content: 'Partial',
            panelID: 'Partial-content-1',
        },
    ];

    const resourceName = {
        singular: 'submission-form',
        plural: 'submission-forms',
    };

    const onOpenUrl = (url) => {
        if(url){
            window.open(url, "_blank")
        } else {
            setMessage("URL is broken!!")
            setActive(true)
            setIsError(true)
        }
    }

    const handleCheckEnds = (urlsData) => {
        const thumbnails = (urlsData || []).map((url, i) => {
            let newUrl = url?.replace(imagePath, '') || '';
            let fileType;
            if (/(.jpg|.jpeg|.png|.gif|.svg|.heic|.webp|.bmp)$/i.test(url)) {
                fileType = 'image';
            } else if (/(.mp4|.webm|.mov)$/i.test(url)) {
                fileType = 'video';
            } else if (/(.mp3|.wav|.m4a)$/i.test(url)) {
                fileType = 'audio';
            } else {
                fileType = 'app';
            }

            return <Tooltip content={fileType === 'image' ? "Image" : fileType === 'video' ? 'Video' : fileType === 'audio' ? 'Audio' : 'File'} dismissOnMouseOut key={`${i}-${fileType}`}>
                <Button variant={"monochromePlain"} onClick={() => onOpenUrl(url)}>
                    <span title={newUrl}>
                        <Thumbnail source={fileType === 'image' ? url : fileType === 'video' ? videoImage : fileType === 'audio' ? audioImage : FileIcon} size="small" alt={newUrl}/>
                    </span>
                </Button>
            </Tooltip>
        });

        return thumbnails.filter(thumbnail => thumbnail !== null) || '-';
    }

    const renderInDetails = (fieldType, data, submittedAt) => {
        let parsedData;
        if ((fieldType == '3' || fieldType == '6' || fieldType == '7') && data?.trim() !== '') {
            parsedData = data ? JSON.parse(data) : "-";
        } else {
            parsedData = data;
        }
        switch (fieldType) {
            case "3":
                return Array.isArray(parsedData) ? <InlineStack gap={"100"}>{(parsedData || []).map((x,b) =>
                    <Badge key={b}>{x}</Badge>)}</InlineStack> : parsedData || "-";
            case "6":
            case "7":
                return Array.isArray(parsedData) ? parsedData.join(', ') : parsedData || "-";
            case "11":
                return parsedData && parsedData?.trim() !== '' ? <Button variant={"plain"} onClick={() => onOpenUrl(parsedData)}>{parsedData}</Button> : "-";
            case "13":
                return  handleCheckEnds(parsedData).length > 0 ? <InlineStack wrap={false} gap={"100"}>{handleCheckEnds(parsedData)}</InlineStack> : "-";
            case "14":
                return parsedData ? parsedData?.trim() !== '' ? <Tooltip content={"Open"} dismissOnMouseOut>
                    <Button variant={"monochromePlain"} onClick={() => onOpenUrl(parsedData)}>
                        <Thumbnail source={parsedData} size="small" alt={"signature"}/>
                    </Button>
                </Tooltip> : '-' : '-';
            case "submittedAt":
                return submittedAt ? moment(submittedAt).format("MMM DD, YYYY hh:mm a") : '-';
            default:
                return parsedData || '-';
        }
    }

    const rowMarkup = (submittedRecordsList || []).map((x, i,) => {
            return (
                <IndexTable.Row key={i}>
                    {
                        (titleList || []).map((t, j) => {
                            return (
                                <IndexTable.Cell key={j}>
                                    {renderInDetails(t.fieldType, x[t.id], x.createdAt)}
                                </IndexTable.Cell>
                            )
                        })
                    }
                </IndexTable.Row>
            )
        },);

    return (
        <Fragment>

            <div className={"page-style"}>
                <Page title={<Fragment>Submissions of My {formData?.name || ''}</Fragment>}
                      // primaryAction={{content: <span><Tooltip content={"Download"}><span>Download</span></Tooltip></span>, onAction: ()=> console.log("***")}}
                >
                    <Layout>
                        <Layout.Section>
                            <Card padding={"0"}>
                                <div className="dashboard-card">
                                    {
                                        submissionsDetail.map((x,i) => {
                                            return (
                                                <Box paddingBlockEnd={"400"} paddingBlockStart={"400"} padding={"500"} key={i}>
                                                    <BlockStack gap={isAnalyticsLoading ? "400" : "200"}>
                                                        <Text variant="headingMd" tone={x.isPlan ? "disabled" : ""}>{x.label}</Text>
                                                        {isAnalyticsLoading ? <SkeletonBodyText lines={1} /> : <InlineStack gap={"100"} blockAlign={"center"}><Text variant={"headingLg"} tone={x.isPlan ? "disabled" : ""}>{x.isPlan ? "0" : x.value} </Text> {x.isPlan ? <PlanBadge/>:""}</InlineStack>}
                                                    </BlockStack>
                                                </Box>
                                            )
                                        })
                                    }
                                </div>
                            </Card>
                        </Layout.Section>

                        {
                            formData?.publishStatus == 0 ? <Layout.Section>
                                <Card>
                                    <Text>No complete submissions yet. Please share your form to the world to start collecting submissions.</Text>
                                </Card>
                            </Layout.Section> : null
                        }

                        <Layout.Section>
                            <Card padding={"0"}>
                                <IndexFilters
                                    queryValue={''}
                                    queryPlaceholder="Searching by date"
                                    onQueryChange={() => {}}
                                    onQueryClear={() => {}}
                                    cancelAction={{
                                        onAction: () => {},
                                        disabled: false,
                                        loading: false,
                                    }}
                                    hideQueryField
                                    tabs={tabs}
                                    selected={selected}
                                    onSelect={(selectedTabIndex)=> handleTabChange(selectedTabIndex)}
                                    canCreateNewView={false}
                                    filters={[]}
                                    hideFilters
                                    mode={mode}
                                    setMode={setMode}
                                />
                                <IndexTable
                                    emptyState={selected === 2 && planDetails.planType == 0 ?
                                        <Box paddingBlock={"400"}>
                                            <BlockStack gap={"400"} align={"center"} inlineAlign={"center"}>
                                                <Text variant={"headingMd"}>Partial Submissions <PlanBadge/></Text>

                                                <Box maxWidth={"600px"}>
                                                    <Text alignment={"center"} tone={"subdued"}>
                                                        Collecting partial submission details allows you to capture information from users who start filling out a form but do not complete it. This feature can provide valuable insights into user behavior and help you improve your form's design and usability.
                                                    </Text>
                                                </Box>

                                                <Button variant={"primary"} onClick={()=> navigate(`${baseUrl}/plan`)}>Enable Partial Submissions</Button>
                                            </BlockStack>
                                        </Box>:
                                        <NotFoundContent isLoading={isLoading}><Text variant={"headingMd"}>No Submissions</Text></NotFoundContent>
                                    }
                                    resourceName={resourceName}
                                    itemCount={isLoading ? null : selected === 2 && planDetails.planType == 0 ? 0 :submittedRecordsList?.length}
                                    loading={isLoading}
                                    selectable={false}
                                    headings={(isLoading || titleList?.length === 0) ? [{title: ''}] : titleList}
                                >
                                    {selected === 2 && planDetails.planType == 0 ? null : rowMarkup}
                                </IndexTable>
                                {
                                     (selected === 2 && planDetails.planType == 0) || isLoading ? null : submittedRecordsList?.length > 0 ? <Fragment>
                                        <Divider/>
                                        <Box paddingInlineEnd={"500"} paddingBlockEnd={"300"} paddingBlockStart={"300"}>
                                            <InlineStack align={"end"}>
                                                <Pagination
                                                    hasPrevious={pageNo !== 1}
                                                    onPrevious={onPrevious}
                                                    hasNext={(pageNo < totalPage && isNextPage) || (pageNo < totalPage)}
                                                    onNext={onNext}
                                                />
                                            </InlineStack>
                                        </Box>
                                    </Fragment> : ""
                                }
                            </Card>
                        </Layout.Section>
                    </Layout>
                </Page>
                <footer><Text>{Copyright}</Text></footer>
            </div>
        </Fragment>
    )
};

export default Submissions;