import React, {useEffect} from 'react';
import {Navigate, Outlet, useLocation} from "react-router-dom";
import {baseUrl, capitalizeMessage, getToken} from "../../Utils/Constent";
import {Frame} from "@shopify/polaris";
import {useSelector} from "react-redux";

const Public = () => {
    const location = useLocation();

    const checkPath = location.pathname || '';
    const userDetails = useSelector((state) => state.userDetails);

    useEffect(() => {
        const title = checkPath?.split('/')?.[1];
        document.title = `Web Form ${title ? `| ${capitalizeMessage(title)}` : ''}`;
    }, [checkPath]);

    return getToken() ? getToken() && userDetails?.enabled2fa == 1 ? <Frame><Outlet/></Frame> : <Navigate to={`${baseUrl}/dashboard`}/> : <Frame><Outlet/></Frame>
};

export default Public;