import React, {Fragment, useId, useState} from 'react';
import {Text, BlockStack, TextField, Button, Tooltip, Modal, Toast,InlineStack,Thumbnail, Box, Divider, Icon, useBreakpoints} from '@shopify/polaris';
import {CheckIcon, ClipboardIcon, ExternalIcon, LinkIcon} from "@shopify/polaris-icons";
import {webDomain} from "../../../../Utils/Constent";
import shareX from "../../../../Img/share-x.png";
import shareLinkedIn from "../../../../Img/share-linkedin.png";
import shareWhatsapp from "../../../../Img/share-whatsapp.png";
import shareFacebook from "../../../../Img/share-facebook.png";
import {commonParagraph} from "../../../../Utils/Loader";
import {onCopy} from "../CommonUse/CommonUse";

const ShareModal = ({commonPropsShare}) => {
    const  {isShareModal, setIsShareModal, formData, isLoading} = commonPropsShare ;
    const  {smDown} = useBreakpoints() ;

    const [message, setMessage] = useState('');
    const firstId = useId();
    const secondId = useId();

    const toggleActive = () => {
        setMessage('');
    };

    const onWindowOpen = (url) => {
        window.open(`${url}${webDomain}/form/${formData?.publishId||""}`, "_blank")
    }

    const shareArray = [
        {
            img: shareFacebook,
            alt: "Share on Facebook",
            onClick : () => onWindowOpen(`https://www.facebook.com/sharer/sharer.php?u=`),
        },
        {
            img: shareWhatsapp,
            alt: "Share on Whatsapp",
            onClick : () => onWindowOpen(`https://api.whatsapp.com/send?text=`),
        },
        {
            img: shareX,
            alt: "Share on X",
            onClick : () => onWindowOpen(`https://twitter.com/intent/post?url=`),
        },
        {
            img: shareLinkedIn,
            alt: "Share on LinkedIn",
            onClick : () => onWindowOpen(`https://www.linkedin.com/shareArticle?url=`),
        },
    ]

    return (
        <Fragment>
            {message !== "" && <Toast content={message} onDismiss={toggleActive} duration={3000}/>}

            <Modal
                open={isShareModal}
                onClose={() => setIsShareModal(false)}
                title="Share Link"
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: () => setIsShareModal(false),
                    },
                ]}
            >
                <Modal.Section>
                    {
                        formData?.publishStatus == '1' ?
                            <BlockStack gap={"300"}>
                                <Text>
                                    Your form is now published and ready to be shared with the world! Copy this link to share
                                    your form on social media, messaging apps or via email.
                                </Text>

                                {
                                    isLoading ? commonParagraph(1) :
                                        <BlockStack gap={"600"}>
                                            <div className="copy-code" id={`cc${firstId}copy`}>
                                                <InlineStack gap={"150"} wrap={smDown} as={"span"}>
                                                    <Box as={"span"} width={"100%"}>
                                                        <TextField id={`cc${secondId}text`} prefix={<Icon source={LinkIcon}/>}
                                                                   labelHidden value={`${webDomain}/form/${formData?.publishId||""}`} readOnly autoComplete="off"
                                                                   suffix={<Box paddingBlockStart={"150"}>
                                                                       <Tooltip content={"Open in New Tab"}>
                                                                           <Button variant={"monochromePlain"} icon={ExternalIcon} onClick={() => window.open(`${webDomain}/form/${formData?.publishId||""}`, "_blank")}/>
                                                                       </Tooltip>
                                                                   </Box>}
                                                        />
                                                    </Box>
                                                    <Tooltip content={message === '' ? "Copy" : "Copied!"}>
                                                        <Button size={"large"} variant={"primary"} icon={message === '' ? ClipboardIcon : CheckIcon}
                                                                onClick={() => onCopy(`cc${secondId}text`, `cc${firstId}copy`, setMessage)}
                                                        >{message === '' ? "Copy" : "Copied!"}</Button>
                                                    </Tooltip>
                                                </InlineStack>
                                            </div>
                                            <Divider/>
                                            <BlockStack gap={"400"}>
                                                <BlockStack>
                                                    <Text variant={"headingMd"}>Share Form</Text>
                                                    <Text variant={"bodyMd"} tone={"subdued"}>Share your form link in various social posts and through email.</Text>
                                                </BlockStack>

                                                <InlineStack gap={"300"}>
                                                    {
                                                        shareArray.map((x,i) => {
                                                            return (
                                                                <Button key={i} variant={"monochromePlain"} onClick={x.onClick}>
                                                                    <Tooltip content={x.alt} dismissOnMouseOut>
                                                                        <Thumbnail source={x.img} size="small" alt={x.alt}/>
                                                                    </Tooltip>
                                                                </Button>
                                                            )
                                                        })
                                                    }
                                                </InlineStack>
                                            </BlockStack>
                                        </BlockStack>
                                }
                            </BlockStack>
                            : <Text>Your form is unpublished.</Text>
                    }
                </Modal.Section>
            </Modal>
        </Fragment>
    );
};

export default ShareModal;