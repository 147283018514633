import React from 'react';
import {Box, EmptyState} from "@shopify/polaris";

const NotFoundContent = ({isLoading, heading, imgSrc, action, secondaryAction, children}) => {
    return isLoading ? <Box paddingBlock={"400"}/> :
        <EmptyState heading={heading ? heading : null} image={imgSrc ? imgSrc : 'https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png'} action={action ?? null} secondaryAction={secondaryAction ?? null} fullWidth>
            {children ? <Box paddingBlockStart={"200"}>{children}</Box> : ''}
        </EmptyState>
};

export default NotFoundContent;