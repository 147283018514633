import React, {Fragment} from "react";
import {ApiService} from "./ApiService";

export const baseUrl = '';
export const webDomain = 'https://app.webform.io';

export const imagePath = `${webDomain}/uploads/`;

export const SET_WORKSPACE = 'SET_WORKSPACE';
export const SET_USER_DETAILS  = 'SET_USER_DETAILS';
export const SET_PLAN_DETAILS  = 'SET_PLAN_DETAILS';
export const SET_IS_LOADING_PLAN  = 'SET_IS_LOADING_PLAN';

export const defaultImage = 'https://img.freepik.com/premium-vector/default-image-icon-vector-missing-picture-page-website-design-mobile-app-no-photo-available_87543-11093.jpg';
export const defaultSignImage = 'https://img.freepik.com/premium-vector/vector-template-typography-poster-sticker-banner-sticker-etc_98908-478.jpg?w=826';
export const videoImage = 'https://cdn-icons-png.freepik.com/256/15228/15228070.png?ga=GA1.1.952984711.1709782639&';
export const audioImage = 'https://cdn-icons-png.freepik.com/256/13240/13240626.png?ga=GA1.1.952984711.1709782639&';

export const googleClientId = "363556015742-v6r9cohqqgckvl3h5otqp0hqge24t90m.apps.googleusercontent.com";

export const apiService = new ApiService()

export const OR = <div className={"or"}>OR</div>;

export const Copyright = <Fragment>Copyright &copy; 2024 Web Form</Fragment>;

export const emailRegExp = /^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/;

export const capitalizeMessage = (message) => {
    return (message?.split(' ') || []).map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
};

export const getToken = () => {
    return localStorage.getItem('token')
}

export const removeDetails = () => {
    localStorage.clear()
}

export const getLSUserDetails = () => {
    const savedUser = localStorage.getItem('user-details');
    return savedUser ? JSON.parse(savedUser) : {};
}

export const isEmpty = (value) => {
    return (
        value === null || value === undefined ||
        (typeof value === 'string' && value.trim() === '')
    );
};

export const onKeyFire = (event, onCallback) => {
    if(event.key === "Enter"){
        onCallback?.();
    }
}