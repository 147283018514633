import React, {Fragment} from 'react';
import ReactQuill from "react-quill";

const Elements = ({commonListProps}) => {
    const {fieldType, blockArea, selectedPageIndex, selectedBlockIndex, formData} = commonListProps;
    const {style, title,} = blockArea[selectedPageIndex]?.[selectedBlockIndex] || {};

    const objTextColor = {
        color: formData?.style?.globalTextColor,
        fill: formData?.style?.globalTextColor,
    }

    const styledObj = {
        color: style?.text_color,
        backgroundColor: style?.bg,
        textAlign: style?.alignment
    }

    return (
        <Fragment>
            {
                fieldType == 16 ?
                    <h1 className={"Polaris-Text--root Polaris-Text--heading3xl"} style={styledObj}>{title}</h1> :
                    fieldType == 17 ?
                        <h2 className={'Polaris-Text--root Polaris-Text--heading2xl'} style={styledObj}>{title}</h2> :
                        fieldType == 18 ?
                            <h3 className={'Polaris-Text--root Polaris-Text--headingXl'} style={styledObj}>{title}</h3> :
                                fieldType == 19 ?
                                    <span style={objTextColor}>
                                        <ReactQuill className={"react-quill"} value={title} readOnly modules={{toolbar: false}}/>
                                    </span> :
                                    fieldType == 20  ?
                                            <hr className="Polaris-Divider" style={{borderBlockStart: `${style?.divider_width}px ${style?.divider_style ? style.divider_style : 'solid'} ${style?.divider_color}`}}/>
                                        : null
            }
        </Fragment>
    );
};

export default Elements;