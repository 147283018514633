import React, {Fragment} from "react"
import { Image} from "@shopify/polaris";
import {imagePath} from "../../../../Utils/Constent";
import {useWindowSize} from "./CommonUse";

const FormBanner = ({formData, isMobileView}) => {
    const bannerPosition = formData?.style?.bannerPosition;
    const {width} = useWindowSize();

    return (
       <Fragment>
           {
               formData?.banner !== '' &&
               <div className={`heroBanner ${bannerPosition}`}>
                   <Image
                       source={`${formData?.banner?.name ? URL.createObjectURL(formData?.banner) : `${imagePath}${formData?.banner}`}`}
                       alt={'banner'} className={'bannerImg'}
                   />
               </div>
           }
           {
               formData?.logo !== '' && <div style={{ padding:` 0 10px`,margin: `${isMobileView && formData?.style?.bannerSize === 'fullwidth' ? '0 auto' : isMobileView && formData?.style?.bannerSize === 'container' ? "0 auto 10px" :  formData?.style?.bannerSize === 'container' ? '0 auto 20px' : '0 auto'}`,maxWidth: `${formData?.style?.formSize === 'small' ? '662px' : formData?.style?.formSize === 'medium' ? '998px' : '100%'}`}}>
                   <div className={`formLogo ${formData?.style?.logoPosition}`}> <Image width={width > 475 ? 100 : 50} height={width > 475 ? 100 : 50}
                       source={`${formData?.logo?.name ? URL.createObjectURL(formData?.logo) : `${imagePath}${formData?.logo}`}`}
                       alt={'logo image'} className={'logoImg'}
                   /></div>
               </div>
           }
       </Fragment>
    )
}
export default FormBanner;