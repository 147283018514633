import React from 'react';
import {baseUrl} from "../../Utils/Constent";
import {useNavigate} from "react-router-dom";

const PlanBadge = () => {
    const navigate = useNavigate();

    return <span className="proText" title={"Allowed in Professional Plan"} onClick={()=> navigate(`${baseUrl}/plan`)}>Professional</span>;
};

export default PlanBadge;