import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {Modal, TextField, Toast, Box, Select, BlockStack} from "@shopify/polaris";
import {apiService, baseUrl, onKeyFire} from "../../../Utils/Constent";
import {workSpaceDetailsAction} from "../../../Redux/Action/Action";
import qs from "qs";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

const WorkSpaceModal = ({isWorkspaceModal, setIsWorkspaceModal, wpUpdateRecord, setWpUpdateRecord}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [workSpaceName, setWorkSpaceName] = useState('');
    const [selectedOrganization, setSelectedOrganization] = useState('');
    const [isWorkspaceLoading, setIsWorkspaceLoading] = useState(false);
    const [formError, setErrors] = useState({});
    const [organization, setOrganization] = useState([]);
    const [active, setActive] = useState(false);
    const [message, setMessage] = useState('');
    const [isError, setIsError] = useState(false);

    const workSpacesAll = useSelector((state) => state.workSpaceDetails);
    const planDetails = useSelector((state) => state.planDetails);

    useEffect(() => {
        if(wpUpdateRecord?.name){
            setWorkSpaceName(wpUpdateRecord?.name)
        }
    }, [wpUpdateRecord]);

    useEffect(() => {
        const data = [{label: "Select organization", value: "", disabled: true}];
        planDetails.org.map((x) => {
            let obj = {
                label: `${x.firstName} ${x.lastName} Organization`,
                value: x.orgId?.toString()
            }
            data.push(obj)
        })
        setOrganization(data)
    }, [planDetails]);

    const onCreateWorkspace = async () => {
        if (workSpaceName.trim() === "") {
            setErrors({...formError, workspace_name: "Name is required",});
            return;
        } else if (selectedOrganization === "" && organization.length >= 2) {
            setErrors({...formError, organization: "Organization is required",});
            return;
        } else {
            setErrors({})
        }

        const payload = {
            name: workSpaceName,
            ownerId: selectedOrganization
        }

        setIsWorkspaceLoading(true);
        const response = await apiService.createWorkSpace(payload);
        if (response.success === true) {
            const payload = {
                id: response.data.id,
                name: workSpaceName,
                forms: []
            };
            dispatch(workSpaceDetailsAction([...workSpacesAll, {...payload, formCount: 0}]));
            setIsWorkspaceLoading(false);
            setMessage(response?.message);
            setActive(true);
            onCloseModal();
            setIsError(false);
            navigate({
                pathname: `${baseUrl}/workspace/${response.data.id}`,
                search: qs.stringify( {workSpaceTab: 0}),
            });
        } else {
            setIsWorkspaceLoading(false);
            setActive(true);
            setMessage(response?.error?.message);
            setIsError(true);
        }
    }

    const onUpdateWorkSpace = async  () => {
        if (workSpaceName.trim() === "") {
            setErrors({...formError, workspace_name: "Name is required.",});
            return;
        } else {
            setErrors({})
        }

        const payload = {
             name: workSpaceName
         };

        if(wpUpdateRecord?.id){
            setIsWorkspaceLoading(true);
            const response = await apiService.workspaceFormUpdateDelete(wpUpdateRecord?.id, payload);
            if (response.success === true) {
                setIsWorkspaceLoading(false);
                const updatedWorkSpaces = (workSpacesAll || []).map(x => {
                    if (x.id == wpUpdateRecord.id) {
                        return {...x, name: workSpaceName};
                    }
                    return x;
                });
                dispatch(workSpaceDetailsAction(updatedWorkSpaces));
                setWpUpdateRecord({})
                setMessage(response.message);
                setActive(true);
                setIsError(false)
                onCloseModal();
            } else {
                setIsWorkspaceLoading(false);
                setActive(true);
                setMessage(response.error.message);
                setIsError(true);
            }
        }
    }

    const onCloseModal = () => {
        setWorkSpaceName('')
        setIsWorkspaceModal(false)
        setErrors({})
    }

    const toggleActive = useCallback(() => {
        setActive(false)
        setIsError(false)
        setMessage('')
    }, []);

    return (
        <Fragment>
            {(active && message !== '') && <Toast content={message} onDismiss={toggleActive} error={isError}/>}

            {
                isWorkspaceModal &&
                <Modal size={"small"}
                       open={isWorkspaceModal}
                       onClose={onCloseModal}
                       title={`${(wpUpdateRecord?.id ) ?  "Update" : "Create New"} Workspace`}
                       primaryAction={{
                           content: 'Save',
                           onAction: (wpUpdateRecord?.id ) ? onUpdateWorkSpace : onCreateWorkspace,
                           loading: isWorkspaceLoading
                       }}
                >
                    <Modal.Section>
                        <Box as={"span"} onKeyPress={(e) => onKeyFire(e, (wpUpdateRecord?.id ) ? onUpdateWorkSpace : onCreateWorkspace)}>
                            <BlockStack gap={"400"}>
                                {
                                    organization.length >=2 && !wpUpdateRecord?.id ? <Select
                                        label="Select Organization"
                                        options={organization}
                                        value={selectedOrganization}
                                        onChange={(value) => setSelectedOrganization(value)}
                                        error={formError.organization}
                                    /> : ""
                                }
                                <TextField
                                    label="Give your workspace name"
                                    placeholder={"Enter name"}
                                    value={workSpaceName}
                                    error={formError.workspace_name}
                                    onChange={(value) => setWorkSpaceName(value)}
                                />
                            </BlockStack>
                        </Box>
                    </Modal.Section>
                </Modal>
            }

        </Fragment>
    );
};

export default WorkSpaceModal;