import React from 'react';
import CommonInput from "../FormList/CommonInput";
import MultiSelect from "../FormList/MultiSelect";
import SingleSelectOption from "../FormList/SingleSelectOption";
import MultiSelectOption from "../FormList/MultiSelectOption";
import DropdownList from "../FormList/DropdownList";
import StarRating from "../FormList/StarRating";
import FileUpload from "../FormList/FileUpload";
import ImgTag from "../FormList/ImgTag";
import Elements from "../FormList/Elements";
import VideoPlay from "../FormList/VideoPlay";
import Captcha from "../FormList/Captcha";

export const RenderComponentByFieldType = (fieldType, pageIndex, blockIndex, blockArea, formData) => {
    const commonListProps = {fieldType: fieldType, blockArea: blockArea, selectedPageIndex: pageIndex, selectedBlockIndex: blockIndex, formData: formData}
    switch (fieldType) {
        case "1":
        case "2":
        case "4":
        case "5":
        case "9":
        case "10":
        case "11":
        case "14":
            return <CommonInput {...{commonListProps}} />;
        case "3":
            return <MultiSelect {...{commonListProps}}/>;
        case "6":
            return <SingleSelectOption {...{commonListProps}}/>;
        case "7":
            return <MultiSelectOption {...{commonListProps}}/>;
        case "8":
            return <DropdownList {...{commonListProps}} />;
        case "12":
            return <StarRating {...{commonListProps}}/>;
        case "13":
            return <FileUpload {...{commonListProps}}/>;
        case "21":
            return <ImgTag {...{commonListProps}}/>;
        case "16":
        case "17":
        case "18":
        case "19":
        case "20":
            return <Elements {...{commonListProps}}/>;
        case "22":
            return <VideoPlay {...{commonListProps}}/>;
        case "15":
            return <Captcha {...{commonListProps}}/>;
        default:
            return null;
    }
};