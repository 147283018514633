import {SET_IS_LOADING_PLAN, SET_PLAN_DETAILS, SET_USER_DETAILS, SET_WORKSPACE} from "../../Utils/Constent";

export const workSpaceDetailsAction = (objValues) => {
    return (
        {
            type: SET_WORKSPACE,
            payload: objValues
        }
    )
};

export const setUserDetails = (objValues) => {
    return (
        {
            type: SET_USER_DETAILS,
            payload: objValues
        }
    )
};

export const setPlanDetails = (objValues) => {
    return (
        {
            type: SET_PLAN_DETAILS,
            payload: objValues
        }
    )
};

export const setIsLoadingPlan = (isLoading) => ({
    type: SET_IS_LOADING_PLAN,
    payload: isLoading,
});