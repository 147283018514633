import React, { Fragment } from 'react';
import {Icon} from '@shopify/polaris';
import {CaretDownIcon, InfoIcon} from "@shopify/polaris-icons";
import {isContentEmpty} from "../CommonUse/CommonUse";

const DropdownList = ({ commonListProps }) => {
    const { blockArea, selectedPageIndex, selectedBlockIndex } = commonListProps;
    const { title, isRequired,placeholder, description, options, errorMessage } = blockArea[selectedPageIndex]?.[selectedBlockIndex] || {};

    return (
        <Fragment>
            <div>
                <div className="Polaris-Labelled__LabelWrapper">
                    <div className="Polaris-Label">
                        <label className={`Polaris-Label__Text ${isRequired == 1 ? "Polaris-Label__RequiredIndicator" : ""}`}>
                            <span className="Polaris-Text--root Polaris-Text--bodyMd">{title}</span>
                        </label>
                    </div>
                </div>

                <div className="Polaris-Select">
                    <select className="Polaris-Select__Input" defaultValue={options[0]}>
                        {
                            placeholder.trim() !== '' &&
                            <option value={''} disabled>{placeholder}</option>
                        }
                        {(options || []).map(({ value, label }, i) => (
                            <option key={i} value={value}>{label}</option>
                        ))}
                    </select>
                    <div className="Polaris-Select__Content" aria-hidden="true">
                        <span className="Polaris-Select__SelectedOption">{placeholder.trim() === '' ? options[0]?.value : placeholder}</span>
                        <span className="Polaris-Select__Icon">
                            <Icon source={CaretDownIcon} />
                        </span>
                    </div>
                    <div className="Polaris-Select__Backdrop" />
                </div>

                {errorMessage?.trim() !== '' && isRequired == 1 &&
                <div className="Polaris-Labelled__Error">
                    <div className="Polaris-InlineError">
                        <div className="Polaris-InlineError__Icon">
                            <Icon source={InfoIcon}/>
                        </div>
                        {errorMessage}
                    </div>
                </div>
                }

                {isContentEmpty(description) ? null :
                    <div className="Polaris-Labelled__HelpText">
                        <span className="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--break Polaris-Text--subdued" dangerouslySetInnerHTML={{__html: description}}/>
                    </div>
                }

            </div>

        </Fragment>
    );
};

export default DropdownList;
