import {Suspense} from 'react';
import '@shopify/polaris/build/esm/styles.css';
import "react-phone-number-input/style.css";
import 'react-quill/dist/quill.snow.css';
import './Styles.scss';
import DefaultLayout from "./Components/DefaultLayout/DefaultLayout";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {baseUrl} from "./Utils/Constent";
import {routes} from "./Utils/Routes";
import Login from "./Auth/AuthScreen/Login";
import Public from "./Auth/AuthStructure/Public";
import Register from "./Auth/AuthScreen/Register";
import Protected from "./Auth/AuthStructure/Protected";
import enTranslations from '@shopify/polaris/locales/en.json';
import {AppProvider} from '@shopify/polaris';
import ForgotPassword from "./Auth/AuthScreen/ForgotPassword";
import {Spinner} from '@shopify/polaris';
import ResetPassword from "./Auth/AuthScreen/ResetPassword";
import ZapierAuthorize from "./Auth/AuthScreen/ZapierAuthorize";
import PageNotFound from "./Auth/AuthStructure/PageNotFound";
import TFA from "./Auth/AuthScreen/TFA";
import OnBoarding from "./Components/OnBoarding/OnBoarding";

function App() {
    return (
        <AppProvider i18n={enTranslations}>
            <Suspense fallback={<Spinner size="large"/>}>
                <BrowserRouter>
                    <Routes>
                        <Route element={<Protected/>}>
                            <Route exact path={`${baseUrl}/`} element={<DefaultLayout/>}>
                                {
                                    (routes || []).map((x, i) => {
                                        return <Route key={i} path={x.path} element={x.component}/>
                                    })
                                }
                                <Route path={`${baseUrl}/`} element={<Navigate to={`${baseUrl}/dashboard`} replace/>}/>
                                <Route path={`${baseUrl}/authorize`} element={<ZapierAuthorize/>}/>
                            </Route>
                        </Route>

                        <Route element={<Public/>}>
                            <Route path={`${baseUrl}/register`} element={<Register/>}/>
                            <Route path={`${baseUrl}/login`} element={<Login/>}/>
                            <Route path={`${baseUrl}/forgot-password`} element={<ForgotPassword/>}/>
                            <Route path={`${baseUrl}/reset-password`} element={<ResetPassword/>}/>
                            <Route path={`${baseUrl}/verify-2fa`} element={<TFA/>}/>
                        </Route>

                        <Route path="*" element={<PageNotFound/>}/>
                    </Routes>
                </BrowserRouter>
            </Suspense>
        </AppProvider>
    );
}

export default App;
