import React, {Fragment} from 'react';
import {Box, Divider, TextField} from "@shopify/polaris";

const CaptchaKey = ({commonProps}) => {
    const { blockArea, selectedPageIndex, selectedBlockIndex, handleChangeStyles } = commonProps;
    const {captchaKey} = blockArea[selectedPageIndex]?.[selectedBlockIndex] || {};

    return (
        <Fragment>
            <Box padding={"400"} paddingBlockStart={"200"}>
                <TextField
                    label="Captcha Key"
                    placeholder={"Type or paste a key"}
                    value={captchaKey}
                    onChange={(value) => handleChangeStyles('captchaKey', value)}
                    helpText={"Works with site key"}
                    autoComplete="off"
                />
            </Box>
            <Divider/>
        </Fragment>
    );
};

export default CaptchaKey;