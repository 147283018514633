import React, {Fragment} from 'react';
import {Icon, Tooltip, Badge} from "@shopify/polaris";
import {PlusIcon} from "@shopify/polaris-icons";
import EllipsisText from "../Common/EllipsisText";
import {useSelector} from "react-redux";
import PlanBadge from "../Common/PlanBadge";
import {useNavigate} from "react-router-dom";
import {baseUrl} from "../../Utils/Constent";

const NavigationMarkup = ({navigationProps}) => {
    const  {navigationItems, setIsWorkspaceModal, loading} = navigationProps;
    const planDetails = useSelector((state) => state.planDetails);
    const navigate = useNavigate();

    // const renderSubNavigationItems = (subItems) => (
    //     <ul className="Polaris-Navigation__SubNavigation">
    //         {subItems.map((subItem, subIndex) => (
    //             <li key={subIndex} className="Polaris-Navigation__ListItem">
    //                 <div className="Polaris-Navigation__ItemWrapper">
    //                     <div className={`Polaris-Navigation__ItemInnerWrapper ${subItem?.selected ? 'Polaris-Navigation__ItemInnerWrapper--selected' : ''}`}>
    //                         <button type="button" className={`Polaris-Navigation__Item ${subItem?.selected ? 'Polaris-Navigation__Item--selected' : ''}`}
    //                                 onClick={subItem?.onClick}
    //                         >
    //                             <span className="Polaris-Navigation__Text">
    //                                 <EllipsisText text={loading ? '' : subItem?.label || ''} /></span>
    //                         </button>
    //                     </div>
    //                 </div>
    //             </li>
    //         ))}
    //     </ul>
    // );

    return (
        <Fragment>
            <nav className="Polaris-Navigation">
                <div
                    className="Polaris-Navigation__PrimaryNavigation Polaris-Scrollable Polaris-Scrollable--vertical Polaris-Scrollable--horizontal"
                    data-polaris-scrollable="true"
                >
                    {(navigationItems || []).map((x, index) => (
                        <ul key={index} className={`Polaris-Navigation__Section ${x?.separator ? 'Polaris-Navigation__Section--withSeparator' : ''}`}>
                            {x.title && (
                                <li className="Polaris-Navigation__SectionHeading" style={{paddingBottom : "calc(var(--p-space-300) - var(--pc-app-provider-scrollbar-width))"}}>
                                  <span className="Polaris-Text--root Polaris-Text--bodySm Polaris-Text--medium Polaris-Text--subdued">
                                    {x?.title}
                                  </span>
                                    {
                                        x?.title === 'Workspace' && <Tooltip active={loading ? false : ""} content={planDetails.planType == 0 ? <PlanBadge/> : "Create New Workspace"}>
                                        <button type="button" className="Polaris-Navigation__Action" disabled={loading} onClick={() =>  planDetails.planType == 0 ? navigate(`${baseUrl}/plan`) : setIsWorkspaceModal(true)} aria-label="Create New Workspace">
                                                <Icon source={PlusIcon} tone="base"/>
                                            </button>
                                        </Tooltip>
                                    }
                                </li>
                            )}
                            {x?.items?.map((subItem, subIndex) => (
                                <li key={subIndex} className="Polaris-Navigation__ListItem">
                                    <div className="Polaris-Navigation__ItemWrapper">
                                            <Tooltip content={'Allowed in Professional Plan'} active={!subItem.isPlan ? false : ""} dismissOnMouseOut>
                                                <div className={`Polaris-Navigation__ItemInnerWrapper ${subItem?.selected ? 'Polaris-Navigation__ItemInnerWrapper--selected' : ''}`}>
                                                        <button type="button" style={{alignItems: "center"}} className={`Polaris-Navigation__Item ${subItem?.selected ? 'Polaris-Navigation__Item--selected' : ''}`}
                                                                onClick={subItem?.onClick}
                                                        >
                                                            <div className="Polaris-Navigation__Icon">
                                                                <Icon source={subItem?.icon}/>
                                                            </div>
                                                            <span className="Polaris-Navigation__Text">{loading ? subItem?.label : <EllipsisText text={subItem?.label || ''} maxWidth={145}/>}</span>
                                                            {((!loading) && x.title === 'Workspace') &&
                                                                <div className={`hovered-btn`}><Badge>{subItem?.formCount}</Badge></div>
                                                            }
                                                        </button>
                                                </div>
                                            </Tooltip>
                                        {/*{subItem?.subNavigationItems && renderSubNavigationItems(subItem?.subNavigationItems)}*/}
                                    </div>
                                </li>
                            ))}

                            {loading || planDetails.planType == 0 ? null :
                                (x?.items.length === 0 && x.title === 'Workspace') ?
                                    <li className="Polaris-Navigation__ListItem">
                                        <div className="Polaris-Navigation__ItemWrapper">
                                            <Tooltip content={"Create New Workspace"}>
                                                <div
                                                    className={`Polaris-Navigation__ItemInnerWrapper Polaris-Navigation__ItemInnerWrapper--selected`}>
                                                    <button type="button" disabled={planDetails.planType == 0}
                                                            className={`Polaris-Navigation__Item w-100`}
                                                            onClick={planDetails.planType == 0 ? null : () => setIsWorkspaceModal(true)}>
                                                        <div className="Polaris-Navigation__Icon"><Icon source={PlusIcon}/></div>
                                                        <span className="Polaris-Navigation__Text">Add New Workspace</span>
                                                    </button>
                                                </div>
                                            </Tooltip>
                                        </div>
                                    </li> : ""
                            }
                        </ul>
                    ))}
                </div>
            </nav>
        </Fragment>
    );
};

export default NavigationMarkup;