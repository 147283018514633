import React, {Fragment, useRef} from "react";
import ReCAPTCHA from 'react-google-recaptcha'

const Captcha = ({commonListProps}) => {
    const {blockArea, selectedPageIndex, selectedBlockIndex} = commonListProps;
    const {captchaKey} = blockArea[selectedPageIndex]?.[selectedBlockIndex] || {};

    const recaptcha = useRef(null)

    return (
        <Fragment>
           <ReCAPTCHA size='normal' width={"100%"} ref={recaptcha} sitekey={`${captchaKey || '56hff6hf56'}`} />
        </Fragment>
    );
};

export default Captcha;