import React, {Fragment} from 'react';
import {Icon} from "@shopify/polaris";
import {InfoIcon} from "@shopify/polaris-icons";
import {isContentEmpty} from "../CommonUse/CommonUse";

const FileUpload = ({commonListProps}) => {
    const {blockArea, selectedPageIndex, selectedBlockIndex} = commonListProps;
    const {title, isRequired, description, placeholder, errorMessage} = blockArea[selectedPageIndex]?.[selectedBlockIndex] || {};

    return (
        <Fragment>
            <div>
                <div className="Polaris-Labelled__LabelWrapper">
                    <div className="Polaris-Label">
                        <label className={`Polaris-Label__Text ${isRequired == 1 ? "Polaris-Label__RequiredIndicator" : ""}`}>
                            <span className="Polaris-Text--root Polaris-Text--bodyMd">{title}</span>
                        </label>
                    </div>
                </div>

                <div className="Polaris-DropZone Polaris-DropZone--hasOutline Polaris-DropZone--sizeLarge">
                    <span className="Polaris-Text--root Polaris-Text--bodySm Polaris-Text--visuallyHidden">
                      <input multiple="" type="file" autoComplete="off" readOnly/>
                    </span>
                    <div className="Polaris-DropZone__Container">
                        <div className="Polaris-DropZone-FileUpload Polaris-DropZone-FileUpload--large">
                            {
                                placeholder.trim() !== '' &&
                                <div className="Polaris-BlockStack">
                                    <div className="Polaris-DropZone-FileUpload__Action">{placeholder}</div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {errorMessage?.trim() !== '' && isRequired == 1 &&
                <div className="Polaris-Labelled__Error">
                    <div className="Polaris-InlineError">
                        <div className="Polaris-InlineError__Icon">
                            <Icon source={InfoIcon}/>
                        </div>
                        {errorMessage}
                    </div>
                </div>
                }

                {isContentEmpty(description) ? null :
                    <div className="Polaris-Labelled__HelpText">
                        <span className="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--break Polaris-Text--subdued" dangerouslySetInnerHTML={{__html: description}}/>
                    </div>
                }
            </div>
        </Fragment>
    );
};

export default FileUpload;