import React, {Fragment, useId, useState} from 'react';
import {Toast, TextField} from "@shopify/polaris";
import {Icons} from "../../Utils/Icons";

const CopyCode = ({value, disabled, label}) => {
    const [message, setMessage] = useState('');
    const firstId = useId();
    const secondId = useId();

    const onCopy = (inputID, parentID) => {
        const copyCode = document.getElementById(parentID);
        copyCode.classList.add("copy-true");
        let copyText;
        copyText = document.getElementById(inputID);
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");
        setMessage('Copied!');
        setTimeout(() => {
            copyCode.classList.remove("copy-true");
            copyText?.setSelectionRange(0, 0);
        }, 3000);
    };

    const toggleActive = () => {
        setMessage('');
    };

    return (
        <Fragment>
            {message !== "" && <Toast content={message} onDismiss={toggleActive} duration={3000}/>}
            {label &&
                <div className="Polaris-Labelled__LabelWrapper">
                    <div className="Polaris-Label">
                        <label className="Polaris-Label__Text">{label}</label>
                    </div>
                </div>
            }
            <div className="copy-code" id={`cc${firstId}copy`}>
                <TextField spellCheck={false} id={`cc${secondId}text`} value={value}
                           disabled={!value || (disabled && disabled)} readOnly type={"text"}/>
                 <button className="cc-copy" disabled={!value || (disabled && disabled)}
                                       onClick={(!value || disabled) ? null : () => onCopy(`cc${secondId}text`, `cc${firstId}copy`)}>
                        <span>{Icons.copyIcon}{Icons.copyTrueIcon}</span>
                    </button>
            </div>
        </Fragment>
    );
};

export default CopyCode;