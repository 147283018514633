import React, {Fragment} from 'react';
import {BlockStack, Box, Text, Toast} from "@shopify/polaris";
import {Icons} from "../../Utils/Icons";

const AuthFrame = ({children, title, subTitle, message,isError, active, toggleActive}) => {
    return (
        <Fragment>
            {(active && message !== '') && <Toast content={message} onDismiss={toggleActive} error={isError} duration={3500} />}

            <div className="main-auth-frame">
                <div className="auth-card">
                    <Box paddingBlockEnd={"1600"} paddingBlockStart={"1600"}>
                        <div className={"auth-sub"}>
                            <BlockStack gap={"1000"} align={"center"} inlineAlign={"center"}>
                                <Box as={"span"} width={"100%"}>
                                    <BlockStack gap={"200"}>
                                        <Box as={"span"}>{Icons.logoBlack}</Box>
                                        <Text as={"h2"} fontWeight={"medium"} variant={"headingLg"}>{title}</Text>
                                        {subTitle || ''}
                                    </BlockStack>
                                </Box>
                                {children}
                            </BlockStack>
                        </div>
                    </Box>
                </div>
            </div>
        </Fragment>
    );
};

export default AuthFrame;