import {SET_IS_LOADING_PLAN} from "../../Utils/Constent";

const initialState = true;

export default function isLoadingPlanReducer(state = initialState, action) {
    switch (action.type) {
        case SET_IS_LOADING_PLAN: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}