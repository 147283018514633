import React, {Fragment, useEffect, useState} from 'react';
import {apiService} from "../../Utils/Constent";
import {Box, Button, Card, Divider, IndexTable, InlineStack, Page, Pagination, Text, Tooltip} from "@shopify/polaris";
import moment from "moment/moment";
import {ResetIcon} from "@shopify/polaris-icons";
import EllipsisText from "../Common/EllipsisText";
import {workSpaceDetailsAction} from "../../Redux/Action/Action";
import {useDispatch, useSelector} from "react-redux";
import NotFoundContent from "../Common/NotFoundContent";

const Trash = () => {
    const [trashData, setTrashData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [pageNo, setPageNo] = useState(1);
    const [isNextPage, setIsNextPage] = useState(false);
    const [totalPage, setTotalPage] = useState("");

    const dispatch = useDispatch();
    const workSpacesAll = useSelector((state) => state.workSpaceDetails);

    useEffect(() => {
        getTrash()
    }, [pageNo])

    const getTrash = async () => {
        setIsLoading(true)
        const response = await apiService.getTrash({pageNo});
        if (response.success === true) {
            setIsLoading(false)
            setTrashData(response.data?.forms)
            setIsNextPage(response.data?.nextPage)
            setTotalPage(response.data?.totalPages)
        } else {
            setIsLoading(false)
        }
    }

    const reStore = async (id, workSpaceId) => {
        setSelectedIndex(id)
        const response = await apiService.reStore(id);
        if (response.success === true) {
            setSelectedIndex(null)
            let clone = [...trashData]
            const updatedArray = clone.filter((x) => x?.id != id)
            setTrashData(updatedArray);
            if (updatedArray.length === 0 && pageNo > 1) {
                setPageNo(1);
            }
            const updatedWorkSpaces = (workSpacesAll || []).map(x => {
                if (x.id == workSpaceId) {
                    return {
                        ...x,
                        formCount: x?.formCount + 1,
                    };
                }
                return x;
            });
            dispatch(workSpaceDetailsAction(updatedWorkSpaces));
        } else {
            setSelectedIndex(null)
        }
    }

    const resourceName = {
        singular: 'Trash',
        plural: 'Trashes',
    };

    const rowMarkup = trashData.map((x, i) => (
            <IndexTable.Row key={i}>
                <IndexTable.Cell>{<EllipsisText text={x.name}/>}</IndexTable.Cell>
                <IndexTable.Cell>{moment(x.deletedAt).format("MMM DD, YYYY")}</IndexTable.Cell>
                <IndexTable.Cell>
                    <Tooltip content={"Restore Form"} dismissOnMouseOut><Button variant={"monochromePlain"} loading={selectedIndex === x.id} icon={ResetIcon} onClick={()=> reStore(x.id, x.workSpaceId)}/></Tooltip>
                </IndexTable.Cell>
            </IndexTable.Row>
        ),
    );

    const onPrevious = () => {
        const pageNoNew = Number(pageNo) - 1 === 0 ? 1 : Number(pageNo) - 1
        setPageNo(pageNoNew)
    }

    const onNext = () => {
        setPageNo(Number(pageNo) + 1)
    }

    return (
        <Fragment>
            <Page title="Trash" subtitle={"Here you will find your deleted forms. These forms will be permanently removed after 30 days. You can easily restore them within this period."}>
                <Card padding={"0"}>
                    <IndexTable
                        emptyState={<NotFoundContent isLoading={isLoading}><Text variant={"headingMd"}>Trash is Empty</Text></NotFoundContent>}
                        resourceName={resourceName}
                        itemCount={isLoading ? null : trashData.length}
                        selectable={false}
                        loading={isLoading}
                        headings={[
                            {title: 'Form Name'},
                            {title: 'Trash Date'},
                            {title: 'Restore'},
                        ]}
                    >
                        {rowMarkup}
                    </IndexTable>
                    {
                        isLoading ? null : trashData?.length > 0 ? <Fragment>
                            <Divider/>
                            <Box padding={"400"}>
                                <InlineStack align={"end"}>
                                    <Pagination
                                        hasPrevious={pageNo !== 1}
                                        onPrevious={onPrevious}
                                        hasNext={pageNo < totalPage && isNextPage}
                                        onNext={onNext}
                                    />
                                </InlineStack>
                            </Box>
                        </Fragment> : ""
                    }
                </Card>
            </Page>
        </Fragment>
    );
};

export default Trash;