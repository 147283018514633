import {SET_USER_DETAILS} from "../../Utils/Constent";

const initialState = {};

export default function userDetailsReducer(state = initialState, action) {
    switch (action.type) {
        case SET_USER_DETAILS: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}