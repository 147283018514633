import React, {useEffect, useRef, useState} from 'react';
import { Tooltip } from "@shopify/polaris";

const EllipsisText = ({ text = '', maxWidth = 200 }) => {
    const trimmedText = typeof text === 'object' ? "" : text?.trim() ;
    const textRef = useRef(null);
    const [storeWidth, setStoreWidth] = useState('');

    useEffect(() => {
        if (textRef.current) {
            setStoreWidth(textRef.current.clientWidth);
        }
    }, [trimmedText, maxWidth]);

    return (
        <React.Fragment>
            {maxWidth > storeWidth ?
                <span ref={textRef} title={text} className="ellipsis-text">{trimmedText}</span> :
                <Tooltip dismissOnMouseOut content={text}>
                    <span ref={textRef} style={{maxWidth: `${maxWidth}px`}} className="ellipsis-text cursor-pointer">{trimmedText}</span>
                </Tooltip>
            }
        </React.Fragment>
    );
};

export default EllipsisText;
