import React, {Fragment} from 'react';
import {Button} from "@shopify/polaris";
import {baseUrl, getToken} from "../../Utils/Constent";
import {useNavigate} from "react-router-dom";

const PageNotFound = () => {
    const navigate = useNavigate();
    return (
        <Fragment>
            <div className="page-not-found">
                <h1 className="error">404</h1>
                <p className="oops">Ooops!!! The page you are looking for is not found</p>
                <Button variant={"primary"} size={"large"} onClick={() => navigate(`${baseUrl}/${getToken()?'dashboard':'login'}`)}>Back to {getToken() ? 'Dashboard' : 'Login'}</Button>
            </div>
        </Fragment>
    );
};

export default PageNotFound;