import React, {Fragment} from 'react';

const StarRatingHtml = ({commonListProps}) => {
    const {fieldType,blockArea, selectedPageIndex, selectedBlockIndex} = commonListProps;
    const {title = '', id, isRequired,errorMessage} = blockArea[selectedPageIndex]?.[selectedBlockIndex] || {};

    return (
        <Fragment>
            <div>
                {title?.trim() !== '' && <label>{title}</label>}
                <div>
                    {Array.from(Array(5)).map((_, r) => {
                        return (
                            <Fragment key={r}>
                                <input
                                    data-field={fieldType}
                                    type="radio"
                                    name={id}
                                    value={r+1}
                                    id={`${id}-${r+1}`}
                                    required={isRequired == 1}
                                    data-error={isRequired == 1 ? errorMessage : undefined}
                                />
                                <label htmlFor={`${id}-${r+1}`}>&#9734;</label>
                            </Fragment>
                        )
                    })}
                    {isRequired == 1 ? <span className="error"></span> : ""}
                </div>
            </div>
        </Fragment>
    );
};

export default StarRatingHtml;