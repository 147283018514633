import React, {Fragment} from 'react';
import {SkeletonBodyText, SkeletonDisplayText, BlockStack} from '@shopify/polaris';

export const commonParagraph = (count) => {
    return <BlockStack gap={"200"}>
        {
            Array.from(Array(count)).map((_, r) => {
                return (
                    <Fragment key={r}>
                        <SkeletonDisplayText size="medium" />
                        <SkeletonBodyText lines={6}/>
                    </Fragment>
                )
            })
        }
    </BlockStack>
}