import React, {useState, useCallback, Fragment, useEffect} from 'react';
import {Frame, TopBar, Button, Toast, SkeletonBodyText, Box, Text, Banner, Page, BlockStack, Spinner, InlineStack,} from '@shopify/polaris';
import {CashDollarIcon, DeleteIcon, ExitIcon, HomeIcon, NotificationIcon, PageIcon, SettingsIcon,PersonIcon} from '@shopify/polaris-icons';
import {useNavigate, Outlet, useLocation, useParams} from "react-router-dom";
import {apiService, baseUrl, capitalizeMessage, Copyright, getLSUserDetails, imagePath, isEmpty, removeDetails} from "../../Utils/Constent";
import logo from "../../Img/logoWhite.png";
import {loadGapiInsideDOM} from "gapi-script";
import NavigationMarkup from "./NavigationMarkup";
import qs from "qs";
import {useDispatch, useSelector} from "react-redux";
import {setIsLoadingPlan, setPlanDetails, workSpaceDetailsAction} from "../../Redux/Action/Action";
import WorkSpaceModal from "../MyWorkspace/Modals/WorkSpaceModal";


const DefaultLayout = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const UrlParams = new URLSearchParams(location.search);

    const {id, type} = useParams();
    const workSpaceTab = UrlParams.get("workSpaceTab") || 0;

    const [isWorkspaceModal, setIsWorkspaceModal] = useState(false);
    const [wpUpdateRecord, setWpUpdateRecord] = useState({});
    const [loading, setLoading] = useState(false);

    const [active, setActive] = useState(false);
    const [message, setMessage] = useState('');
    const [isError, setIsError] = useState(false);

    const [userMenuActive, setUserMenuActive] = useState(false);
    const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
    const [gapi, setGapi] = useState(null);

    const [invitationList, setInvitationList] = useState([]);

    const dispatch = useDispatch();
    const isLoadingPlan = useSelector((state) => state.isLoadingPlan);
    const planDetails = useSelector((state) => state.planDetails);
    const workSpacesAll = useSelector((state) => state.workSpaceDetails);
    const userDetailsFromRedux = useSelector((state) => state.userDetails);
    const userDetails = getLSUserDetails() || userDetailsFromRedux ;

    useEffect(() => {
        const loadGapi = async () => {
            const newGapi = await loadGapiInsideDOM();
            setGapi(newGapi);
        }
        loadGapi();
    }, []);

    useEffect(() => {
        getWorkspaceForm()
        getInvitation()
        getPlanPrice()
    }, [])

    const getPlanPrice = async () => {
        dispatch(setIsLoadingPlan(true))
        const response = await apiService.getPlanPrice();
        if (response.success === true) {
            dispatch(setIsLoadingPlan(false))
            dispatch(setPlanDetails(response.data));
        } else {
            dispatch(setIsLoadingPlan(false))
        }
    }

    useEffect(() => {
        if (window.Headway) {
            window.Headway.init({
                selector: "#wb_form_change_log",
                account:  "764o1y",
            });
        }
    }, []);

    const getWorkspaceForm = async () => {
        setLoading(true)
        const response = await apiService.getWorkspaceForm();
        if (response.success === true) {
            setLoading(false)
            dispatch(workSpaceDetailsAction(response.data));
        } else {
            setLoading(false)
        }
    }

    const getInvitation = async () => {
        const response = await apiService.getInvitation();
        if (response.success === true) {
            setInvitationList(response.data.data)
        } else {

        }
    }

    const signOut = () => {
        if (gapi && gapi.auth2) {
            const auth2 = gapi.auth2.getAuthInstance();
            if (auth2) {
                auth2.signOut().then(() => {
                    console.log('%cUser signed out', 'color: red; background-color: black; padding: 4px;');
                });
            }
        }
        removeDetails()
        navigate(`${baseUrl}/login`)
    }

    const onAcceptInvitation = async (record, status, i) => {
        const payload = {
            ...record,
            "status" : status,
        }
        const response = await apiService.acceptInvitation(payload);
        if (response.success === true) {
            let clone = [...invitationList]
            clone.splice(i, 1)
            setInvitationList(clone)
            getWorkspaceForm()
            getPlanPrice()
        } else {
            setLoading(false)
        }
    }

    const toggleUserMenuActive = useCallback(
        () => setUserMenuActive((userMenuActive) => !userMenuActive),
        [userMenuActive],
    );

    const toggleMobileNavigationActive = useCallback(
        () =>
            setMobileNavigationActive(
                (mobileNavigationActive) => !mobileNavigationActive,
            ),
        [mobileNavigationActive],
    );

    const profile = userDetails?.profileImage?.includes('googleusercontent.com') ? userDetails?.profileImage : `${imagePath}${userDetails?.profileImage}`;

    const userMenuActions = [
        {items: [{content: <InlineStack gap={"150"}>{isEmpty(userDetails?.profileImage) ?
                    <Box width={"20px"} minHeight={"20px"} borderRadius={"full"} background={"bg-fill-success"}><Text as={"span"} tone={"text-inverse"} alignment={"center"}>{`${userDetails.firstName ? userDetails.firstName[0] : '-'}`}</Text></Box> : <img width={"20px"} height={'20px'} style={{borderRadius:"50%"}} src={profile} alt={`${userDetails.firstName ? userDetails.firstName[0] : '-'}`}/> }
                    <Text as={"span"}>Profile</Text></InlineStack>, onAction: () => navigate(`${baseUrl}/settings`)}],},
        {items: [{content: 'Log Out', onAction: () => signOut(), destructive: true, icon: ExitIcon}],},
    ];

    const userMenuMarkup = (
        <TopBar.UserMenu
            customWidth={"101px"}
            actions={userMenuActions}
            name={`${userDetails.firstName || '-'} ${userDetails.lastName || ""}`}
            initials={`${userDetails.firstName ? userDetails.firstName[0] : '-'}`}
            avatar={(userDetails?.profileImage !== '' || userDetails?.profileImage !== null) ? profile : false}
            open={userMenuActive}
            onToggle={toggleUserMenuActive}
        />
    );

    const userNotifyMarkup = (
        <div className='secondaryMenuContainer'>
            {/*{isLoadingPlan || planDetails.planType == 1 ? null : <Box paddingInlineEnd={"200"}><span onClick={()=> navigate(`${baseUrl}/plan`)} className="proText" style={{backgroundColor: "#003dffcc"}}>Save 50%</span></Box>}*/}
            <Button id={"wb_form_change_log"} icon={NotificationIcon}/>
        </div>
    );

    const topBarMarkup = (
        <TopBar
            showNavigationToggle
            secondaryMenu={userNotifyMarkup}
            userMenu={userMenuMarkup}
            onNavigationToggle={toggleMobileNavigationActive}
        />
    );

    const onNavigate = (url) => {
        navigate(`${baseUrl}/${url}`)
    }

    const onNavigateWork = (prtRecord) => {
        navigate({
            pathname: `${baseUrl}/workspace/${prtRecord?.id}`,
            search: qs.stringify({workSpaceTab: Number(workSpaceTab)}),
        });
    }

    const checkPath = location.pathname || '';

    const logoDetail = {
        width: 50,
        topBarSource: logo,
        url: "/dashboard",
        accessibilityLabel: 'Web-Form',
    };

    const navigationItems = [
        {
            title: null,
            items: [
                {
                    label: 'Home',
                    icon: HomeIcon,
                    onClick: () => onNavigate('dashboard'),
                    selected: checkPath === `${baseUrl}/dashboard`,
                },
                isLoadingPlan ? {
                        label: '',
                        icon: PageIcon,
                        onClick: null,
                        selected: false,
                    } :
                    {
                        label: 'Members',
                        icon: PersonIcon,
                        onClick: () => onNavigate(planDetails.planType == 0 ? 'plan' : 'members'),
                        selected: checkPath === `${baseUrl}/members`,
                        isPlan: planDetails.planType == 0,
                    },
            ],
        },
        {
            title: 'Workspace',
            items: isLoadingPlan || loading ? Array.from(Array(6)).map((_) => ({
                label: <Box as={"li"} paddingBlockStart={"150"} ><SkeletonBodyText lines={1} /></Box>,
                icon: PageIcon,
                onClick: () => {},
                selected: false,
            })) : (workSpacesAll || []).map((x) => ({
                label: capitalizeMessage(x.name),
                icon: PageIcon,
                onClick: () => onNavigateWork(x),
                selected: id == x.id,
                formCount: x.formCount || 0,
            })),
        },
        {
            title: null,
            items: [
                {
                    label: 'Trash',
                    icon: DeleteIcon,
                    onClick: () => onNavigate('trash'),
                    selected: checkPath === `${baseUrl}/trash`,
                },
                {
                    label: 'Plan & Price',
                    icon: CashDollarIcon,
                    onClick: () => onNavigate('plan'),
                    selected: checkPath === `${baseUrl}/plan`,
                },
                {
                    label: 'Settings',
                    icon: SettingsIcon,
                    onClick: () => onNavigate('settings'),
                    selected: checkPath === `${baseUrl}/settings`,
                },
            ],
        },
    ];

    const toggleActive = useCallback(() => {
        setActive(false)
        setIsError(false)
        setMessage('')
    }, []);

    const navigationProps = {
        navigationItems,
        setIsWorkspaceModal,
        loading : isLoadingPlan || loading,
    }

    const checkRoute = type === 'form' || type === 'customize' || type === 'integrate' || type === 'submission' || type === 'thank-you';

    useEffect(() => {
        const pathParts = checkPath?.split('/');
        const title =  pathParts[checkRoute?3:1];
        if(type !== 'form' && title !== 'workspace') {
            document.title = `Web Form ${title ? `| ${capitalizeMessage(title)}` : ''}`;
        } else if(title === 'workspace') {
            const getWorkspaceTitle = () => {
                const findTitle = (workSpacesAll || []).find((x) => x.id == id)
                return  findTitle?.name || '';
            }
            document.title = `Web Form ${getWorkspaceTitle() ? `| ${capitalizeMessage(getWorkspaceTitle())}` : ''}`;
        }
    }, [checkPath, workSpacesAll]);

    const onBoarding = checkPath == `${baseUrl}/onboarding`;
    const authorize = checkPath == `${baseUrl}/authorize`;

    return (
        <Fragment>
            <Frame
                logo={onBoarding ? false : logoDetail} topBar={onBoarding ? false :topBarMarkup}
                showMobileNavigation={authorize ? false : mobileNavigationActive}
                onNavigationDismiss={authorize ? false : toggleMobileNavigationActive}
                navigation={(checkRoute || authorize || onBoarding) ? false : <NavigationMarkup {...{navigationProps}}/>}
            >
                {(active && message !== '') && <Toast content={message} onDismiss={toggleActive} error={isError} duration={3500} />}

                {/*{checkRoute || authorize || onBoarding ? null : <HeaderBanner/>}*/}

                {checkRoute || authorize ? null : invitationList.length > 0 ?
                <Page>
                    <BlockStack gap={"400"}>
                    {
                        (invitationList || []).map((x, i) => {
                            return (
                                   <Banner key={i} title={`${x.firstName} ${x.lastName} (${x.email}) invited you to join their organization`}
                                           action={{content: `Transfer to ${x.firstName}'s organization`, onAction: () => onAcceptInvitation(x, "2", i), }}
                                           secondaryAction={{content: 'Decline', onAction: () => onAcceptInvitation(x, "3", i)}}
                                   >
                                       <Text>Please note that you can only be a member of one organization at a time. Accepting this invitation will transition you to the new organization, and you will no longer have access to your current organization's workspaces and forms. However, rest assured that any forms you've created will be seamlessly transferred to the new organization and will remain visible exclusively to you.</Text>
                                   </Banner>
                            )
                        })
                    }
                    </BlockStack>
                </Page> : ""}

                {isLoadingPlan ? <div className="main_spinner"><Spinner accessibilityLabel="Spinner example" size="large"/></div> : <Outlet/>}

                <WorkSpaceModal {...{isWorkspaceModal, setIsWorkspaceModal, wpUpdateRecord, setWpUpdateRecord}}/>

                {isLoadingPlan ? null : (checkRoute || authorize || onBoarding) ? null : <footer><Text>{Copyright}</Text></footer>}
            </Frame>
        </Fragment>
    );
};

export default DefaultLayout;