import React, {Fragment, useEffect, useState} from 'react';
import {BlockStack, Box, Divider, Button, TextField, Text, ButtonGroup} from "@shopify/polaris";
import {onKeyFire} from "../../../../Utils/Constent";

const VideoStyle = ({ commonProps }) => {
    const { blockArea, selectedPageIndex, selectedBlockIndex, handleChangeStyles } = commonProps;
    const {videoURL, fieldSize} = blockArea[selectedPageIndex]?.[selectedBlockIndex] || {};

    const [error, setError] = useState('')
    const [newVideoURL, setNewVideoURL] = useState('')

    useEffect(() => {
        setNewVideoURL(videoURL)
        setError('')
    }, [videoURL, selectedPageIndex, selectedBlockIndex])

    useEffect(() => {
        setError('')
    }, [newVideoURL, selectedPageIndex, selectedBlockIndex])

    const onEmbedVideo = () => {
        if(newVideoURL.includes('vimeo.com') || newVideoURL.includes('youtube.com')|| newVideoURL.includes('youtu.be')|| newVideoURL.includes('mp4')){
            handleChangeStyles('videoURL', newVideoURL)
        } else {
            setError('Unsupported video platform');
        }
    };

    const handleChangeVideo = (value) => {
        setNewVideoURL(value)
    };

    return (
        <Fragment>
            <Box padding={"400"} paddingBlockStart={"200"}>
                <BlockStack gap={"150"}>
                    <Box as={"span"} width={"100%"} onKeyPress={(e) => onKeyFire(e, onEmbedVideo)}>
                        <TextField
                            label={'Embed Link'}
                            placeholder={"Paste the video link"}
                            value={newVideoURL}
                            onChange={(value) => handleChangeVideo(value)}
                            autoComplete="off"
                            error={error}
                        />
                    </Box>
                    <Button variant={"primary"} onClick={onEmbedVideo}>Embed Video</Button>

                    <span className="Polaris-Text--root Polaris-Text--break Polaris-Text--subdued">
                        Works with YouTube, Vimeo, MP4s and more
                    </span>
                </BlockStack>
            </Box>

            <Divider />
            <Box padding={"400"} paddingBlockStart={"200"}>
                <BlockStack gap={"100"}>
                    <Text>Size</Text>
                    <ButtonGroup variant="segmented" fullWidth>
                        {['medium', 'fullwidth'].map((x) => (
                            <Button
                                key={x} fullWidth size={"large"}
                                pressed={fieldSize === x}
                                onClick={() => handleChangeStyles("fieldSize", x,)}
                            >
                                {x.charAt(0).toUpperCase() + x.slice(1)}
                            </Button>
                        ))}
                    </ButtonGroup>
                </BlockStack>
            </Box>
            <Divider />
        </Fragment>
    );
};

export default VideoStyle;
