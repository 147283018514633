import React, {useState, useEffect, Fragment, useCallback, useMemo} from 'react';
import {Divider, BlockStack, Box, Button, Card, Layout, Page, Text, Toast, Tooltip, InlineError, Tabs, ResourceList, Avatar, InlineStack, TextField, Select,
    Combobox, Tag, AutoSelection, Listbox, EmptySearchResult, SkeletonBodyText,} from '@shopify/polaris';
import FormModal from "../MyWorkspace/Modals/FormModal";
import {useSelector} from "react-redux";
import {apiService, capitalizeMessage, emailRegExp, imagePath} from "../../Utils/Constent";
import moment from 'moment';
import {DeleteIcon} from "@shopify/polaris-icons";
import {commonParagraph} from "../../Utils/Loader";
const initialState = {
    workSpaceId: "",
    emails: "",

};
const Members = () => {
    const [selected, setSelected] = useState(0);
    const [isFormModal, setIsFormModal] = useState(false);
    const [isFormLoading, setIsFormLoading] = useState(false);
    const [value, setValue] = useState('');
    const [valueEmail, setValueEmail] = useState('');
    const [suggestion, setSuggestion] = useState('');
    const [selectedWorkSpace, setSelectedWorkSpace] = useState([]);
    const [formError, setErrors] = useState(initialState);
    const [active, setActive] = useState(false);
    const [message, setMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isMembersLoading, setIsMembersLoading] = useState(false);
    const [selectedIndexOrgMem, setSelectedIndexOrgMem] = useState(null);
    const [selectedIndexWrkMem, setSelectedIndexWrkMem] = useState(null);
    const [members, setMembers] = useState([]);
    const [workSpaceMembers, setWorkSpaceMembers] = useState([]);
    const [owner, setOwner] = useState({});
    const [selectedOrganization, setSelectedOrganization] = useState('');
    const [organization, setOrganization] = useState([]);
    const [isOrganizationLoading, setIsOrganizationLoading] = useState(false);
    const [workSpacesAll, setWorkSpacesAll] = useState([]);

    const userDetails = useSelector((state) => state.userDetails);
    const planDetails = useSelector((state) => state.planDetails);

    useEffect(() => {
        const getMember = async () => {
            setIsMembersLoading(true)
            const response = await apiService.getMember();
            if (response.success === true) {
                setIsMembersLoading(false)
                setMembers(response.data.data)
                const findOwner = response.data.data.find((x) => x.role === "owner");
                setOwner(findOwner)
            } else {
                setIsMembersLoading(false)
            }
        };
        getMember()
    },[])

    useEffect(() => {
        const getWorkspaceMember = async () => {
            setIsLoading(true)
            const response = await apiService.getWorkspaceMember();
            if (response.success === true) {
                setIsLoading(false)
                setWorkSpaceMembers(response.data.data)
            } else {
                setIsLoading(false)
            }
        };
        getWorkspaceMember()
    },[])

    useEffect(() => {
        const data = [{label: "Select organization", value: "", disabled: true}];
        planDetails.org.map((x) => {
            let obj = {
                label: `${x.firstName} ${x.lastName} Organization`,
                value: x.orgId?.toString()
            }
            data.push(obj)
        })

        setOrganization(data)
    }, [planDetails]);

    const handleTabChange = (selectedTabIndex) => {
        setSelected(selectedTabIndex)
    }

    const tabs = [
        {
            id: 'all-customers-4',
            content: 'Organization members',
            accessibilityLabel: 'All customers',
            panelID: 'all-customers-content-4',
            badge: members.filter((x) => x.firstName).length
        },
        {
            id: 'accepts-marketing-4',
            content: 'Workspaces members',
            panelID: 'accepts-marketing-content-4',
        },
        ]

    const onCloseModal = () => {
        setIsFormModal(false)
        setSelectedOrganization('')
        setValueEmail('')
        setSuggestion('');
        setValue('')
        setSelectedWorkSpace([])
        setErrors(initialState)
    }

    const formValidate = (name, value) => {
        switch (name) {
            case "workSpaceId":
                if (value.length <= 0) {
                    return "Workspace is required.";
                } else {
                    return "";
                }
            case "emails":
                if (value.length <= 0) {
                    return "Email is required.";
                } else {
                    for (let x of value) {
                        if (!x.match(emailRegExp)) {
                            return `${x} is an invalid email.`;
                        }
                    }
                    return "";
                }
            default:
                return "";
        }
    };

    const onInvite = async () => {
        const payload = {
            workSpaceId: selectedWorkSpace,
            emails: valueEmail.trim() ? valueEmail.split(",") : [],
            orgId: selectedOrganization
        }
        let errorData = {};
        Object.keys(payload).map((x) => {
            let error = formValidate(x, payload[x]);
            if (error && error.length > 0) {
                errorData[x] = error;
            }
        });
        if (Object.keys(errorData).length > 0) {
            setErrors(errorData);
            return
        }
        setIsFormLoading(true);
        const response = await apiService.memberInvite(payload);
        if (response.success === true) {
            setIsFormLoading(false);
            setMessage(capitalizeMessage(response?.message));
            setActive(true);
            onCloseModal();
        } else {
            setIsFormLoading(false);
            setActive(true);
            setMessage(response?.error?.message);
            setIsError(true);
        }
    }

    const updateSelection = useCallback(
        (selected) => {
            const nextSelectedTags = new Set([...selectedWorkSpace]);
            if (nextSelectedTags.has(selected)) {
                nextSelectedTags.delete(selected);
            } else {
                nextSelectedTags.add(selected);
            }
            setSelectedWorkSpace([...nextSelectedTags]);
            setErrors({...formError, workSpaceId: ""})
            setValue('');
            setSuggestion('');
        },
        [selectedWorkSpace],
    );
    const removeTag = useCallback(
        (tag) => () => {
            updateSelection(tag);
        },
        [updateSelection],
    );

    const verticalContentMarkup =
        selectedWorkSpace.length > 0 ? (
            <InlineStack gap={"150"}>
                {selectedWorkSpace.map((tag) => {
                    const name = workSpacesAll.find((x) => x.id === tag)
                   return (
                        <Tag key={`option-${tag}`} onRemove={removeTag(tag)}>
                            {name.name}
                        </Tag>
                    )
                })}
            </InlineStack>
        ) : null;


    const handleActiveOptionChange = useCallback(
        (activeOption) => {
            const activeOptionIsAction = activeOption === value;
            if (!activeOptionIsAction && !selectedWorkSpace.includes(activeOption)) {
                setSuggestion(activeOption);
            } else {
                setSuggestion('');
            }
        },
        [value, selectedWorkSpace],
    );

    const options = useMemo(() => {
        let list;
        const allTags = workSpacesAll;
        if (value) {
            list = allTags.filter((tag) => tag);
        } else {
            list = allTags;
        }

        return [...list];
    }, [value, workSpacesAll]);

    const optionMarkup =
        options.length > 0
            ? options.map((option) => {
                return (
                    <Listbox.Option
                        key={option.id}
                        value={option.id}
                        selected={selectedWorkSpace.includes(option.id)}
                        accessibilityLabel={option.id}
                    >
                        <Listbox.TextOption selected={selectedWorkSpace.includes(option.id)}>
                            <Text as="span">
                                {option.name}
                            </Text>
                        </Listbox.TextOption>
                    </Listbox.Option>
                );
            })
            : null;

    const emptyStateMarkup = optionMarkup ? null : (
        <EmptySearchResult
            title=""
            description={`No tags found matching "${value}"`}
        />
    );

    const listBoxMarkup =
        optionMarkup || emptyStateMarkup ? (
            <Listbox
                autoSelection={AutoSelection.None}
                onSelect={updateSelection}
                onActiveOptionChange={handleActiveOptionChange}
            >
                {optionMarkup}
            </Listbox>
        ) : null;

    const toggleActive = useCallback(() => {
        setActive(false)
        setIsError(false)
        setMessage('')
    }, []);

    const onCancelInvitation = async (payload) => {
        setSelectedIndexOrgMem(payload.id)
        const response = await apiService.cancelInvitation(payload);
        if (response.success === true){
            let clone = [...members]
            const updatedArray = clone.filter((x) => x.id != payload.id)
            setMembers(updatedArray);
            setSelectedIndexOrgMem(null)
            setMessage(capitalizeMessage(response?.message));
            setActive(true);
            setIsError(false)
        } else {
            setActive(true);
            setMessage(response?.error?.message);
            setIsError(true);
        }
    }

    const onDeleteMember = async (payload) => {
        if (payload.userId) {
            setSelectedIndexOrgMem(payload.userId)
        } else if (payload.id) {
            setSelectedIndexWrkMem(payload.id)
        }

        const response = await apiService.deleteMember(payload);
        if (response.success === true) {
            if(payload.userId){
                let clone = [...members]
                const updatedArray = clone.filter((x) => x.userId != payload.userId)
                setMembers(updatedArray);
                setSelectedIndexOrgMem(null)
            }

            if(payload.id){
                let clone = [...workSpaceMembers];
                let updatedArray = (clone || []).map(workspace => {
                    return {
                        ...workspace,
                        users: workspace.users.filter(user => user.id != payload.id)
                    };
                });
                setWorkSpaceMembers(updatedArray);
                setSelectedIndexWrkMem(null)
            }
            setMessage(capitalizeMessage(response?.message));
            setActive(true);
            setIsError(false)
        } else {
            setActive(true);
            setMessage(response?.error?.message);
            setIsError(true);
        }
    }

    const onChangeOrganization = async (value) => {
        setSelectedOrganization(value)
        setIsOrganizationLoading(true)
        const response = await apiService.getOrganization({id: value?.toString()});
        if (response.success === true) {
            setIsOrganizationLoading(false)
            setWorkSpacesAll(response.data.data)
        } else {
            setIsOrganizationLoading(false)
            setActive(true);
            setMessage(response?.error?.message);
            setIsError(true);
        }
    }

    return (
        <Fragment>
            {(active && message !== '') && <Toast content={message} onDismiss={toggleActive} error={isError}/>}

            <Page title={'Members'} primaryAction={{content: "Invite Via Email", onAction: () => setIsFormModal(true), disabled : planDetails.planType == 0}}>

                <Fragment>
                    <FormModal
                        open={isFormModal}
                        size={"base"}
                        title={'Invite Via Email'}
                        onClose={onCloseModal}
                        onContinue={onInvite}
                        loading={isFormLoading}>

                        <BlockStack gap={"400"}>
                            <Select
                                label="Select Organization"
                                options={organization} disabled={planDetails.planType == 0}
                                value={selectedOrganization}
                                onChange={(value) => onChangeOrganization(value)}
                                error={formError.organization}
                            />
                            {
                                isOrganizationLoading ? commonParagraph(1) :
                                    <Fragment>
                                        <BlockStack gap={"100"}>
                                            <Combobox
                                                allowMultipl
                                                activator={
                                                    <Combobox.TextField
                                                        autoComplete="off" disabled={selectedOrganization === '' || isOrganizationLoading || planDetails.planType == 0}
                                                        label="Workspaces"
                                                        value={value}
                                                        suggestion={suggestion}
                                                        placeholder="Select workspaces"
                                                        verticalContent={verticalContentMarkup}
                                                        onChange={setValue}
                                                    />
                                                }
                                            >
                                                {listBoxMarkup}
                                            </Combobox>
                                            <InlineError message={formError.workSpaceId}/>
                                        </BlockStack>
                                        <TextField
                                            autoComplete="off"
                                            label="Emails"
                                            value={valueEmail}
                                            placeholder="Type or paste emails separated by commas"
                                            onChange={(e) => {
                                                setValueEmail(e)
                                                setErrors({...formError, emails: ""})
                                            }}
                                            multiline={3} disabled={selectedOrganization === '' || isOrganizationLoading || planDetails.planType == 0}
                                            error={formError.emails}
                                        />
                                    </Fragment>
                            }

                        </BlockStack>
                    </FormModal>
                </Fragment>


                <Layout>
                    <Layout.Section>
                        <Card padding={"0"}>
                            <Tabs
                                tabs={tabs}
                                selected={selected}
                                onSelect={handleTabChange}
                                disclosureText="More views"
                            >
                                {
                                    selected === 0 && <Fragment>
                                        {isMembersLoading ? <Box padding={"400"}><SkeletonBodyText lines={30}/></Box> :
                                            members.length > 0 ?
                                                <ResourceList
                                                    resourceName={{singular: 'customer', plural: 'customers'}} items={members}
                                                    renderItem={(item) => {
                                                        const {id, createdAt, email, firstName, lastName, profileImage, role, teamUserId, userId, status = ''} = item;
                                                        return (
                                                            <ResourceList.Item
                                                                id={id} media={<Avatar size="md" source={profileImage ? `${imagePath}${profileImage}` : ""} name={firstName && lastName ? firstName : email} initials={firstName && lastName ? firstName.substring(0, 1).toUpperCase() : email.substring(0, 1).toUpperCase()} />}
                                                                verticalAlignment={"center"}
                                                            >
                                                                <InlineStack align={"space-between"}>
                                                                    <BlockStack gap={"50"}>
                                                                        <Text variant="headingSm" as="h6">
                                                                            {firstName && lastName ? `${firstName} ${lastName}` : email}
                                                                        </Text>
                                                                        <Text variant="bodySm" as="p" tone="subdued">
                                                                            {status == 1 ? `Pending invite since ${moment(createdAt).fromNow()}` : firstName && lastName ? email  : `Pending invite since ${moment(createdAt).fromNow()}`}
                                                                        </Text>
                                                                    </BlockStack>
                                                                    {
                                                                        role === "owner" ?  <Text variant="bodyMd" as="p" tone="subdued">
                                                                            Organization owner
                                                                        </Text> : owner?.userId === userDetails?.id  ?  <Tooltip dismissOnMouseOut content={"Remove member from the organization"}><Button tone={"critical"} disabled={planDetails.planType == 0} icon={DeleteIcon} loading={selectedIndexOrgMem === userId} onClick={() => status == 1 ? onCancelInvitation({id: id}) : onDeleteMember({userId: userId})}/> </Tooltip>: ""
                                                                    }
                                                                </InlineStack>
                                                            </ResourceList.Item>
                                                        );
                                                    }}
                                                /> : <Box padding={"400"} paddingBlockEnd={"800"}>
                                                    <BlockStack>
                                                        <Text alignment={"center"} variant={"headingMd"}>No Organization Members</Text>
                                                    </BlockStack>
                                                </Box>
                                        }
                                    </Fragment>
                                }
                                {
                                    selected === 1 && <Fragment>
                                        <BlockStack gap={"800"}>
                                            { isLoading ? <Box padding={"400"}><SkeletonBodyText lines={30}/></Box> :
                                                workSpaceMembers.length > 0 ?
                                                    (workSpaceMembers || []).map((x, i) => {
                                                        return (
                                                            <Fragment key={i}>
                                                                <BlockStack gap={"100"}>
                                                                    <Box paddingInlineStart="300">
                                                                        <Text variant="headingMd" as="h6">
                                                                            {x.workspaceTitle}
                                                                        </Text>
                                                                    </Box>
                                                                    <ResourceList
                                                                        resourceName={{singular: 'customer', plural: 'customers'}}
                                                                        items={x.users}
                                                                        renderItem={(item) => {
                                                                            const {id, createdAt, email, firstName, lastName, profileImage, role, workSpacCreator} = item;
                                                                            return (
                                                                                <ResourceList.Item id={id}
                                                                                    media={<Avatar size="md" source={profileImage ? `${imagePath}${profileImage}` : ""} name={firstName && lastName ? firstName : email} initials={firstName && lastName ? firstName.substring(0, 1).toUpperCase() : email.substring(0, 1).toUpperCase()} />}
                                                                                    verticalAlignment={"center"}
                                                                                >
                                                                                    <InlineStack align={"space-between"}>
                                                                                        <BlockStack gap={"50"}>
                                                                                            <Text variant="headingSm" as="h6">
                                                                                                {firstName && lastName ? `${firstName} ${lastName}` : email}
                                                                                            </Text>
                                                                                            <Text variant="bodySm" as="p" tone="subdued">
                                                                                                {firstName && lastName ? email : `Pending invite since ${moment(createdAt).fromNow()}`}
                                                                                            </Text>
                                                                                        </BlockStack>
                                                                                        {
                                                                                            role === "owner" ?  <Text variant="bodyMd" as="p" tone="subdued">
                                                                                                Organization owner
                                                                                            </Text> : role !== "owner" && workSpacCreator === "1" ? <Text variant="bodyMd" as="p" tone="subdued">
                                                                                                Workspace creator
                                                                                            </Text> : x.workSpaceOwnerId == userDetails?.id ? <Tooltip dismissOnMouseOut content={"Remove member from the organization"}><Button tone={"critical"} disabled={planDetails.planType == 0} loading={selectedIndexWrkMem === id} icon={DeleteIcon} onClick={() => onDeleteMember({id: id})}/> </Tooltip>: ""
                                                                                        }
                                                                                    </InlineStack>
                                                                                </ResourceList.Item>
                                                                            );
                                                                        }}
                                                                    />
                                                                    <Divider/>
                                                                </BlockStack>
                                                            </Fragment>
                                                        )
                                                    })
                                                    : <Box padding={"400"} paddingBlockEnd={"800"}>
                                                        <BlockStack>
                                                            <Text alignment={"center"} variant={"headingMd"}>No Workspaces Members</Text>
                                                        </BlockStack>
                                                    </Box>                                    }
                                        </BlockStack>
                                    </Fragment>
                                }
                            </Tabs>
                        </Card>
                    </Layout.Section>
                </Layout>
            </Page>
        </Fragment>
    );
};

export default Members;