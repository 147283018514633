import React, {Fragment, useEffect} from 'react';
import FormBanner from "../CommonUse/FormBanner";
import {RenderComponentByFieldType} from "../CommonUse/RenderComponentByFieldType";
import {Icon, Box, Text} from "@shopify/polaris";
import {ChevronLeftIcon, ChevronRightIcon} from "@shopify/polaris-icons";
import {commonParagraph} from "../../../../Utils/Loader";
import {findRef, useWindowSize} from "../CommonUse/CommonUse";
import {Copyright} from "../../../../Utils/Constent";
import {useParams} from "react-router-dom";

const MiddleBar = ({commonPropsMiddleBar}) => {
    const {
        middleRef,
        isLoading,
        isMobileView,
        formData,
        blockArea,
        onSetUpMiddle,
        selectedPageIndex,
        selectedBlockIndex,
        objColors,
        selectedHeadPageIndex,
        findMiddleRef,
    } = commonPropsMiddleBar;

    const {type} = useParams();
    const {width} = useWindowSize();

    const updateStyles = (selectors, propertyName, value) => {
        selectors.forEach(selector => {
            const elements = document.querySelectorAll(selector);
            elements.forEach(element => {
                element.style.setProperty(propertyName, value);
            });
        });
    };

    useEffect(() => {
        updateStyles(['.Polaris-Label__RequiredIndicator'], '--form-required-indicator-color', formData?.style?.globalErrorColor);
    }, [formData?.style?.globalErrorColor, blockArea]);

    useEffect(() => {
        updateStyles(['.Polaris-TextField__Input', '.Polaris-TextField__Prefix', '.Polaris-Select__Icon', '.Polaris-Select__SelectedOption', '.Polaris-DropZone-FileUpload__Action', '.PhoneInputInput', '.PhoneInputCountry .PhoneInputCountrySelectArrow'], '--form-placeholder-color', formData?.style?.globalPlaceColor);
    }, [formData?.style?.globalPlaceColor, blockArea]);

    useEffect(() => {
        updateStyles(['.Polaris-Label__Text'], '--form-label-color', formData?.style?.globalLabelColor);
    }, [formData?.style?.globalLabelColor, blockArea]);

    useEffect(() => {
        updateStyles(['.Polaris-InlineError'], '--form-error-color', formData?.style?.globalErrorColor);
    }, [formData?.style?.globalErrorColor, blockArea]);

    useEffect(() => {
        updateStyles(['.Polaris-TextField__Input', '.Polaris-Tag__TagText', '.Polaris-Choice__Label', '.Polaris-Select__Input', '.star-rating-icon', '.PhoneInputInput'], '--form-input-color', formData?.style?.globalTextColor);
    }, [formData?.style?.globalTextColor, blockArea]);

    useEffect(() => {
        updateStyles(['.Polaris-Labelled__HelpText .Polaris-Text--subdued'], '--form-help-text-color', formData?.style?.globalDescColor);
    }, [formData?.style?.globalDescColor, blockArea]);

    return (
        <Fragment>
            <div className={"middle-bar"} ref={middleRef || findMiddleRef} style={{ backgroundColor: formData?.style?.globalBg}}>
                {
                    isLoading ? <Box padding={"400"} minWidth={"100%"}>{commonParagraph(4)}</Box> :
                        <div className={`view ${(width <= 475) || isMobileView ? "mobile-view" : "desk-view"}`}>

                            {formData?.style?.bannerSize === 'fullwidth' && <FormBanner {...{formData, isMobileView}}/>}

                            <div className={"container"}  style={{margin: "auto",maxWidth: `${formData?.style?.formSize === 'small' ? '662px' : formData?.style?.formSize === 'medium' ? '998px' : '100%'}`}}>
                                {formData?.style?.bannerSize === 'container' && <FormBanner {...{formData, isMobileView}}/>}

                                {Object.keys(blockArea).map((x, pageIndex) => {
                                    const isLastIndex = pageIndex === Object.keys(blockArea).length - 1;

                                    return ((selectedHeadPageIndex === pageIndex) ?
                                        <div className={`mid-inner ${type === 'form' ? '' : 'mid-inner-customize'}`} key={pageIndex}>
                                            <div className={"w-100"} style={{display: "flex", flexWrap: "wrap"}}>
                                                {(blockArea[x] || []).map((y, blockIndex) => (
                                                    <div key={blockIndex} onClick={()=> type === 'form' ? onSetUpMiddle(pageIndex+1,blockIndex, y.fieldType, `${pageIndex}-${blockIndex}`) : {}}
                                                         ref={el => findRef(el, findMiddleRef, `${pageIndex}-${blockIndex}`)}
                                                         style={{width : (width <= 475) || isMobileView ? '100% ':`${y?.fieldSize === 'small' ? 25 : y?.fieldSize === 'medium' ? 50 : y?.fieldSize === 'large' ? 75 : y?.fieldSize === 'fullwidth' ? 100 : 100}%`}}
                                                         className={`block ${(selectedPageIndex == pageIndex+1 && selectedBlockIndex == blockIndex) ? "active" : ""}`}>
                                                        {RenderComponentByFieldType(y.fieldType, pageIndex+1, blockIndex, blockArea, formData)}
                                                    </div>
                                                ))}

                                                <div className={`caret-btn-area ${(width <= 475) || isMobileView ? 'mobile' : ''}`} style={{justifyContent: `${formData?.style?.buttonPosition}`,}}>
                                                    {pageIndex !== 0 && (
                                                            <button className={`Polaris-Button Polaris-Button--sizeLarge ${formData?.style?.buttonIcon == '1' ? "Polaris-Button--iconOnly" : ""}`} type="button" style={objColors}>
                                                                <span className="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--medium">
                                                                     {formData?.style?.buttonIcon == '1' ? <Icon source={ChevronLeftIcon} />  : formData?.style?.previousButtonText}
                                                            </span>
                                                            </button>
                                                    )}

                                                    {!isLastIndex && (
                                                            <button className={`Polaris-Button Polaris-Button--sizeLarge ${formData?.style?.buttonIcon == '1' ? "Polaris-Button--iconOnly" : ""}`} type="button" style={objColors}>
                                                                <span className="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--medium">
                                                                    {formData?.style?.buttonIcon == '1' ? <Icon source={ChevronRightIcon} />  : formData?.style?.nextButtonText}
                                                            </span>
                                                            </button>
                                                    )}
                                                    {
                                                         (isLastIndex) && (blockArea[1]?.length > 0) ?
                                                            <div className={"btn-submit"} style={{justifyContent: `${formData?.style?.buttonPosition}`}}>
                                                                <button className="Polaris-Button Polaris-Button--sizeLarge" type="button" style={objColors}><span
                                                                    className="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--medium"
                                                                >{formData?.style?.submitButtonText}</span></button>
                                                            </div>: null
                                                    }
                                                </div>
                                            </div>
                                        </div>:null
                                    )
                                })}
                            </div>
                        </div>
                }
                <footer><Text>{Copyright}</Text></footer>
            </div>
        </Fragment>
    );
};

export default MiddleBar;