import {CalendarIcon, CaretDownIcon, CheckboxIcon, ComposeIcon, EmailIcon, FaviconIcon, ImageIcon, LinkIcon,
    MenuIcon, MinusIcon, PageIcon, PhoneIcon, PlayCircleIcon, ReferralCodeIcon, SelectIcon, StarIcon, StatusActiveIcon, TextBlockIcon, TextTitleIcon, UploadIcon} from "@shopify/polaris-icons";
import {Icons} from "../../../../Utils/Icons";
import {useEffect, useState} from "react";
import {webDomain} from "../../../../Utils/Constent";


export const blockBtnArray = [
    {
        icon: MinusIcon,
        fieldType: "1",
        label: 'Single Line Input',
        desc: `Use this block to ask for any question which are one liner. For e.g Name, Email among many others where a line break is not expected.`,
    },
    {
        icon: MenuIcon,
        fieldType: "2",
        label: 'Multi Line Input',
        desc: `Unlike single line blocks, multi line blocks are used for any questions where answers are expected to have line breaks and multiple lines. For e.g User Feedback, Address etc.`,
    },
    {
        icon: SelectIcon,
        fieldType: "3",
        label: 'Multi-Select',
        desc: `Use this to allow users to select multiple answers from a list of choices.`,
    },
    {
        icon: EmailIcon,
        fieldType: "4",
        label: 'Email',
        desc: `Use this to collect correctly formatted email addresses as answers. If someone tries to add text in the wrong format, they will have to try again.`,
    },
    {
        icon: PhoneIcon,
        fieldType: "5",
        label: 'Phone Number',
        desc: `Use this block to ask for phone number.`,
    },
    {
        icon: StatusActiveIcon,
        fieldType: "6",
        label: 'Single Select Option',
        desc: `Single Select Option blocks are used in questions where multiple options are presented to users but they can choose only one out of many. For e.g the following situation:`,
    },
    {
        icon: CheckboxIcon,
        fieldType: "7",
        label: 'Multi Select Option',
        desc: `Unlike Single Select Option blocks, these blocks are used in questions where multiple options are presented to users and they can choose more than one among them. For e.g the following situation:`,
    },
    {
        icon: CaretDownIcon,
        fieldType: "8",
        label: 'Dropdown List',
        desc: `Use this select block when you have large list of data to choose from. For e.g. Select a city.`,
    },
    {
        icon: CalendarIcon,
        fieldType: "9",
        label: 'Date',
        desc: `Use this to ask user for a date. For e.g. an appointment date.`,
    },
    {
        icon: ReferralCodeIcon,
        fieldType: "10",
        label: 'Number',
        desc: ``,
    },
    {
        icon: LinkIcon,
        fieldType: "11",
        label: 'Link',
        desc: `Use this to collect correctly formatted links or websites.`,
    },
    {
        icon: StarIcon,
        fieldType: "12",
        label: 'Star Rating',
        desc: `Use star rating block to ask your users ratings about your product or service.`,
    },
    {
        icon: UploadIcon,
        fieldType: "13",
        label: 'File Upload',
        desc: `Use star rating block to ask your users ratings about your product or service.`,
    },
    {
        icon: ComposeIcon,
        fieldType: "14",
        label: 'Signature',
        desc: `Use star rating block to ask your users ratings about your product or service.`,
    },
    {
        icon: FaviconIcon,
        fieldType: "15",
        label: 'Captcha',
        desc: ``,
    },
];

export const elementBtnArray = [
    {
        icon: TextTitleIcon,
        fieldType: "16",
        label: 'Heading 1',
        desc: `Big heading suitable for a title of main sections in your form.`,
    },
    {
        icon: TextTitleIcon,
        fieldType: "17",
        label: 'Heading 2',
        desc: `Medium heading suitable for a title of smaller sections in your form.`,
    },
    {
        icon: TextTitleIcon,
        fieldType: "18",
        label: 'Heading 3',
        desc: `Small heading suitable for questions.`,
    },
    {
        icon: TextBlockIcon,
        fieldType: "19",
        label: 'Paragraph',
        desc: ``,
    },
    {
        icon: MinusIcon,
        fieldType: "20",
        label: 'Divider',
        desc: `Add a divider to separate sections in your form.`,
    },
    {
        icon: ImageIcon,
        fieldType: "21",
        label: 'Image',
        desc: `Upload an image, add an image URL, or choose from Unsplash. Use images to visualize products or to personalize your form with your visual style.`,
    },
    {
        icon: PlayCircleIcon,
        fieldType: "22",
        label: 'Video',
        desc: `Embed video’s from YouTube, Vimeo, Loom, .MP4s and more. Insert this embed block and paste the video’s URL or embed link.`,
    },
];

export const labelFieldType = {
    "1": 'Single Line Input',
    "2": 'Multi Line Input',
    "3": 'Multi Select',
    "4": 'Email',
    "5": 'Phone Number',
    "6": 'Single Select Option',
    "7": 'Multi Select Option',
    "8": 'Dropdown List',
    "9": 'Date',
    "10": 'Number',
    "11": 'Link',
    "12": 'Star Rating',
    "13": 'File Upload',
    "14": 'Signature',
    "15": 'Captcha',
    "16": 'Heading 1',
    "17": 'Heading 2',
    "18": 'Heading 3',
    "19": 'Paragraph',
    "20": 'Divider',
    "21": 'Image',
    "22": 'Video',
    default: 'Type a question'
};

export const fieldSizeFieldType = {
    "15": 'fullwidth',
    "16": 'fullwidth',
    "17": 'fullwidth',
    "18": 'fullwidth',
    "20": 'fullwidth',
    default: 'medium',
};

export const placeholderFieldType = {
    "3": 'Add options',
    "8": '-Select-',
    "11": `${webDomain}`,
    "13": 'Add files',
    "21": 'Choose File',
    default: '',
};

export const iconFieldType = {
    "1": MinusIcon,
    "2": MenuIcon,
    "3": SelectIcon,
    "4": EmailIcon,
    "5": PhoneIcon,
    "6": StatusActiveIcon,
    "7": CheckboxIcon,
    "8": CaretDownIcon,
    "9": CalendarIcon,
    "10": ReferralCodeIcon,
    "11": LinkIcon,
    "12": StarIcon,
    "13": UploadIcon,
    "14": ComposeIcon,
    "15": FaviconIcon,
    "16": TextTitleIcon,
    "17": TextTitleIcon,
    "18": TextTitleIcon,
    "19": TextBlockIcon,
    "20": MinusIcon,
    "21": ImageIcon,
    "22": PlayCircleIcon,
    default: PageIcon
};

const radioCheckOptions = [
    {label: "First Choice", value: "First Choice"},
    {label: "Second Choice", value: "Second Choice"},
    {label: "Third Choice", value: "Third Choice"},
]

export const optionsFieldType = {
    "3": radioCheckOptions,
    "6": radioCheckOptions,
    "7": radioCheckOptions,
    "8": radioCheckOptions,
    default: []
};

export const iconsRatingType = {
    "1": Icons.StarIcon,
    "2": Icons.LightningIcon,
    "3": Icons.ShieldIcon,
    "4": Icons.HeartIcon,
    "5": Icons.FlagIcon,
    "6": Icons.BulbIcon,
    default: StarIcon
};

export const allowedType = [
    '.jpg',
    '.jpeg',
    '.png',
    '.gif',
    '.svg',
    '.heic',
    '.webp',
    '.bmp',
    '.psd',
    '.mp4',
    '.mov',
    '.webm',
    '.mp3',
    '.wav',
    '.m4a',
    '.txt',
    '.csv',
    '.html',
    '.xml',
    '.pdf',
    '.doc',
    '.docx',
    '.xls',
    '.xlsx',
    '.ppt',
    '.pptx',
    '.zip',
    '.rar',
    '.json',
    '.gzip',
    '.odt',
]

export const isContentEmpty = (content) => {
    const strippedContent = content?.replace(/<[^>]*>/g, '')?.trim();
    return strippedContent === '';
};

export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return windowSize;
}

export const scrollToBottom = (ref) => {
    if (ref?.current) {
        const element = ref.current;
        setTimeout(() => {
            element.scrollTo({
                top: element.scrollHeight - element.clientHeight,
                behavior: 'smooth'
            });
        }, 0);
    }
};

export const findRef = (el, ref, index) => {
    if (!ref.current) {
        ref.current = {};
    }
    ref.current[index] = el
};

export const findRefIndex = (ref, refIndex) => {
    if (ref?.current[refIndex]) {
        const element = ref.current[refIndex];
        element.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    }
};

export const onCopy = (inputID, parentID, setMessage) => {
    const copyCode = document.getElementById(parentID);
    copyCode.classList.add("copy-true");
    const copyText = document.getElementById(inputID);
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
    setMessage('Copied!');
    setTimeout(() => {
        copyCode.classList.remove("copy-true");
        copyText.setSelectionRange(0, 0);
    }, 3000);
};