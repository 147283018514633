import React, {Fragment, useId} from 'react';

const ColorPicker = ({name, value, onChange, label, disabled}) => {
    const uniqId = useId();
    return (
        <Fragment>
            <div>
                {label && <label htmlFor={`color${uniqId}picker`} className="color-label">{label}</label>}
                <div className="color_picker">
                    <input type="color" name={name} id={`color${uniqId}picker`} value={value} onChange={(e)=> onChange(e.target.value)} disabled={disabled && disabled}/>
                    <label htmlFor={`color${uniqId}picker`}>{value}</label>
                </div>
            </div>
        </Fragment>
    );
};

export default ColorPicker;