import React, {Fragment} from 'react';
import {InlineStack, Icon} from "@shopify/polaris";
import {iconsRatingType, isContentEmpty} from "../CommonUse/CommonUse";
import {InfoIcon} from "@shopify/polaris-icons";

const StarRating = ({commonListProps}) => {
    const {blockArea, selectedPageIndex, selectedBlockIndex} = commonListProps;
    const {title, isRequired, description, ratingCount, ratingType, errorMessage} = blockArea[selectedPageIndex]?.[selectedBlockIndex] || {};

    return (
        <Fragment>
            <div className="Polaris-Labelled__LabelWrapper">
                <div className="Polaris-Label">
                    <label className={`Polaris-Label__Text ${isRequired == 1 ? "Polaris-Label__RequiredIndicator" : ""}`}>
                        <span className="Polaris-Text--root Polaris-Text--bodyMd">{title}</span>
                    </label>
                </div>
            </div>

            <InlineStack align={"start"} gap={"200"}>
                {
                    Array.from(Array( Number(ratingCount) || 0)).map((_, r) => {
                        return (
                            <span key={r} style={{width: "20px"}} className={"star-rating-icon"}>{iconsRatingType[ratingType?.toString()] || iconsRatingType.default}</span>
                        )
                    })
                }
            </InlineStack>

            {errorMessage?.trim() !== '' && isRequired == 1 &&
            <div className="Polaris-Labelled__Error">
                <div className="Polaris-InlineError">
                    <div className="Polaris-InlineError__Icon">
                        <Icon source={InfoIcon}/>
                    </div>
                    {errorMessage}
                </div>
            </div>
            }

            {isContentEmpty(description) ? null :
                <div className="Polaris-Labelled__HelpText">
                    <span className="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--break Polaris-Text--subdued" dangerouslySetInnerHTML={{__html: description}}/>
                </div>
            }

        </Fragment>
    );
};

export default StarRating;