import React, {Fragment} from 'react';
import {BlockStack, Box, Button, ButtonGroup, Divider, Text, TextField, Select} from "@shopify/polaris";
import ColorPicker from "../../../Common/ColorPicker";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';

const WithColorStyle = ({commonProps, fieldType}) => {
    const { blockArea, selectedPageIndex, selectedBlockIndex, handleChangeStyles} = commonProps;
    const {style, title, fieldSize} = blockArea[selectedPageIndex]?.[selectedBlockIndex] || {};

    const renderLabel = (typ) => {
        switch (typ) {
            case "16":
            case "17":
            case "18":
                return 'Heading';
            case "19":
                return 'Paragraph';
            default:
                return null;
        }
    };

    const DividerStyleOptions = [
        {label: 'Solid', value: 'solid'},
        {label: 'Dashed', value: 'dashed'},
        {label: 'Dotted', value: 'dotted'},
        {label: 'Double', value: 'double'},
    ]

    return (
        <Fragment>

            { fieldType == 20 ? null :
                fieldType == 19 ? <Fragment>
                    <Box padding={"400"} paddingBlockStart={"200"}>
                        <div className="Polaris-Labelled__LabelWrapper">
                            <div className="Polaris-Label">
                                <label className={`Polaris-Label__Text`}>{renderLabel(fieldType)}</label>
                            </div>
                        </div>
                        <ReactQuill
                            placeholder={"Type a description"}
                            className={`"ql-disabled"`}
                            value={title}
                            modules={{toolbar: {
                                    container: [
                                        ["bold", "italic", "underline", "strike", "blockquote","link" ],
                                        [{ header: [1, 2, 3, 4,  false] }, { color: [] }, ],
                                        [
                                            { list: "ordered" },
                                            { list: "bullet" },
                                            { indent: "-1" },
                                            { indent: "+1" },
                                            { align: [] }
                                        ],
                                    ],
                                }, clipboard: { matchVisual: false }}}
                            onChange={(newValue, delta, source) => {
                                if (source === 'user') {
                                    handleChangeStyles('title', newValue)
                                }
                            }}
                        />
                    </Box>
                </Fragment> : <Fragment>
                    <Box padding={"400"} paddingBlockStart={"200"}>
                        <TextField
                            label={renderLabel(fieldType)}
                            placeholder={"Type a text"}
                            value={title}
                            onChange={(value) => handleChangeStyles('title', value)}
                            autoComplete="off"
                        />
                    </Box>
                    <Divider />
                </Fragment>
            }

            {
                ( fieldType == 16 ||  fieldType == 17 || fieldType == 18) &&
                <Fragment>
                    <Box padding={"400"} paddingBlockStart={"200"}>
                        <BlockStack gap={"150"}>
                            <Text>Heading Alignment</Text>
                            <ButtonGroup variant="segmented" fullWidth>
                                {['left', 'center', 'right'].map((alignment) => (
                                    <Button
                                        key={alignment} fullWidth size={"large"}
                                        pressed={style?.alignment === alignment}
                                        onClick={() => handleChangeStyles("alignment", alignment, 'style')}
                                    >
                                        {alignment.charAt(0).toUpperCase() + alignment.slice(1)}
                                    </Button>
                                ))}
                            </ButtonGroup>
                            <Text tone={"subdued"}>Select how the heading text is aligned horizontally</Text>
                        </BlockStack>
                    </Box>
                    <Divider />
                </Fragment>
            }

            {
               ( fieldType == 19 || fieldType == 20) ? null : <Fragment>
                    <Box padding={"400"} paddingBlockStart={"200"}>
                        <BlockStack gap={"150"}>
                            <ColorPicker label={"Text Color"} name="text_color"
                                         value={style?.text_color}
                                         onChange={(value) => handleChangeStyles('text_color', value, 'style')}
                            />
                        </BlockStack>
                    </Box>
                </Fragment>
            }

            {
                fieldType == 20 ?  <Fragment>
                    <Box padding={"400"} paddingBlockStart={"200"}>
                        <BlockStack gap={"150"}>
                            <ColorPicker label={"Divider Color"} name="divider_color"
                                         value={style?.divider_color}
                                         onChange={(value) => handleChangeStyles('divider_color', value, 'style')}
                            />
                            <TextField
                                label={'Divider Width'}
                                placeholder={"Enter divider width"}
                                value={style?.divider_width}
                                onChange={(value) => handleChangeStyles('divider_width', value, 'style')}
                                autoComplete="off" type={"number"}
                                suffix={"PX"} min={1}
                            />
                            <Select
                                label="Divider Style"
                                options={DividerStyleOptions}
                                value={style?.divider_style}
                                onChange={(value) => handleChangeStyles('divider_style', value, 'style')}
                            />
                        </BlockStack>
                    </Box>
                </Fragment> : null
            }

            {
                fieldType == 19 ?
                <Fragment>
                    <Divider/>
                    <Box padding={"400"} paddingBlockStart={"200"}>
                        <BlockStack gap={"100"}>
                            <Text>Size</Text>
                            <ButtonGroup variant="segmented" fullWidth>
                                {['medium', 'fullwidth'].map((x) => (
                                    <Button
                                        key={x} fullWidth size={"large"}
                                        pressed={fieldSize === x}
                                        onClick={() => handleChangeStyles("fieldSize", x,)}
                                    >
                                        {x.charAt(0).toUpperCase() + x.slice(1)}
                                    </Button>
                                ))}
                            </ButtonGroup>
                        </BlockStack>
                    </Box>
                </Fragment> : null
            }
            <Divider/>
        </Fragment>
    );
};

export default WithColorStyle;