import React, {Fragment} from 'react';
import {Modal} from '@shopify/polaris';

const FormModal = ({children, open, title, onClose, onContinue, loading, size ="small"}) => {
    return (
        <Fragment>
            <Modal size={size}
                open={open}
                onClose={onClose}
                title={title}
                primaryAction={{
                    content: 'Continue',
                    onAction: onContinue,
                    loading: loading
                }}
            >
                <Modal.Section>
                    {children}
                </Modal.Section>
            </Modal>
        </Fragment>
    );
};

export default FormModal;