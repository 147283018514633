import React, {Fragment, useState, useCallback} from 'react';
import {BlockStack, TextField, InlineStack, Button, Text, Box} from '@shopify/polaris';
import {apiService, baseUrl, capitalizeMessage, emailRegExp, onKeyFire, OR} from "../../Utils/Constent";
import AuthFrame from "../AuthStructure/AuthFrame";
import {useNavigate} from "react-router-dom";
import {HideIcon, ViewIcon} from "@shopify/polaris-icons";
import WithGoogle from "./WithGoogle";
import {setUserDetails} from "../../Redux/Action/Action";
import {useDispatch} from "react-redux";

const initialState = {
    email: "",
    password: "",
};

const Login = () => {
    const navigate = useNavigate();
    const [authDetails, setAuthDetails] = useState(initialState);
    const [formError, setErrors] = useState(initialState);
    const [isLoading, setIsLoading] = useState(false);
    const [isGoogleLoading, setIsGoogleLoading] = useState(false);
    const [active, setActive] = useState(false);
    const [message, setMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [isShowPaas, setIsShowPaas] = useState(false);
    const dispatch = useDispatch();

    const formValidate = (name, value) => {
        switch (name) {
            case "email":
                if (!value.trim()) {
                    return "Email is required.";
                } else if (!value.match(emailRegExp)) {
                    return "Enter a valid email address";
                } else {
                    return "";
                }
            case "password":
                if (!value || value.trim() === "") {
                    return "Password is required.";
                } else {
                    return "";
                }
            default: {
                return "";
            }
        }
    };

    const onHandleChange = useCallback((name, value) => {
            setAuthDetails(prevTokenDetails => ({
                ...prevTokenDetails,
                [name]: value
            }));
            setErrors(formError => ({...formError, [name]: '',}));
        },
        [authDetails],
    );

    const onBlurChange = useCallback((name, value) => {
            setErrors(formError => ({
                ...formError,
                [name]: formValidate(name, value),
            }));
        },
        [authDetails],
    );

    const onNavigate = (url) => {
        navigate(`${baseUrl}/${url}`)
    }

    const handleLogin = async () => {
        let errorData = {};
        Object.keys(authDetails).map((x) => {
            let error = formValidate(x, authDetails[x]);
            if (error && error.length > 0) {
                errorData[x] = error;
            }
        });
        if (Object.keys(errorData).length > 0) {
            setErrors(errorData);
            return;
        }
        setIsLoading(true);
        const payload = {
            email: authDetails.email,
            password: authDetails.password,
        };
        const response = await apiService.login(payload);
        if (response.success === true) {
            setIsLoading(false);
            setMessage(capitalizeMessage(response?.message));
            setActive(true);
            setAuthDetails(initialState);
            setIsError(false)
            localStorage.setItem("token", response?.data?.token);
            dispatch(setUserDetails(response?.data));
            let userDetails = {...response?.data};
            delete userDetails?.token;
            localStorage.setItem("user-details", JSON.stringify(userDetails));
            if (response?.data?.enabled2fa == 1) {
                localStorage.setItem("2FA", "true");
                navigate(`${baseUrl}/verify-2fa`);
            } else {
                localStorage.setItem("2FA", "false");
                navigate(`${baseUrl}/dashboard`);
            }
        } else {
            setIsLoading(false);
            setActive(true);
            setMessage(response.error.message);
            setIsError(true);
        }
    };

    const toggleActive = useCallback(() => {
        setActive(false)
        setIsError(false)
        setMessage('')
    }, []);

    return (
        <Fragment>
            <AuthFrame title={"Sign In To Your Account"} {...{message,isError, active, toggleActive}}
                       subTitle={<Text as={"span"} tone={"subdued"}>Or <button className={"create-btn"} onClick={()=>onNavigate('register')}>Create a new account</button></Text>}>
                <Box as={"span"} width={"100%"}>
                    <BlockStack gap={"300"}>
                        <Box as={"span"} width={"100%"}>
                            <TextField
                                label={"Email"}
                                type={"email"}
                                placeholder={"Email"}
                                value={authDetails.email}
                                error={formError.email} onBlur={(e) => onBlurChange("email", e.target.value)}
                                onChange={(value) => onHandleChange("email", value)}
                            />
                        </Box>
                        <Box as={"span"} width={"100%"}>
                            <BlockStack gap={"200"}>
                                <Box as={"span"} width={"100%"} onKeyPress={(e) => onKeyFire(e, handleLogin)}>
                                    <TextField
                                        label={"Password"}
                                        placeholder={"Password"}
                                        value={authDetails.password}  type={isShowPaas ? "text" : "password"}
                                        error={formError.password} onBlur={(e) => onBlurChange("password", e.target.value)}
                                        onChange={(value) => onHandleChange("password", value)}
                                        suffix={
                                            <Box paddingBlockStart={"100"}><Button icon={!isShowPaas ? ViewIcon : HideIcon} variant={"plain"} onClick={()=>setIsShowPaas(!isShowPaas)}/></Box>
                                        }
                                    />
                                </Box>
                                <InlineStack align={"end"}><Button variant={"monochromePlain"} onClick={()=>onNavigate('forgot-password')}>Forgot password?</Button></InlineStack>
                            </BlockStack>
                        </Box>

                        <Button size={"large"} variant={"primary"}  onClick={isGoogleLoading ? null : handleLogin} loading={isLoading}>Log in</Button>
                        <Fragment>{OR}</Fragment>

                        <WithGoogle {...{text: 'Login with Google',isLoading, isGoogleLoading, setIsGoogleLoading}} />
                    </BlockStack>
                </Box>
            </AuthFrame>
        </Fragment>
    );
};

export default Login;