import React, {useState, useEffect, Fragment, useCallback} from 'react';
import {Card, Page, Text, Box, Button, Layout, InlineStack, Divider, BlockStack, Popover, Toast, ActionList, Modal, Tooltip, TextField} from "@shopify/polaris";
import {apiService, capitalizeMessage, Copyright, emailRegExp, webDomain} from "../../../../Utils/Constent";
import {useParams} from "react-router-dom";
import {ClockIcon, ConnectIcon, EditIcon, MenuVerticalIcon} from "@shopify/polaris-icons";
import EventLogTable from "./EventLogTable/EventLogTable";
import {useSelector} from "react-redux";
import emailImg from "../../../../Img/Email.png";
import sheetImg from "../../../../Img/Sheet.png";
import webhooks from "../../../../Img/webhooks.png";
import zapierLogo from "../../../../Img/zapier.webp";
import PlanBadge from "../../../Common/PlanBadge";
import {commonParagraph} from "../../../../Utils/Loader";

const CLIENT_ID = '363556015742-v6r9cohqqgckvl3h5otqp0hqge24t90m.apps.googleusercontent.com';
const API_KEY = 'AIzaSyBuMu013_FiCi8XTNP-Xd_ZYqK3IXl5hEg';
// const DISCOVERY_DOC = 'https://sheets.googleapis.com/$discovery/rest?version=v4';
// const SCOPES = 'https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/drive';


const Integrate = ({formData, formFieldsAll}) => {
    const {workspaceId, id} = useParams();
    const planDetails = useSelector((state) => state.planDetails);
    const userDetails = useSelector((state) => state.userDetails);

    const [gapiInited, setGapiInited] = useState(false);
    const [gisInited, setGisInited] = useState(false);
    const [tokenClient, setTokenClient] = useState(null);

    const [loading, setLoading] = useState('');
    const [integrationsData, setIntegrationsData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [active, setActive] = useState(false);
    const [message, setMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [webhookUrlData, setWebhookUrlData] = useState({url: "", id: ""});

    const [history, setHistory] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [isNextPage, setIsNextPage] = useState(false);
    const [totalPage, setTotalPage] = useState("");
    const [eventModalOpn, setEventModalOpn] = useState(false);
    const [selectedIntegrationId, setSelectedIntegrationId] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [popoverActive, setPopoverActive] = useState(null);

    const [isEmailModal, setIsEmailModal] = useState(false);
    const [emailData, setEmailData] = useState({url: "", id: ""});

    const togglePopoverActive = useCallback(
        (id) => setPopoverActive(prevId => prevId === id ? null : id),
        [popoverActive],
    );

    useEffect(() => {
        getIntegrations()
    }, [])

    const getIntegrations = async () => {
        setIsLoading(true)
        const response = await apiService.getIntegrations(id);
        if (response.success === true) {
            setIsLoading(false)
            setIntegrationsData(response?.data)
        } else {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (selectedIntegrationId) {
            getWebhooks(selectedIntegrationId)
        }
    }, [selectedIntegrationId, pageNo]);

    const getWebhooks = async (integrationId) => {
        setLoading('eventLog')
        setPopoverActive(null)
        const payload = {
            formId: id?.toString(),
            workSpaceId: workspaceId,
            integrationId: integrationId,
            pageNo: pageNo
        }
        const response = await apiService.getWebhooks(payload);
        if (response.success === true) {
            setLoading('')
            setHistory(response?.data?.history)
            setIsNextPage(response?.data?.nextPage)
            setTotalPage(response?.data?.totalPages)
        } else {
            setLoading('')
        }
    }

    useEffect(() => {
        const script1 = document.createElement('script');
        script1.src = 'https://apis.google.com/js/api.js';
        script1.async = true;
        script1.defer = true;
        script1.onload = gapiLoaded;
        document.body.appendChild(script1);

        const script2 = document.createElement('script');
        script2.src = 'https://accounts.google.com/gsi/client';
        script2.async = true;
        script2.defer = true;
        script2.onload = gisLoaded;
        document.body.appendChild(script2);

        return () => {
            document.body.removeChild(script1);
            document.body.removeChild(script2);
        };
    }, []);

    const gapiLoaded = () => {
        window.gapi.load('client', initializeGapiClient);
    };

    const initializeGapiClient = async () => {
        await window.gapi.client.init({
            apiKey: API_KEY,
            discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
                'https://www.googleapis.com/discovery/v1/apis/sheets/v4/rest'],
            scope: 'https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/drive.file'
        });
        setGapiInited(true);
        maybeEnableButtons();
    };

    const gisLoaded = () => {
        const tokenClient = window.google.accounts.oauth2.initTokenClient({
            client_id: CLIENT_ID,
            scope: 'https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/drive.file',
            callback: '',
        });
        setTokenClient(tokenClient);
        setGisInited(true);
        maybeEnableButtons();
    };

    const maybeEnableButtons = () => {
        const authorizeButton = document.getElementById('authorize_button');
        if (authorizeButton && gapiInited && gisInited) {
            authorizeButton.style.visibility = 'visible';
        }
    };

    const handleAuthClick = () => {
        if (!tokenClient) return;
        tokenClient.callback = async (resp) => {
            if (resp.error !== undefined) {
                throw (resp);
            }
            await createNewSheet();
        };

        if (window.gapi.client.getToken() === null) {
            tokenClient.requestAccessToken({prompt: 'consent'});
        } else {
            tokenClient.requestAccessToken({prompt: ''});
        }
    };

    const createNewSheet = async () => {
        setLoading("2")
        let response;
        try {
            const sheetHeader = [{userEnteredValue: {stringValue: "Submitted at"}}];
            const ids = [];
            formFieldsAll.map((y, i) => {
                if (y.fieldType <= 14) {
                    const obj = {
                        userEnteredValue: {stringValue: y.title},
                    }
                    sheetHeader.push(obj)
                    ids.push(`${y.id}:${y.title}`)
                }

            })
            const request = {
                properties: {
                    title: formData.name, // Specify the title of the spreadsheet
                },
                sheets: [
                    {
                        properties: {
                            title: "Sheet1", // Specify the title of the sheet
                            gridProperties: {
                                rowCount: 10000, // Set the number of rows
                                columnCount: 1000 // Set the number of columns
                            }
                        },
                        data: [
                            {
                                startRow: 0, // Start row index for the data (optional)
                                startColumn: 0, // Start column index for the data (optional)
                                rowData: [
                                    {
                                        values: sheetHeader,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            };

            response = await window.gapi.client.sheets.spreadsheets.create({resource: request});
            const sheetId = response.result.sheets[0].properties.sheetId;
            const spreadsheetId = response.result.spreadsheetId;
            const metadataRequest = {
                spreadsheetId: spreadsheetId,
                requests: [
                    {
                        createDeveloperMetadata: {
                            developerMetadata: {
                                location: {
                                    sheetId: sheetId,
                                },
                                visibility: "DOCUMENT",
                                metadataKey: "FormBuilder",
                                metadataValue: ids.join(","),
                            },
                        },
                    },
                ],
            };
            await window.gapi.client.sheets.spreadsheets.batchUpdate(metadataRequest);
            await window.gapi.client.drive.permissions.create({
                fileId: spreadsheetId,
                fields: 'id',
                resource: {
                    type: 'anyone',
                    role: 'writer',
                }
            });
            const url = response.result;
            onConnect("2", spreadsheetId, url.spreadsheetUrl)
        } catch (err) {
            document.getElementById('content').innerText = err.message;
            return;
        }
    };

    const onConnect = async (type, googleSheetId = "", googleSheetUrl = "", recordData = "", recordUpdateId = '') => {
        const showError = (message) => {
            setActive(true);
            setIsError(true);
            setMessage(message);
        };

        if (type == '5' && !recordData.match(/^https?:\/\//)) {
            return showError('Please enter a valid link');
        }

        if (type == '1') {
            if (!recordData.trim()) {
                return showError("Email is required.");
            }
            if (!recordData.match(emailRegExp)) {
                return showError("Enter a valid email address");
            }
        }

        const payload = {
            type: type?.toString(),
            formId: id?.toString(),
            workSpaceId: workspaceId?.toString(),
            googleSheetId: googleSheetId,
            googleSheetUrl: googleSheetUrl,
            webhookUrl: type == '5' ? recordData : '',
            id: recordUpdateId?.toString() || "",
            email: type == '1' ? recordData : '',
        }
        setLoading(type)
        const response = await apiService.connectIntegration(payload);
        if (response.success === true) {
            setLoading('')
            setMessage(capitalizeMessage(response?.message));
            setActive(true);
            setIsError(false)
            onCloseAllModal()
            setPopoverActive(null)
            await getIntegrations()
        } else {
            setLoading('')
            setActive(true);
            setMessage(response?.error?.message);
            setIsError(true);
            onCloseAllModal()
        }
    }

    const onCloseAllModal = () => {
        setIsModal(false)
        setIsEmailModal(false)
        setWebhookUrlData({...webhookUrlData, id: ''})
        setEmailData({...emailData, id: ''})
    }

    const onDisconnect = async (type, loader) => {
        setLoading(loader)
        const response = await apiService.disconnectIntegration(type);
        if (response.success === true) {
            setLoading('')
            setMessage(capitalizeMessage(response?.message));
            setActive(true);
            setIsError(false)
            setPopoverActive(null)
            await getIntegrations()
        } else {
            setLoading('')
            setActive(true);
            setMessage(response?.error?.message);
            setIsError(true);
        }
    }

    const onWindow = (url) => {
        window.open(url, "_blank")
    }

    const integrationTypeExists = (type) => integrationsData?.some((x) => x?.integrationType == type);

    const integrationExistsId = (type) => {
        const integration = integrationsData?.find(x => x?.integrationType == type);
        return integration?.id || '';
    };

    const findUrl = (type) => {
        const matchingIntegrations = integrationsData?.filter((x) => x.integrationType == type) || [];
        const lastMatch = matchingIntegrations?.pop();
        return lastMatch?.[type == '1' ? 'email' : type == '2' ? 'googleSheetUrl' : type == '5' ? 'webhookUrl' : type == '7' ? 'zapierWebhook' : ''] || '';
    };

    const findId = (type) => {
        const matchingIntegrations = integrationsData?.filter((x) => x.integrationType == type) || [];
        const lastMatch = matchingIntegrations?.pop();
        return lastMatch?.['id'] || '';
    };

    const allOfThem = [
        {
            id: integrationExistsId(1),
            integrationType: 1,
            eventLog: false,
            img: emailImg,
            label: 'Email',
            desc: integrationTypeExists('1') ? findUrl('1') : 'Get each submission in your inbox as soon as they are submitted.',
            loading: loading == '1',
            connect: integrationTypeExists('1'),
            onClick: () => {
                integrationTypeExists('1') ? onDisconnect(integrationExistsId('1'), '1') : onMail('create');
            },
        },
        {
            id: integrationExistsId(2),
            integrationType: 2,
            eventLog: false,
            isPlan: planDetails.planType == 0,
            img: sheetImg,
            label: 'Google Sheets',
            desc: integrationTypeExists('2') ? <Fragment>Submissions are synced with this <Button variant={"plain"}
                                                                                                  onClick={() => onWindow(findUrl('2'))}>spreadsheet</Button></Fragment> : 'Get each submission in your Google Sheets as soon as they are submitted.',
            loading: loading == '2',
            connect: integrationTypeExists('2'),
            onClick: () => {
                integrationTypeExists('2') ? onDisconnect(integrationExistsId('2'), '2') : handleAuthClick();
            },
        },
        {
            id: integrationExistsId(5),
            integrationType: 5,
            eventLog: true,
            isPlan: planDetails.planType == 0,
            img: webhooks,
            label: 'Webhook',
            desc: integrationTypeExists('5') ? findUrl('5') : 'Send events for new submissions to HTTP endpoints',
            loading: loading == '5',
            connect: integrationTypeExists('5'),
            onClick: () => {
                integrationTypeExists('5') ? onDisconnect(integrationExistsId('5'), '5') : onHook('create');
            },
        },
        {
            id: integrationExistsId(7),
            integrationType: 7,
            eventLog: false,
            isPlan: planDetails.planType == 0,
            img: zapierLogo,
            label: 'Zapier',
            desc: 'Send submissions to your favorite tools',
            loading: false,
            connect: findUrl('7'),
            onClick: () => window.open('https://zapier.com/apps/webform/integrations', '_blank'),
            buttonText: findUrl('7') ? "Connected" : "Connect"
        },
    ];

    const onEventLog = (integrationId, label) => {
        setModalTitle(label)
        getWebhooks(integrationId)
        setSelectedIntegrationId(integrationId)
        setEventModalOpn(true)
    }

    const onCloseEventModal = () => {
        setEventModalOpn(false)
        setPageNo(1)
        setSelectedIntegrationId('')
    }

    const toggleActive = useCallback(() => {
        setActive(false)
        setIsError(false)
        setMessage('')
    }, []);

    const onHook = (type = '') => {
        setIsModal(true)
        if (type === 'create') {
            setWebhookUrlData({...webhookUrlData, url: '', id: ''})
        } else {
            setWebhookUrlData({...webhookUrlData, url: findUrl('5'), id: findId('5')})
        }
    }

    const onMail = (type = '') => {
        setIsEmailModal(true)
        if (type === 'create') {
            setEmailData({...emailData, url: userDetails?.email, id: ''})
        } else {
            setEmailData({...emailData, url: findUrl('1'), id: findId('1')})
        }
    }

    return (
        <Fragment>
            {(active && message !== '') && <Toast content={message} onDismiss={toggleActive} error={isError}/>}
            <Fragment>

                <Fragment>
                    {
                        isEmailModal &&
                        <Modal open={isEmailModal} title={"Enter a Email to Receive Form Submission Notifications"}
                               onClose={loading == '1' ? null : onCloseAllModal}
                               primaryAction={{
                                   content: emailData?.id ? "Update" : 'Save',
                                   onAction: () => onConnect('1', "", "", emailData?.url, emailData?.id),
                                   loading: loading == '1', disabled: emailData?.url?.trim() === ""
                               }}
                               secondaryActions={[{
                                   content: 'Cancel',
                                   onAction: loading == '1' ? null : onCloseAllModal
                               }]}
                        >
                            <Modal.Section>
                                <TextField label={"Email address"} placeholder={`Enter email`} value={emailData?.url}
                                           onChange={(value) => setEmailData({...emailData, url: value})}
                                           type={"email"}/>
                            </Modal.Section>
                        </Modal>
                    }
                </Fragment>

                <Fragment>
                    {
                        isModal &&
                        <Modal open={isModal} title={"Add a webhook endpoint"}
                               onClose={loading == '5' ? null : onCloseAllModal}
                               primaryAction={{
                                   content: webhookUrlData?.id ? "Update" : 'Save',
                                   onAction: () => onConnect('5', "", "", webhookUrlData?.url, webhookUrlData?.id),
                                   loading: loading == '5', disabled: webhookUrlData?.url?.trim() === ""
                               }}
                               secondaryActions={[{
                                   content: 'Cancel',
                                   onAction: loading == '5' ? null : onCloseAllModal,
                               }]}
                        >
                            <Modal.Section>
                                <TextField label={"Endpoint URL"} placeholder={`${webDomain}/webhooks`}
                                           value={webhookUrlData?.url}
                                           onChange={(value) => setWebhookUrlData({...webhookUrlData, url: value})}
                                           type={"url"}/>
                            </Modal.Section>
                        </Modal>
                    }
                </Fragment>

                <Fragment>
                    {eventModalOpn &&
                        <Modal open={eventModalOpn} title={`${modalTitle} Event Logs`} size={"large"}
                               onClose={onCloseEventModal}>
                            <Modal.Section>
                                <EventLogTable {...{
                                    loading,
                                    history,
                                    setLoading,
                                    setHistory,
                                    setMessage,
                                    setIsError,
                                    setActive,
                                    pageNo,
                                    totalPage,
                                    isNextPage,
                                    setPageNo,
                                    modalTitle
                                }}/>
                            </Modal.Section>
                        </Modal>
                    }
                </Fragment>
            </Fragment>

            <div className={"page-style"}>
                <Page title="Integrate form to your favorite tools">
                    <Layout>
                        <Layout.Section>
                            <Card>
                                {isLoading ? commonParagraph(2) :
                                    (allOfThem || []).map((x, i) => {
                                        return (
                                            <Fragment key={i}>
                                                <InlineStack blockAlign={"center"} gap={"300"} align={"space-between"}
                                                             wrap={false}>
                                                    <InlineStack blockAlign={"center"} gap={"300"} wrap={false}>
                                                    <span className="form-thumbnail">
                                                        <img src={x.img} alt={'img'}
                                                             style={{maxWidth: x.label === 'Webhook' ? "" : "87%"}}/>
                                                    </span>
                                                        <BlockStack>
                                                            <InlineStack align={"start"} gap={"150"}><Text fontWeight={"semibold"}>{x.label}</Text> {x.connect ?
                                                                <Tooltip content={`Connected`}>
                                                                    <Box width={"8px"} minHeight={"8px"} background={"bg-fill-success"} borderRadius={"full"}/></Tooltip> : ""}
                                                                {x.isPlan ? <PlanBadge/> : ""}
                                                            </InlineStack>
                                                            <Text tone={"subdued"}>{x.desc}</Text>
                                                        </BlockStack>
                                                    </InlineStack>
                                                    <Popover
                                                        active={popoverActive === i}
                                                        activator={<Tooltip
                                                            active={popoverActive === i ? false : ""} content={'Actions'}
                                                            dismissOnMouseOut><Button variant={"plain"} icon={MenuVerticalIcon} onClick={() => togglePopoverActive(i)}/></Tooltip>}
                                                        autofocusTarget="first-node"
                                                        onClose={() => togglePopoverActive(i)}
                                                    >
                                                        <ActionList
                                                            actionRole="menuitem"
                                                            items={[
                                                                {
                                                                    content: x.buttonText ? x.buttonText : x.connect ? 'Disconnect' : 'Connect',
                                                                    icon: ConnectIcon,
                                                                    onAction: (isLoading || x.isPlan || loading == x.integrationType) ? null : x.onClick,
                                                                    disabled: (isLoading || x.isPlan),
                                                                    destructive: x.connect
                                                                },
                                                                (x.eventLog && x.connect) ? {
                                                                    content: 'Event log',
                                                                    icon: ClockIcon,
                                                                    disabled: loading == x.integrationType,
                                                                    onAction: () => onEventLog(x.id, x.label)
                                                                } : "",
                                                                ((x.integrationType == 1 && x.connect) || (x.integrationType == 5 && x.connect)) ? {
                                                                    content: 'Edit',
                                                                    icon: EditIcon,
                                                                    disabled: loading == x.integrationType,
                                                                    onAction: x.integrationType == 1 ? onMail : onHook
                                                                } : ""
                                                            ]}
                                                        />
                                                    </Popover>
                                                </InlineStack>
                                                {i !== allOfThem?.length - 1 &&
                                                    <Box paddingBlock={"400"}><Divider/></Box>}
                                            </Fragment>
                                        )
                                    })
                                }
                            </Card>
                        </Layout.Section>
                    </Layout>
                </Page>
                <footer><Text>{Copyright}</Text></footer>
            </div>
        </Fragment>
    );
};

export default Integrate;