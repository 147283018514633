import { SET_WORKSPACE } from "../../Utils/Constent";

const initialState = [];

export default function workSpaceDetailsReducer(state = initialState, action) {
    switch (action.type) {
        case SET_WORKSPACE: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}