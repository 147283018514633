import React, {Fragment, useState} from 'react';
import {BlockStack, Box, Button, InlineStack, Select, Text, Thumbnail} from "@shopify/polaris";
import {Icons} from "../../Utils/Icons";
import Facebook from "../../Img/share-facebook.png";
import Instagram from "../../Img/instagram.png";
import X from "../../Img/share-x.png";
import LinkedIn from "../../Img/share-linkedin.png";

const OnBoarding = () => {
    const [step, setStep] = useState(1);
    const [industry, setIndustry] = useState('Engineering & Technology');
    const [responsibility, setResponsibility] = useState('Business Owner');
    const [aboutWebForm, setAboutWebForm] = useState('Blog article');

    const onStep = (stepType) => {
        setStep(stepType)
    }

    const handleSelectChange = (value, type) => {
        if (type === 'ind') {
            setIndustry(value)
        } else if (type === 'res') {
            setResponsibility(value)
        } else if (type === 'web') {
            setAboutWebForm(value)
        }
    }

    const optionsIndustry = [
        {label: 'Engineering & Technology', value: 'Engineering & Technology'},
        {label: 'Creative & Design', value: 'Creative & Design'},
        {label: 'Sales & Business Development', value: 'Sales & Business Development'},
        {label: 'Marketing & Communications', value: 'Marketing & Communications'},
        {label: 'Customer Support & Success', value: 'Customer Support & Success'},
        {label: 'Operations & Logistics', value: 'Operations & Logistics'},
        {label: 'Human Resources & People Management', value: 'Human Resources & People Management'},
        {label: 'Information Technology & Software', value: 'Information Technology & Software'},
        {label: 'Finance & Accounting', value: 'Finance & Accounting'},
        {label: 'Healthcare & Wellness', value: 'Healthcare & Wellness'},
        {label: 'Other(Please specify)', value: 'Other(Please specify)'},
    ];

    const optionsResponsibility = [
        {label: 'Business Owner', value: 'Business Owner'},
        {label: 'Freelancer', value: 'Freelancer'},
        {label: 'Team Lead', value: 'Team Lead'},
        {label: 'Product Manager', value: 'Product Manager'},
        {label: 'Department lead', value: 'Department lead'},
        {label: 'Team manager', value: 'Team manager'},
        {label: 'Team member', value: 'Team member'},
        {label: 'Using for myself', value: 'Using for myself'},
        {label: 'Other (please specify)', value: 'Other (please specify)'},
    ];

    const optionsWebForm = [
        {label: 'Blog article', value: 'Blog article'},
        {label: 'Search engine', value: 'Search engine'},
        {label: 'Facebook post', value: 'Facebook post'},
        {label: 'Recommendation from a friend or colleague', value: 'Recommendation from a friend or colleague'},
        {label: 'You tube video', value: 'You tube video'},
        {label: 'Reddit discussion', value: 'Reddit discussion'},
        {label: 'LinkedIn post', value: 'LinkedIn post'},
        {label: 'Twitter mention', value: 'Twitter mention'},
        {label: 'Product Hunt feature', value: 'Product Hunt feature'},
        {label: 'Email newsletter', value: 'Email newsletter'},
        {label: 'Other', value: 'Other'},
    ];

    const onWindowOpen = (url) => {
        window.open(`${url}`, "_blank")
    }

    const shareArray = [
        {
            img: Instagram,
            alt: "Instagram",
            onClick : () => onWindowOpen(`https://www.instagram.com`),
        },
        {
            img: LinkedIn,
            alt: "LinkedIn",
            onClick : () => onWindowOpen(`https://www.linkedin.com/shareArticle?url=`),
        },
        {
            img: X,
            alt: "X",
            onClick : () => onWindowOpen(`https://twitter.com/intent/post?url=`),
        },
        {
            img: Facebook,
            alt: "Facebook",
            onClick : () => onWindowOpen(`https://www.facebook.com/sharer/sharer.php?u=`),
        },
    ]

    return (
        <Fragment>
            <div className="on-boarding">
                <div className="">
                    <Box maxWidth={step != 3 ? "606px" : "658px"} padding={"400"}>
                        <InlineStack align={"center"}><Box paddingBlockEnd={"1200"}>{Icons.logoWithName}</Box></InlineStack>

                        {
                            step == 1 &&
                            <Fragment>
                                <BlockStack as={"span"} gap={"600"} align={"center"} inlineAlign={"center"}>
                                    <BlockStack as={"span"} gap={"200"} inlineAlign={"center"}>
                                        <Text variant={"heading2xl"}><Text as={"span"} variant={"heading2xl"}>👋</Text>Welcome</Text>
                                        <Text alignment={"center"} variant={"headingMd"} fontWeight={"regular"}>
                                            Welcome to Webform, your go-to tool for creating dynamic forms with unlimited
                                            possibilities.
                                        </Text>
                                    </BlockStack>
                                    <Box>
                                        <Button variant={"primary"} size={"large"} onClick={()=> onStep(2)}>Let’s get started</Button>
                                    </Box>
                                </BlockStack>
                            </Fragment>
                        }

                        {
                            step == 2 &&
                            <Fragment>
                                <BlockStack as={"span"} gap={"600"} align={"center"} inlineAlign={"center"}>
                                    <BlockStack as={"span"} gap={"200"}>
                                        <Text variant={"heading2xl"}>We’d Like to Get to Know You</Text>
                                        <Text alignment={"center"} variant={"headingMd"} fontWeight={"regular"}>
                                            Tell us a bit about yourself! We’d love to get to know you better.
                                        </Text>
                                    </BlockStack>

                                    <Box minWidth={"70%"}>
                                        <BlockStack as={"span"} gap={"600"}>
                                            <Select
                                                label="What industry do you work in?"
                                                options={optionsIndustry}
                                                onChange={(val) => handleSelectChange(val, "ind")}
                                                value={industry}
                                            />
                                            <Select
                                                label="What’s your current role or responsibility?"
                                                options={optionsResponsibility}
                                                onChange={(val) => handleSelectChange(val, "res")}
                                                value={responsibility}
                                            />
                                            <Select
                                                label="How did you get to know about Webform?"
                                                options={optionsWebForm}
                                                onChange={(val) => handleSelectChange(val, "web")}
                                                value={aboutWebForm}
                                            />
                                        </BlockStack>
                                    </Box>
                                    <Box>
                                        <Button variant={"primary"} size={"large"} onClick={()=> onStep(3)}>Continue</Button>
                                    </Box>
                                </BlockStack>
                            </Fragment>
                        }

                        {
                            step == 3 &&
                            <Fragment>
                                <BlockStack as={"span"} gap={"600"} align={"center"} inlineAlign={"center"}>
                                    <InlineStack align={"center"} as={"span"} gap={"100"} wrap={false}>
                                        <Text as={"span"}>{Icons.onThankYou}</Text>
                                        <Text variant={"heading2xl"}>Thank You for Signing Up!</Text>
                                    </InlineStack>

                                    <BlockStack as={"span"} gap={"150"}>
                                        <Text alignment={"center"} variant={"headingMd"} fontWeight={"regular"}>
                                            We’re excited to have you on board.
                                        </Text>
                                        <Text alignment={"center"} variant={"headingMd"} fontWeight={"regular"}>
                                            Your journey with Webform starts now! Here’s what you can do next:
                                        </Text>
                                    </BlockStack>

                                    <Box paddingBlock={"200"}>
                                        <BlockStack as={"span"} gap={"150"}>
                                            <Text alignment={"center"} variant={"headingMd"} fontWeight={"regular"}>
                                                <span className={"text-success"}><span className={"d-next"}>{Icons.next}</span> Create your first form</span> and start gathering responses.
                                            </Text>
                                            <Text alignment={"center"} variant={"headingMd"} fontWeight={"regular"}>
                                                <span className={"text-success"}><span className={"d-next"}>{Icons.next}</span> Explore our templates</span> to find the perfect fit for your needs.
                                            </Text>
                                            <Text alignment={"center"} variant={"headingMd"} fontWeight={"regular"}>
                                                <span className={"text-success"}><span className={"d-next"}>{Icons.next}</span> Upgrade your plan</span> to unlock unlimited features and workspaces.
                                            </Text>
                                        </BlockStack>
                                    </Box>

                                    <BlockStack as={"span"} gap={"150"}>
                                        <Text alignment={"center"} variant={"headingMd"} fontWeight={"regular"}>
                                            Need help? Check out our help center or contact our 24/7 support team.
                                        </Text>
                                        <Text alignment={"center"} variant={"headingMd"} fontWeight={"regular"}>
                                            Follow us on social media for the latest updates and tips!
                                        </Text>
                                    </BlockStack>

                                    <Box paddingBlockEnd={"100"} paddingBlockStart={"200"}>
                                        <InlineStack gap={"300"}>
                                            {
                                                shareArray.map((x,i) => {
                                                    return (
                                                        <Button key={i} variant={"monochromePlain"} onClick={x.onClick}>
                                                            <span title={x.alt}><Thumbnail source={x.img} size="small" alt={x.alt}/></span>
                                                        </Button>
                                                    )
                                                })
                                            }
                                        </InlineStack>
                                    </Box>

                                    <Box paddingBlockEnd={"150"}>
                                        <Text alignment={"center"} variant={"headingMd"} fontWeight={"regular"}>Happy form building</Text>
                                    </Box>

                                    <InlineStack gap={"400"}>
                                        <Button variant={"primary"} size={"large"} onClick={()=> onStep(3)}>Create First Form</Button>
                                        <Button variant={"primary"} size={"large"} onClick={()=> onStep(3)}>&nbsp; &nbsp; &nbsp; Dashboard &nbsp; &nbsp; &nbsp;</Button>
                                    </InlineStack>
                                </BlockStack>
                            </Fragment>
                        }
                    </Box>
                </div>
            </div>
        </Fragment>
    );
};

export default OnBoarding;