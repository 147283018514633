import React, {Fragment, useCallback, useState} from 'react';
import AuthFrame from "../AuthStructure/AuthFrame";
import {BlockStack, Button, Icon, InlineStack, Text, TextField, Box} from "@shopify/polaris";
import {useNavigate} from "react-router-dom";
import {ArrowLeftIcon} from "@shopify/polaris-icons";
import {apiService, baseUrl, capitalizeMessage, onKeyFire} from "../../Utils/Constent";

const initialState = {
    password: "",
    re_password: "",
};

const ResetPassword = () => {
    const navigate = useNavigate();
    const UrlParams = new URLSearchParams(window.location.search);
    const token = UrlParams.get("token") || '';

    const [authDetails, setAuthDetails] = useState(initialState);
    const [formError, setErrors] = useState(initialState);
    const [isLoading, setIsLoading] = useState(false);
    const [active, setActive] = useState(false);
    const [message, setMessage] = useState('');
    const [isError, setIsError] = useState(false);

    const onNavigate = (url) => {
        navigate(`${baseUrl}/${url}`)
    }

    const formValidate = (name, value) => {
        switch (name) {
            case "password":
                if (!value || value.trim() === "") {
                    return "Password is required.";
                } else if (value.length < 8) {
                    return "Password must be 8 character."
                } else {
                    return "";
                }
            case "re_password":
                if (value !== authDetails.password) {
                    return "Password doesn't match.";
                } else {
                    return "";
                }
            default: {
                return "";
            }
        }
    };

    const onHandleChange = useCallback((name, value) => {
            setAuthDetails(prevTokenDetails => ({
                ...prevTokenDetails,
                [name]: value
            }));
            setErrors(formError => ({...formError, [name]: '',}));
        },
        [authDetails],
    );

    const onBlurChange = useCallback((name, value) => {
            setErrors(formError => ({
                ...formError,
                [name]: formValidate(name, value),
            }));
        },
        [authDetails],
    );

    const onReset = async () => {
        let errorData = {};
        Object.keys(authDetails).map((x) => {
            let error = formValidate(x, authDetails[x]);
            if (error && error.length > 0) {
                errorData[x] = error;
            }
        });
        if (Object.keys(errorData).length > 0) {
            setErrors(errorData);
            return;
        }
        setIsLoading(true);

        const payload = {
            password: authDetails.password,
            token: token
        };

        const response = await apiService.resetPassword(payload);
        if (response.success === true) {
            setIsLoading(false);
            setMessage(capitalizeMessage(response?.message));
            setActive(true);
            setAuthDetails(initialState);
            setIsError(false)
            setTimeout(() => {
                navigate(`${baseUrl}/login`);
            },4000)
        } else {
            setIsLoading(false);
            setActive(true);
            setMessage(response.error.message);
            setIsError(true);
        }
    }

    const toggleActive = useCallback(() => {
        setActive(false)
        setIsError(false)
        setMessage('')
    }, []);


    return (
        <Fragment>
            <AuthFrame title={"Reset Password"} {...{message,isError, active, toggleActive}}>
                <Box width={"100%"}>
                    <BlockStack gap={"300"}>
                        <Box as={"span"} width={"100%"}>
                            <TextField
                                label={"New Password"}
                                placeholder={"New password"}
                                value={authDetails.password}
                                error={formError.password}
                                onBlur={(e) => onBlurChange("password", e.target.value)}
                                onChange={(value) => onHandleChange("password", value)}
                            />
                        </Box>

                        <Box as={"span"} width={"100%"} onKeyPress={(e) => onKeyFire(e, onReset)}>
                            <TextField
                                label={"Re-enter Password"}
                                placeholder={"Re-enter password"}
                                value={authDetails.re_password}
                                error={formError.re_password}
                                onBlur={(e) => onBlurChange("re_password", e.target.value)}
                                onChange={(value) => onHandleChange("re_password", value)}
                            />
                        </Box>

                        <Box as={"span"}/>
                        <Button size={"large"} variant={"primary"} onClick={onReset} loading={isLoading}>Reset Password</Button>

                        <button className={"back-log-btn"} onClick={() => onNavigate('login')}>
                            <InlineStack blockAlign={"center"} gap={"150"} as={"span"}>
                                <Icon source={ArrowLeftIcon} />
                                <Text as={"span"} fontWeight={"medium"}>Return to login</Text>
                            </InlineStack>
                        </button>

                    </BlockStack>
                </Box>
            </AuthFrame>
        </Fragment>
    );
};

export default ResetPassword;