import React, {Fragment, useEffect, useRef, useState} from 'react';
import { Modal, Toast } from '@shopify/polaris';
import { commonParagraph } from '../../../../Utils/Loader';
import { Editor } from '@monaco-editor/react';
import ReactDOMServer from 'react-dom/server';
import CommonHtmlInput from "./SubUse/CommonHTMLInput";
import SelectHtmlOption from "./SubUse/SelectHTMLOption";
import StarRatingHtml from "./SubUse/StarRatingHTML";

const Html = ({ commonPropsHtml }) => {
    const {formData, isHtmlModal, setIsHtmlModal, blockArea = {}, isLoading } = commonPropsHtml;
    const editorRef = useRef(null);

    const [message, setMessage] = useState('');

    const toggleActive = () => {
        setMessage('');
    };

    const RenderComponentByFieldType = (fieldType, pageIndex, blockIndex, blockArea) => {
        const commonListProps = {fieldType: fieldType, blockArea: blockArea, selectedPageIndex: pageIndex, selectedBlockIndex: blockIndex}
        switch (fieldType) {
            case "1":
            case "2":
            case "4":
            case "5":
            case "9":
            case "10":
            case "11":
            case "13":
            case "15":
                return <CommonHtmlInput {...{commonListProps}} />;
            case "6":
            case "7":
            case "8":
                return <SelectHtmlOption {...{commonListProps}} />;
            case "12":
                return <StarRatingHtml {...{commonListProps}} />;
            default:
                return null;
        }
    };

    const formatHtmlString = (htmlString) => {
        let indentLevel = 0;
        return htmlString?.replace(/></g, '>\n<')?.split('\n')?.map(line => {
                if (line.match(/<\/\w/) && !line.match(/<\w[^>]*\/>/)) {
                    indentLevel = Math.max(indentLevel - 1, 0);
                }
                const isSelfClosing = line.match(/<\w[^>]*\/>/);
                const formattedLine = '  '.repeat(indentLevel) + line;
                if (line.match(/<\w[^>]*[^\/]>.*$/) && !isSelfClosing) {
                    indentLevel++;
                }
                return formattedLine;
            })?.join('\n')?.trim();
    };

    const generateHtmlString = () => {
        let shouldIncludeRecaptcha = false;
        const data = Object.keys(blockArea).map((x, pageIndex) => {
            return (
                <Fragment key={`page-${pageIndex}`}>
                    {(blockArea[x] || []).map((y, blockIndex) => {
                        if (y.fieldType == 15) {
                            shouldIncludeRecaptcha = true;
                        }
                        return (
                            <Fragment key={`block-${pageIndex}-${blockIndex}`}>
                                {RenderComponentByFieldType(y.fieldType, pageIndex+1, blockIndex, blockArea)}
                            </Fragment>
                        )
                    })}
                </Fragment>
            )
        });
        let htmlString = ReactDOMServer.renderToString(
            <Fragment>
                <form method="post" data-formid={formData?.publishId || undefined} id="myForm">
                    {data}
                    <button type={"submit"}>Submit</button>
                </form>
                {shouldIncludeRecaptcha && <script src="https://www.google.com/recaptcha/api.js" async defer></script>}
            </Fragment>
        );
        htmlString = formatHtmlString(htmlString);

        return htmlString;
    };

    const handleEditorDidMount = (editor) => {
        editorRef.current = editor;
        setTimeout(() => {
            editorRef.current.getAction('editor.action.formatDocument').run();
        }, 0);
    };

    useEffect(() => {
        if (editorRef.current) {
            editorRef.current.layout();
        }
    }, [isHtmlModal]);

    const copyToClipboard = () => {
        const htmlString = generateHtmlString();
        navigator.clipboard.writeText(htmlString).then(() => {
            setMessage('HTML copied to clipboard!');
        }).catch(err => {
            setMessage(`Could not copy text: , ${err}`);
        });
    };

    return (
        <Fragment>
            {message !== "" && <Toast content={message} onDismiss={toggleActive} duration={3000} />}

            <Modal
                size="large"
                open={isHtmlModal}
                onClose={() => setIsHtmlModal(false)}
                title="HTML"
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: () => setIsHtmlModal(false),
                    },
                ]}
                primaryAction={{
                    content: 'Copy',
                    onAction: () => copyToClipboard(),
                }}
            >
                <Modal.Section>
                    {
                        isLoading ? commonParagraph(3) :
                            <Editor
                                height="500px"
                                value={generateHtmlString()}
                                defaultLanguage="html"
                                language="html"
                                theme="vs-dark"
                                loading="Loading HTML"
                                onChange={() => {}}
                                options={{
                                    formatOnType: true,
                                    formatOnPaste: true,
                                    wordWrap: 'on',
                                    automaticLayout: true,
                                    readOnly: true,
                                    minimap: { enabled: false },
                                }}
                                onMount={handleEditorDidMount}
                            />
                    }
                </Modal.Section>
            </Modal>
        </Fragment>
    );
};

export default Html;
