import React, {Fragment, useEffect, useState} from 'react';
import {BlockStack, Box, Button, ButtonGroup, Divider, DropZone, Text, TextField} from "@shopify/polaris";
import {imagePath, onKeyFire} from "../../../../Utils/Constent";

const ImgStyle = ({commonProps}) => {
    const {blockArea, selectedPageIndex, selectedBlockIndex, handleChangeStyles} = commonProps;
    const {imgURL, imgLink, imgType, fieldSize} = blockArea[selectedPageIndex]?.[selectedBlockIndex] || {};

    const [imgConvert, setImgConvert] = useState( '');
    const [error, setError] = useState('')

    const imgStyle = {
        maxWidth: "100px",
        maxHeight: '90%',
        width: "100%",
    }

    useEffect(() => {
        setImgConvert(imgLink)
        setError('')
    }, [imgLink, imgType, selectedPageIndex, selectedBlockIndex])

    useEffect(() => {
        setError('')
    }, [imgConvert, selectedPageIndex, selectedBlockIndex])


    const onSubmitImg = () => {
        if (!imgConvert.length || (typeof imgConvert === 'string' && imgConvert?.trim() === '')) {
            setError('Enter image URL');
            return;
        }

        const img = new Image();
        img.onload = function() {
            handleChangeStyles('imgLink', imgConvert);
            setError('');
        };
        img.onerror = function() {
            setError('Invalid image URL');
        };
        img.src = imgConvert;
    };

    const handleCheckImg = (value) => {
        setImgConvert(value)
    };

    const uploadedImgURL = imgURL && imgURL?.name ?
        (<div className="upload-center"><img style={imgStyle} src={URL.createObjectURL(imgURL)}/></div>) :
        imgURL?.length > 0 ? (<div className="upload-center"><img style={imgStyle} src={`${imagePath}${imgURL}`}/></div>):
            <DropZone.FileUpload actionTitle={"Add image"}/> ;

    const handleDropZoneDrop = (_dropFiles, acceptedFiles, _rejectedFiles) => {
        handleChangeStyles('imgURL', acceptedFiles && acceptedFiles.length ? acceptedFiles[0] : '')
    }

    return (
        <Fragment>
            <Box padding={"400"} paddingBlockStart={"200"}>
                <BlockStack gap={"200"}>
                    <ButtonGroup variant="segmented" fullWidth>
                        <Button
                            fullWidth size={"large"}
                            pressed={imgType == '0'}
                            onClick={() => handleChangeStyles("imgType", '0')}
                        >
                            Upload
                        </Button>
                        <Button
                            fullWidth size={"large"}
                            pressed={imgType == '1'}
                            onClick={() => handleChangeStyles("imgType", '1')}
                        >
                            Link
                        </Button>
                    </ButtonGroup>

                    {
                        imgType == '0' ?
                            <DropZone
                                label={"Upload Image"}
                                type={"image"} accept="image/*"
                                onDrop={handleDropZoneDrop}
                            >
                                {uploadedImgURL}
                            </DropZone> : imgType == '1' ?
                            <Fragment>
                                <Box as={"span"} width={"100%"} onKeyPress={(e) => onKeyFire(e, onSubmitImg)}>
                                    <TextField
                                        label={"Image Link"}
                                        placeholder={"Paste any image link from the web"}
                                        type={'url'}
                                        value={imgConvert}
                                        onChange={(value) => handleCheckImg(value)}
                                        autoComplete="off"
                                        error={error}
                                    />
                                </Box>
                                <Button variant={"primary"} onClick={onSubmitImg}>Submit Link</Button>
                            </Fragment>: ""
                    }
                </BlockStack>
            </Box>

            <Divider />
            <Box padding={"400"} paddingBlockStart={"200"}>
                <BlockStack gap={"100"}>
                    <Text>Size</Text>
                    <ButtonGroup variant="segmented" fullWidth>
                        {['medium', 'fullwidth'].map((x) => (
                            <Button
                                key={x} fullWidth size={"large"}
                                pressed={fieldSize === x}
                                onClick={() => handleChangeStyles("fieldSize", x,)}
                            >
                                {x.charAt(0).toUpperCase() + x.slice(1)}
                            </Button>
                        ))}
                    </ButtonGroup>
                </BlockStack>
            </Box>
            <Divider />
        </Fragment>
    );
};

export default ImgStyle;