import React, {Fragment} from 'react';

const SelectHtmlOption = ({commonListProps}) => {
    const {fieldType, blockArea, selectedPageIndex, selectedBlockIndex} = commonListProps;

    const {
        title = '',
        isRequired,
        errorMessage = '',
        id,
        options = []
    } = blockArea[selectedPageIndex]?.[selectedBlockIndex] || {};

    return (
        <Fragment>
            {(fieldType == 6 || fieldType == 7) &&
                <div>
                    <h2>{title}</h2>
                    {options.map(({value, label}, r) => (
                        <Fragment key={value}>
                            <input
                                type={fieldType == 6 ? "radio" : "checkbox"}
                                name={id}
                                value={value}
                                id={`${id}-${r+1}`}
                                data-error={isRequired == 1 ? errorMessage : undefined}
                                required={isRequired == 1}
                                data-field={fieldType}
                            />
                            <label htmlFor={`${id}-${r+1}`}>{label}</label>
                        </Fragment>
                    ))}
                    {isRequired == 1 ? <span className="error"></span> : ""}
                </div>
            }
            {
                fieldType == 8 &&
                <div>
                    {title?.trim() !== '' && <label>{title}</label>}
                    <select
                        id={id}
                        name={id}
                        required={isRequired == 1}
                        data-error={isRequired == 1 ? errorMessage : undefined}
                        data-field={fieldType}
                    >
                        {options.map(({value, label}) => (
                            <option key={value} value={value}>{label || ""}</option>))}
                    </select>
                    {isRequired == 1 ? <span className="error"></span> : ""}
                </div>
            }
        </Fragment>
    );
};

export default SelectHtmlOption;