import React from 'react';
import {Modal, Text} from "@shopify/polaris";

const Confirmation = ({open, onClose, title, onConfirm, message, isLoading}) => {
    return (
        <Modal open={open} title={title} onClose={onClose}
               primaryAction={{
                   content: 'Delete',
                   onAction: onConfirm,
                   loading: isLoading,
                   destructive: true
               }}
               secondaryActions={[
                   {
                       content: 'Cancel',
                       onAction: onClose,
                   }
               ]}
        >
            <Modal.Section>
                <Text>{message}</Text>
            </Modal.Section>
        </Modal>
    )
};

export default Confirmation;